import { ErrorOutline } from '@mui/icons-material';
import { styled } from '@mui/material';

export const LoadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.spacing(78),
  width: theme.spacing(132),
  maxWidth: '100%',
  maxHeight: '80vh',
}));

export const NosDisclaimerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  color: theme.palette?.text.secondary,
  marginBottom: theme.spacing(2),
}));

export const NosDisclaimerText = styled('div')(({ theme }) => ({
  fontSize: theme.typography?.caption.fontSize,
  paddingTop: theme.spacing(0.25),
}));

export const ErrorOutlineStyled = styled(ErrorOutline)(({ theme }) => ({
  fontSize: 20,
  marginRight: theme.spacing(1),
}));
