/* eslint-disable max-lines-per-function */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { DevTool } from '@hookform/devtools';
import Add from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  Artist, CompanyPermission, Bundle, Genre, ModifyBundleInput,
} from '@gql/types/graphql';
import { GET_GENRES } from '@gql/queries/artists';
import { ApolloError, useQuery } from '@apollo/client';
import { useYupValidationResolver } from '@hooks';
import { GET_COMPANIES } from '@gql/queries/companies';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import { SubHeader, ButtonContainer, AddHeadlinerButton } from './EditBundleForm.styled';
import { FormTextInput, AutocompleteInput, FormErrorComponent } from '../shared';
import { editBundleValidationSchema } from './EditBundleForm.schema';
import {
  ButtonStyled,
  DropdownInput,
  mapDropdownOptions,
} from '../shared/Form';
import {
  MAX_CHARS_BUNDLE_NAME,
  EDIT_BUNDLE_TEXT,
  EditBundleFormFields,
  getDefaultValues,
} from './EditBundleForm.models';
import { HeadlinerFields } from '../HeadlinerFields/HeadlinerFields';

interface EditBundleFormProps {
  onFormSubmit: SubmitHandler<ModifyBundleInput>;
  isFormSaving: boolean; // Push saving state into form
  bundle: Bundle;
  mutationError?: ApolloError;
}

export function EditBundleForm({
  onFormSubmit,
  isFormSaving,
  bundle,
  mutationError,
}: EditBundleFormProps) {
  const appInsights = useAppInsightsContext();
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);

  const defaultValues = useMemo(() => getDefaultValues(bundle), [bundle]);

  const resolver = useYupValidationResolver(editBundleValidationSchema);
  const { title, subTitle, submitText } = EDIT_BUNDLE_TEXT;

  const formMethods = useForm<EditBundleFormFields>({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const {
    formState: { isDirty },
    control,
    reset,
    handleSubmit,
    getValues,
    setValue,
  } = formMethods;

  const { fields: headlinersArray, append, remove } = useFieldArray({
    name: 'headliners',
    control,
  });

  const onSelectHeadliner = (headliner: Artist) => {
    const headlinerSubGenres = headliner?.subGenres ?? [];
    const currentGenres = getValues('genres') ?? [];
    const allGenres = [...currentGenres, ...headlinerSubGenres];

    const uniqueGenres = Array.from(new Map(allGenres.map((genre) => [genre?.id, genre])).values()) as Genre[];
    setValue('genres', uniqueGenres);
  };

  const handleAddHeadliner = () => {
    append({ headliner: null, agency: null, agents: [] });
  };

  const handleRemoveHeadliner = (headlinerIndex: number) => () => {
    remove(headlinerIndex);
  };

  const { loading: genresLoading, data: genresData } = useQuery(GET_GENRES);

  const { data: companiesData } = useQuery(GET_COMPANIES, {
    variables: { permission: CompanyPermission.ModifyBundles },
  });

  const handleInvalidSubmit = () => setIsFormErrorOpen(false);

  const handleValidSubmit = async (formData: EditBundleFormFields) => {
    const modifyBundleInput: ModifyBundleInput = {
      id: formData.id,
      name: formData.name.trim(),
      headliners: formData.headliners.map((artistInfo) => ({
        artistId: artistInfo.headliner?.id ?? '',
        agencyId: artistInfo.agency?.id ?? '',
        agentIds: artistInfo.agents?.map((agent) => agent.id).filter((id) => id !== undefined && id !== null) ?? [],
      })),
      genreIds: formData.genres.map((genre) => genre.id),
      companyId: formData.companyId,
      tourNotes: formData.tourNotes,
    };

    try {
      await onFormSubmit(modifyBundleInput);
      reset();
    } catch (error) {
      setIsFormErrorOpen(true);
      logError(appInsights, 'BundleForm submit error', error as Error);
    }
  };

  return (
    <>
      <Typography variant="h5" data-testid="edit-bundles-form-header-text">
        {title}
      </Typography>

      <SubHeader
        variant="body2"
        data-testid="edit-bundles-form-sub-header-text"
      >
        {subTitle}
      </SubHeader>

      <FormProvider {...formMethods}>
        <form onSubmit={(e) => {
          void handleSubmit(handleValidSubmit, handleInvalidSubmit)(e);
        }}>
          <FormErrorComponent
            isDismissable={false}
            error={mutationError}
            isOpen={isFormErrorOpen}
            setIsOpen={setIsFormErrorOpen}
          />

          {bundle && (
            <>
            <div>
              {headlinersArray.map((field, idx) => (
                <HeadlinerFields
                  onSelectHeadliner={onSelectHeadliner}
                  fieldName={`headliners[${idx}]`}
                  key={field.id}
                  testId={String(idx)}
                  removable={ headlinersArray.length > 1 }
                  onRemove={handleRemoveHeadliner(idx)}
                />
              ))}
              </div>
              <div >
                <AddHeadlinerButton variant="outlined"
                  startIcon={<Add />}
                  onClick={handleAddHeadliner}
                  data-testid={'add-headliner-button'}
                >
                  Add Headliner
                </AddHeadlinerButton>
              </div>
              <FormTextInput
                control={control}
                fieldName="name"
                label="Bundle Name"
                isRequired
                placeholderText="Enter Bundle Name"
                maxLength={MAX_CHARS_BUNDLE_NAME}
              />

              <AutocompleteInput
                control={control}
                fieldName="genres"
                label="Genre"
                chipOption={{ labelProperty: 'name' }}
                disableClearable={true}
                areOptionsLoading={genresLoading}
                options={genresData?.genres || []}
                dataDisplayField={{ primary: 'name' }}
                dataFilterField={'id'}
                isRequired={false}
                placeholderText="Select Genre"
                defaultValue={defaultValues.genres}
              />

              <DropdownInput
                fieldName="companyId"
                control={control}
                label={'Company'}
                placeholderText={'Select Company'}
                options={mapDropdownOptions(companiesData?.companies || [])}
                isRequired
              />

              <FormTextInput
                control={control}
                fieldName="tourNotes"
                label="Tour Notes"
                placeholderText="Enter Tour Notes"
                minRow={4}
                maxRow={4}
                infoTooltip="Notes filled here will be included in Tour's Confirmation Email"
              />

              <ButtonContainer>
                <ButtonStyled
                  variant="contained"
                  data-testid="create-button-form"
                  type="submit"
                  disabled={isFormSaving || (bundle && !isDirty)}
                >
                  {submitText}
                </ButtonStyled>
              </ButtonContainer>
            </>
          )}
        </form>
      </FormProvider>
      <DevTool control={control} />
    </>
  );
}
