import { graphql } from '@gql/types';

export const REFRESH_OFFER_WORKBOOK = graphql(`
  mutation RefreshWorkbook($tourId: ID!, $offerId: ID!) {
    refreshOfferWorkbook(tourId: $tourId, offerId: $offerId) {
      id
      workbookRefreshId
    }
  }
`);
