import jwt_decode from 'jwt-decode';
import React, { useEffect, useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { createBatchApolloClient } from '@services/apollo-client';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import { User } from './UserContextProvider.types';
import { EnvStatusContext } from '../EnvStatus/EnvStatusProvider';
import { EnvConfigContext } from '../EnvConfig/EnvConfigProvider';

const ExcelUserContext = React.createContext<User>(null);

const ExcelUserDispatchContext = React.createContext<
React.Dispatch<React.SetStateAction<User>>
>(() => {});

interface DecodedToken {
  oid: string;
  preferred_username: string;
  name: string;
  exp: number;
}

interface TokenState {
  token: string;
  expiration: Date;
}

type ProviderProps = {
  children: React.ReactNode;
};
function ExcelUserProvider({ children }: ProviderProps) {
  const appInsights = useAppInsightsContext();
  const envConfig = React.useContext(EnvConfigContext);
  const { envStatus } = React.useContext(EnvStatusContext);
  const [user, setUser] = React.useState<User>(null);
  const [userToken, setUserToken] = React.useState<TokenState | null>(null);

  const getAccessToken = async (): Promise<string> => {
    if (envStatus.isAutomationEnabled) {
      if (userToken) {
        return new Promise((resolve) => {
          resolve(userToken.token);
        });
      }
      const token = envStatus.automationToken as string;
      return new Promise((resolve) => {
        const decoded = jwt_decode<DecodedToken>(token);
        setUserToken({
          token,
          expiration: new Date(decoded.exp * 1000),
        });
        resolve(envStatus.automationToken as string);
      });
    }
    if (!userToken || userToken.expiration < new Date()) {
      let token = '';
      try {
        token = await OfficeRuntime.auth.getAccessToken();

        const decoded = jwt_decode<DecodedToken>(token);
        setUserToken({
          token,
          expiration: new Date(decoded.exp * 1000),
        });
        return await new Promise((resolve) => {
          resolve(token);
        });
      } catch (err) {
        logError(appInsights, 'Error getting and decoding user access token.', err as Error);
      }
    }
    return new Promise((resolve) => {
      resolve(userToken?.token as string);
    });
  };

  const apolloConfig = useMemo(
    () => createBatchApolloClient({ envConfig, getBearerToken: () => getAccessToken() }),
    [envConfig, userToken],
  );

  useEffect(() => {
    if (userToken?.token) {
      const decoded = jwt_decode<DecodedToken>(userToken.token);
      setUser({
        id: decoded.oid,
        name: decoded.name,
        email: decoded.preferred_username,
        availableCompanies: [],
      });
    }
  }, [userToken]);

  return (
    <ApolloProvider client={apolloConfig.globalToursClient}>
      <ExcelUserContext.Provider value={user}>
        <ExcelUserDispatchContext.Provider value={setUser}>
          {children}
        </ExcelUserDispatchContext.Provider>
      </ExcelUserContext.Provider>
    </ApolloProvider>
  );
}

export { ExcelUserProvider, ExcelUserContext, ExcelUserDispatchContext };
