/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React, { useState, useContext } from 'react';
import { logError } from '@services/telemetry-service';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ADD_ONE_OFF } from '@ooagg-gql/mutations/oneOffs';
import { NotificationDispatchContext } from '@providers';
import { SnackbarType } from '@components';
import { AegAggregator } from '@types';
import { AddOneOffInput } from '@ooagg-gql/types/graphql';
import { EventFormHeaderText } from '../CreateEventDrawer/CreateEventDrawer.models';
import { ManageTeamMembersForm } from '../ManageTeamMembersForm/ManageTeamMembersForm';
import { EventForm } from './EventForm/EventForm';
import { CREATE_EVENT_DEFAULTS, EventFormFields } from './EventForm/EventForm.models';
import { MANAGE_TEAM_MEMBERS_DEFAULTS, ManageTeamMembersFormFields } from '../ManageTeamMembersForm/ManageTeamMembersForm.models';

enum AddEventFormSteps {
  one,
  two,
}

interface AddEventFormProps {
  setFormContainerText: React.Dispatch<React.SetStateAction<EventFormHeaderText>>;
}

export const AddEventForm: React.FC<AddEventFormProps> = ({ setFormContainerText }) => {
  const appInsights = useAppInsightsContext();
  const navigate = useNavigate();
  const setNotification = useContext(NotificationDispatchContext);
  const [formStep, setFormStep] = useState<AddEventFormSteps>(AddEventFormSteps.one);
  const [eventFormData, setEventFormData] = useState<EventFormFields & ManageTeamMembersFormFields>({ ...CREATE_EVENT_DEFAULTS, ...MANAGE_TEAM_MEMBERS_DEFAULTS });

  const [addOneOff, { loading: mutationLoading }] = useMutation(ADD_ONE_OFF, {
    errorPolicy: 'all',
    onCompleted: (data) => {
      navigate(`/one-offs/${data?.addOneOff?.id ?? ''}`);
    },
    onError: (error) => {
      logError(appInsights, 'createOneOff event submit error', error);
      setNotification({
        text: `Error creating Event: ${error.message}`,
        type: SnackbarType.ERROR,
        duration: 6000,
      });
    },
    context: { clientName: AegAggregator.OOAGG },
  });

  const handleStepOne = (formData: EventFormFields) => {
    const data = {
      ...eventFormData,
      ...formData,
    };
    setEventFormData(data);
    setFormStep(AddEventFormSteps.two);
    setFormContainerText(EventFormHeaderText.manageTeam);
  };

  const handleStepTwo = async (formMemberData: ManageTeamMembersFormFields) => {
    if (mutationLoading) {
      return;
    }

    if (eventFormData === null) {
      setFormStep(AddEventFormSteps.one);
      return;
    }

    const finalEventInput = {
      ...eventFormData,
      ...formMemberData,
    };

    const {
      name,
      oneOffType,
      companyId,
      genres,
      notes,
      headliners,
      primaryBuyer,
      hallPasses,
      rosters,
    } = finalEventInput;

    const oneOff: AddOneOffInput = {
      name,
      headliners:
        headliners.map((headlinerInfo) => ({
          artistId: headlinerInfo?.headliner?.id || '',
          agencyId: headlinerInfo?.agency?.id || '',
          agentIds: headlinerInfo?.agents?.filter((agent) => agent?.id).map((agent) => agent.id) || [],
        })) || [],
      oneOffType,
      companyId,
      genreIds: genres.map((genre) => genre.id) || [],
      primaryBuyerId: primaryBuyer?.id || '',
      hallPassUsers:
        (hallPasses || [])
          .filter((hallPass) => hallPass?.user?.id)
          .map((hallPass) => ({
            userId: hallPass?.user?.id || '',
            role: hallPass?.role,
          })) || [],
      rosterUsers: (rosters || [])
        .filter((roster) => roster?.user?.id)
        .map((roster) => ({
          userId: roster?.user?.id || '',
          role: roster?.role,
        })),
      notesAndComments: notes.trim(),
    };

    await addOneOff({
      variables: {
        oneOff,
      },
    });
  };

  return (
    <>
      {formStep === AddEventFormSteps.one && (
        <EventForm
          eventFormData={eventFormData}
          onFormSubmit={handleStepOne}
        />
      )}
      {formStep === AddEventFormSteps.two && (
        <ManageTeamMembersForm
          companyId={eventFormData.companyId}
          eventFormData={eventFormData}
          onFormSubmit={handleStepTwo}
          isFormSaving={mutationLoading}
          onPrevious={(formValues) => {
            const data = { ...eventFormData, ...formValues };
            setEventFormData(data);
            setFormContainerText(EventFormHeaderText.createEvent);
            setFormStep(AddEventFormSteps.one);
          }}
        />
      )}
    </>
  );
};
