import { GraphQLErrors } from '@apollo/client/errors';
import { useContext } from 'react';
import { NotificationDispatchContext } from '@providers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import { SnackbarType } from '@components';

export const useOnError = () => {
  const appInsights = useAppInsightsContext();
  const setNotification = useContext(NotificationDispatchContext);
  return (
    error?: {
      message: string;
      name?: string;
      graphQLErrors?: GraphQLErrors;
    },
    notificationText?: string,
  ) => {
    if (!error) {
      return;
    }

    // TODO: Add a request to app insights here
    setNotification({
      type: SnackbarType.WARNING,
      text: notificationText
        || 'There was an error loading information on this screen. You may need to reload your browser to view it.',
    });

    if (error?.graphQLErrors) {
      logError(appInsights, `GQL error loading information on the screen: ${error.message}`);
    } else {
      logError(appInsights, 'Error loading information on the screen.', error as Error);
    }
  };
};
