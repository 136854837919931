/* eslint-disable max-lines-per-function */
import {
  MANAGED_SHEETS_SHEET_NAME,
  MANAGED_SHEETS_TABLES,
  VARIABLE_COST_RATES_REF_SHEET_NAME,
  VARIABLE_COST_RATES_REF_SHEET_TABLES,
} from './constants';
import {
  AEGSharedTables,
  initializeTableGetter,
} from '../shared/tableLibrary';
import { generateTableName } from '../shared/helpers';
import {
  PROFIT_LOSS_REF_SHEET_NAME,
  PROFIT_LOSS_REF_SHEET_TABLES,
  EXPENSE_CATEGORY_IDS,
  WorkbookReferences,
} from '../shared/constants';
import {
  POST_TAX_TABLE_ROW, PRE_TAX_TABLE_ROW, PostTaxRow, PreTaxRow, TAX_TABLE_ROW, TaxRow,
} from './tableShapes';
import {
  ParseTableFunction, ReferenceSheetTables, SheetTableDataFactory, SheetTables,
} from '../shared/types';

export const AEGModeling = (
  parseTable: ParseTableFunction,
) => {
  const getDataFromReferenceTable = initializeTableGetter(parseTable);

  const referenceTables: ReferenceSheetTables = {
    rates: async () => getDataFromReferenceTable(
      VARIABLE_COST_RATES_REF_SHEET_NAME,
      VARIABLE_COST_RATES_REF_SHEET_TABLES.RATES,
    ),
    managedSheets: async () => getDataFromReferenceTable(
      MANAGED_SHEETS_SHEET_NAME,
      MANAGED_SHEETS_TABLES.MANAGED_SHEETS,
    ),
  };

  const ancillaryItems: SheetTableDataFactory = async (numeratedEventMarketName: string) => ({
    name: generateTableName(numeratedEventMarketName, 'Ancillary'),
    data: [],
    referenceTable: await getDataFromReferenceTable(
      PROFIT_LOSS_REF_SHEET_NAME,
      PROFIT_LOSS_REF_SHEET_TABLES.ANCILLARY_EARNINGS,
    ),
  });

  const variableCosts: SheetTableDataFactory = async (numeratedEventMarketName: string) => ({
    name: generateTableName(numeratedEventMarketName, 'VariableCosts'),
    data: [],
    referenceTable: await getDataFromReferenceTable(
      PROFIT_LOSS_REF_SHEET_NAME,
      PROFIT_LOSS_REF_SHEET_TABLES.VARIABLE_COSTS,
    ),
    categoryId: EXPENSE_CATEGORY_IDS.VARIABLE_COST,
  });

  const fixedCosts = AEGSharedTables(parseTable, WorkbookReferences.MODELING);

  const potentialEarnings: SheetTables = {
    preTax: async (numeratedEventMarketName: string, sheetName?: string) => {
      const name = generateTableName(numeratedEventMarketName, 'PreTaxFees', 0);
      const tableData = await parseTable<PreTaxRow>(sheetName ?? numeratedEventMarketName, name, PRE_TAX_TABLE_ROW);
      return {
        name,
        data: tableData.map((row) => Object.values(row)),
        referenceTable: await getDataFromReferenceTable(
          PROFIT_LOSS_REF_SHEET_NAME,
          PROFIT_LOSS_REF_SHEET_TABLES.PRE_TAX,
        ),
      };
    },
    tax: async (numeratedEventMarketName: string, sheetName?: string) => {
      const name = generateTableName(numeratedEventMarketName, 'Taxes', 0);
      const tableData = await parseTable<TaxRow>(sheetName ?? numeratedEventMarketName, name, TAX_TABLE_ROW);
      return {
        name,
        data: tableData.map((row) => Object.values(row)),
        referenceTable: await getDataFromReferenceTable(
          PROFIT_LOSS_REF_SHEET_NAME,
          PROFIT_LOSS_REF_SHEET_TABLES.TAX,
        ),
      };
    },
    postTax: async (numeratedEventMarketName: string, sheetName?: string) => {
      const name = generateTableName(numeratedEventMarketName, 'PostTaxFees', 0);
      const tableData = await parseTable<PostTaxRow>(sheetName ?? numeratedEventMarketName, name, POST_TAX_TABLE_ROW);
      return {
        name,
        data: tableData.map((row) => Object.values(row)),
        referenceTable: await getDataFromReferenceTable(
          PROFIT_LOSS_REF_SHEET_NAME,
          PROFIT_LOSS_REF_SHEET_TABLES.POST_TAX,
        ),
      };
    },
  };

  const modelingTables = {
    fixedCosts,
    variableCosts,
    ancillaryItems,
    potentialEarnings,
    referenceTables,
  };

  return {
    modelingTables,
  } as const;
};
