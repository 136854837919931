/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines-per-function */
import type { SubmitHandler } from 'react-hook-form';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { DevTool } from '@hookform/devtools';
import { NotificationDispatchContext } from '@providers';
import {
  useContext, useMemo, useState, useEffect,
} from 'react';
import { useYupValidationResolver, useNavigateOnError } from '@hooks';
import { useForm } from 'react-hook-form';
import { Typography } from '@mui/material';
import { GRANT_ADMIN_ACCESS, GRANT_USER_ACCESS } from '@gql/mutations/users';
import { useMutation, useQuery } from '@apollo/client';
import {
  AutocompleteInput, ButtonStyled, DropdownInput, SnackbarType,
} from '@components';
import { GET_COMPANIES } from '@gql/queries/companies';
import { SEARCH_USERS } from '@gql/queries/users';
import { CompanyPermission } from '@gql/types/graphql';
import { UserRoles } from '@types';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import { roleOptions } from './AdminForm.options';
import { addAdminValidation, addUserValidation } from './AdminForm.schema';
import { ButtonContainer } from './AdminForm.styled';

export type AdminFormType = 'User' | 'Admin';

type User = null | {
  id: string;
  name: string;
  email: string;
  role: string;
  company: string;
};

interface AdminFormFields {
  user?: User;
  company: string;
  role: string;
}

const defaultValues = {
  user: null,
  company: '',
  role: '',
};

export function AdminForm({
  type,
  onClose,
}: {
  type: AdminFormType;
  onClose: () => void;
}) {
  const appInsights = useAppInsightsContext();
  const [selectedUser, setSelectedUser] = useState('');
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [isVenueAdminSelected, setIsVenueAdminSelected] = useState(false);
  const setNotification = useContext(NotificationDispatchContext);

  const { loading: usersLoading, data: usersData } = useQuery(SEARCH_USERS, {
    errorPolicy: 'all',
    variables: {
      searchTerm: selectedUser,
    },
  });

  const { loading: companiesLoading, data: companiesData } = useQuery(
    GET_COMPANIES,
    {
      errorPolicy: 'all',
      variables: { permission: CompanyPermission.ManageUsers },
    },
  );

  const validation = useMemo(
    () => (type === 'User' ? addUserValidation() : addAdminValidation()),
    [],
  );

  const resolver = useYupValidationResolver(validation);

  const { handleSubmit, control, getValues } = useForm<AdminFormFields>({
    resolver,
    defaultValues,
  });

  const [grantUserAccess, { error: userMutationError }] = useMutation(GRANT_USER_ACCESS);
  const [grantAdminAccess, { error: adminMutationError }] = useMutation(GRANT_ADMIN_ACCESS);
  const navigateOnError = useNavigateOnError();
  useEffect(() => {
    navigateOnError(userMutationError || adminMutationError);
  }, [adminMutationError, userMutationError]);

  const submitForm: SubmitHandler<AdminFormFields> = async ({
    company,
    role,
    user,
  }) => {
    let response;

    try {
      if (type === 'User') {
        response = await grantUserAccess({
          variables: {
            userAccess: {
              companyId: company,
              userId: user!.id,
              role,
            },
          },
        });
      } else {
        response = await grantAdminAccess({
          variables: {
            userId: user!.id,
          },
        });
      }
    } catch (err) {
      logError(appInsights, 'Error adding user.', err as Error);
    }

    if (response) {
      setNotification({
        type: SnackbarType.SUCCESS,
        text: `${type} has been added successfully`,
        duration: 6000,
      });
      onClose();
    }
  };

  const onSelect = (value: any) => {
    if (selectedUser && !value) {
      return;
    }
    setIsUserSelected(Boolean(value));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(submitForm) as () => void}
        data-testid="admin-form"
      >
        <Typography variant="h5" data-testid="Add-Header-Text">
          {type === 'User' ? 'Add User Permissions' : 'Add Admin'}
        </Typography>
        <AutocompleteInput
          fieldName="user"
          control={control}
          options={(usersData?.users || []) as User[]}
          areOptionsLoading={usersLoading}
          label="User Name / Email"
          dataDisplayField={{ primary: 'name', secondary: 'email' }}
          dataFilterField="id"
          filterOut={[getValues('user')?.id]}
          onInputChange={setSelectedUser}
          onSelect={onSelect}
          placeholderText="Type User's Name or Email Address (e.g. John Doe or john.doe@email.com)"
          isRequired={true}
          minimumCharacters={2}
        />

        {type === 'User'
          && isUserSelected
          && selectedUser
          && (
          <DropdownInput
            control={control}
            fieldName="role"
            label="Role"
            options={roleOptions}
            placeholderText="Select User's Role"
            isRequired={true}
            handleChange={(value: string) => {
              setIsVenueAdminSelected(value === UserRoles.VenueAdmin);
            }}
          />)
        }

        {type === 'User'
          && isUserSelected
          && selectedUser
          && !companiesLoading
          && companiesData?.companies
          && !isVenueAdminSelected && (
            <DropdownInput
              control={control}
              fieldName="company"
              label="Company"
              // TODO: use mapDropdownOptions and remove lint ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              options={companiesData.companies.map((c) => ({
                value: c ? c.id : null,
                label: c ? c.name : null,
              }))}
              placeholderText="Select a Company"
              isRequired={!isVenueAdminSelected}
            />
        )}

        <ButtonContainer>
          <ButtonStyled
            data-testid="cancel-button"
            onClick={onClose}
            type="button"
            variant="outlined"
          >
            Cancel
          </ButtonStyled>

          <ButtonStyled
            type="submit"
            variant="contained"
            disabled={!selectedUser}
            data-testid="add-button"
          >
            Add
          </ButtonStyled>
        </ButtonContainer>
        <DevTool control={control} />
      </form>
    </>
  );
}
