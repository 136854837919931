/* eslint-disable-next-line import/no-extraneous-dependencies */
import { DevTool } from '@hookform/devtools';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AddAgentInput, ModifyAgentInput } from '@gql/types/graphql';
import { CircularProgress } from '@mui/material';
import { FormTextInput } from '@components';
import { useYupValidationResolver } from '@hooks';
import { logError } from '@services/telemetry-service';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
  AgentFormFields,
  AgentFormMode,
  CREATE_AGENT_TEXT,
  EDIT_AGENT_TEXT,
} from './AgentForm.models';
import { createAgentValidationSchema } from './AgentForm.schema';
import { AgentFormContainer, ButtonContainer } from './AgentForm.styled';
import {
  ButtonStyled,
  InlineFormFieldsContainer,
} from '../shared/Form';

export interface AgentFormError {
  field: keyof AgentFormFields;
  message: string;
}

interface AgentFormProps {
  onFormSubmit: SubmitHandler<AddAgentInput> | SubmitHandler<ModifyAgentInput>;
  isFormSaving: boolean; // Push saving state into form
  handleClose: () => void;
  defaultValues: AgentFormFields;
  onFormSubmitNetworkError: AgentFormError | null;
  setIsFormErrorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  agentFormMode: AgentFormMode;
}

// eslint-disable-next-line max-lines-per-function
export function AgentForm({
  onFormSubmit,
  isFormSaving,
  handleClose,
  defaultValues,
  onFormSubmitNetworkError,
  setIsFormErrorOpen,
  agentFormMode,
}: Readonly<AgentFormProps>) {
  const appInsights = useAppInsightsContext();
  const resolver = useYupValidationResolver(createAgentValidationSchema);

  const {
    control,
    handleSubmit,
    setError,
  } = useForm<AgentFormFields>({
    resolver,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  if (onFormSubmitNetworkError) {
    const { field, message } = onFormSubmitNetworkError;
    setError(field, {
      type: 'validate',
      message,
    });
  }

  const handleValidSubmit = (
    formData: AgentFormFields,
  ) => {
    try {
      onFormSubmit(formData);
    } catch (error) {
      setIsFormErrorOpen(true);
      logError(appInsights, 'BundleForm submit error', error as Error);
    }
  };

  const handleInvalidSubmit = () => setIsFormErrorOpen(false);

  const inlineFormFields = [
    {
      fieldName: 'name',
      label: 'Agent Name',
      isRequired: true,
      placeholderText: 'Enter Agent Name',
    },
    {
      fieldName: 'email',
      label: 'Email',
      isRequired: true,
      placeholderText: "Enter Agent's Email Address",
    },
    {
      fieldName: 'phone',
      label: 'Phone #',
      isRequired: false,
      placeholderText: "Enter Agent's Phone #",
    },
  ];

  const submitText = agentFormMode === AgentFormMode.CREATE
    ? CREATE_AGENT_TEXT.submitText
    : EDIT_AGENT_TEXT.submitText;

  return (
    <>
      {/* react-hook-form advises against using nested FormProviders */}
      <form onSubmit={(e) => {
        void handleSubmit(handleValidSubmit, handleInvalidSubmit)(e);
        e.stopPropagation(); // prevent bubble up to parent form
      }}>
        <AgentFormContainer>
          <InlineFormFieldsContainer
            numberOfFields={inlineFormFields.length}
          >
            {inlineFormFields.map((field) => (
              <FormTextInput
                key={field.fieldName}
                control={control}
                fieldName={field.fieldName}
                label={field.label}
                isRequired={field.isRequired}
                placeholderText={field.placeholderText}
              />
            ))}
          </InlineFormFieldsContainer>
          <ButtonContainer>
            <ButtonStyled
              variant="contained"
              data-testid="create-agent-form"
              type="submit"
              disabled={isFormSaving}
              startIcon={isFormSaving && <CircularProgress size={24} color="inherit" data-testid="progress-icon" />}
              minWidth={24}
            >
              {!isFormSaving && submitText}
            </ButtonStyled>

            <ButtonStyled
              data-testid="cancel-button"
              onClick={handleClose}
              type="button"
              variant="outlined"
            >
              Cancel
            </ButtonStyled>
          </ButtonContainer>
        </AgentFormContainer>
      </form>
      <DevTool control={control} />
    </>
  );
}
