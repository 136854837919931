import {
  Box, CustomTheme, Grid, styled,
  Typography,
} from '@mui/material';

export const ConfirmationModalContainer = styled('div')(
  ({ theme }: { theme?: CustomTheme }) => ({
    padding: theme?.spacing(1),
  }),
);

export const GridContainer = styled('div')(
  ({ theme }: { theme?: CustomTheme }) => ({
    paddingLeft: theme?.spacing(2),
    paddingRight: theme?.spacing(2),
    fontSize: theme?.spacing(1.75),
  }),
);

export const GridItemTitle = styled(Grid)({
  fontWeight: 700,
});

export const ButtonContainer = styled(Box)(
  ({ theme }: { theme?: CustomTheme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme?.spacing(2),
    paddingRight: theme?.spacing(2),
    marginTop: theme?.spacing(1),
    marginBottom: theme?.spacing(1),
    '& a': {
      textDecoration: 'underline',
    },
  }),
);

export const QuestionsText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme?.spacing(1.75),
}));
