import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  GridActionsCellItem,
  GridColDef,
  GridCellParams,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridRowId,
  GridRowModel,
  GridRowParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { ExpandLess, ExpandMore, ChevronRight } from '@mui/icons-material';
import { StatusChip } from '@components';
import { Typography } from '@mui/material';
import {
  BundlesPageFieldsFragment,
  Event,
  Offer,
  SpaceConfiguration,
  TierScaling,
  TourFieldsFragment,
  ToursPageFieldsFragment,
} from '@gql/types/graphql';
import { OfferStatusFilters } from '@types';
import { sortEventsByDateActive } from '@utils/eventHelpers';
import { AddTextStyled } from './datagridHelpers.styled';
import { getTimezoneDate, getUserTimezoneDate } from './stringHelpers';

export enum AddColumnDirection {
  left,
  right,
}

/**
 * base Data Grid column to use for an 'add' button
 */
export const addColumn = <T extends GridValidRowModel>(
  addIconClickHandler?: (row: T) => Promise<void>,
  label = 'Add',
  pinnedColumn: AddColumnDirection = AddColumnDirection.right,
  options?: Partial<GridColDef<T>>,
) => [
    {
      field: 'addEvent',
      type: 'actions',
      resizable: false,
      width: 10,
      getActions: (params: GridRowParams<T>) => [
        <GridActionsCellItem
          data-testid="add-button"
          icon={
            <AddTextStyled className={pinnedColumn === AddColumnDirection.left ? 'pinned-left' : ''}>
              {label}
            </AddTextStyled>
          }
          label={label}
          onClick={() => {
            if (addIconClickHandler) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              void addIconClickHandler(params.row);
            }
          }}
        />,
      ],
      ...options,
    },
  ];

/**
 * base Data Grid column to use for an 'expand' carat
 */
export const expandColumn = (expandedRows: GridRowId[], manageExpandedRows: (gridRowId: GridRowId) => void) => [
  {
    field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
    type: 'actions',
    resizable: false,
    width: 10,
    renderCell: (params: GridCellParams<GridRowModel>) => [
      <GridActionsCellItem
        key={params.row.id as string}
        icon={expandedRows?.includes(params.row.id as string)
          ? <ExpandLess data-testid="expand-less-icon" />
          : <ExpandMore data-testid="expand-more-icon" />
        }
        label="expand"
        onClick={() => manageExpandedRows(params.row.id as string)}
      />,
    ],
  },
];

export const chevronRightColumn = <T extends GridValidRowModel>(
  caretIconClickHandler?: (row: T) => (Promise<void> | void),
) => [
    {
      field: 'chevronRight',
      type: 'actions',
      resizable: false,
      width: 10,
      getActions: (params: GridRowParams<T>) => [
        <GridActionsCellItem
          data-testid="chevron-right-button"
          icon={<ChevronRight data-testid="chevron-right-icon" />}
          label="more"
          onClick={() => {
            if (caretIconClickHandler) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              void caretIconClickHandler(params.row);
            }
          }}
        />,
      ],
    },
  ];

type TourGridRow = GridValidRowModel & (TourFieldsFragment | ToursPageFieldsFragment);

/**
 * Column definition shared between the datagrids in the tours page, and the
 * parent tour details page.
 */
export const getSharedTourColumns = <T extends TourGridRow>(): GridColDef<T>[] => [
  {
    field: 'name',
    headerName: 'Tour',
    flex: 0.17,
    renderCell: (params) => (
      <Link to={`/tours/${params.row.id as string}`}>
        <Typography data-testid="child-tour-link" variant="body2" color="info.main">
          {params.row.name}
        </Typography>
      </Link>
    ),
  },
  {
    field: 'headlinerIds',
    headerName: 'Headliner',
    flex: 0.12,
    renderCell: (params) => params?.row?.headliner?.name,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    flex: 0.12,
    renderCell: (params) => {
      if (params.row.startDate === 'TBD') {
        return 'TBD';
      }

      const { primaryOfferId, offers } = params.row;
      const primaryOffer: Offer | undefined | null = offers?.find(
        (offer) => offer?.id === primaryOfferId,
      );

      const events = primaryOffer?.events as Event[] | undefined;
      const sortedEvents = sortEventsByDateActive(events);
      const [formattedDate] = getTimezoneDate(params.row.startDate, sortedEvents?.[0]?.venue?.timezone, 'll');

      return formattedDate;
    },
  },
  {
    field: 'buyerIds',
    headerName: 'Buyer',
    flex: 0.11,
    renderCell: (params) => params?.row?.primaryBuyer?.name || 'Not Available',
  },
  {
    field: 'companyIdsFiltered',
    headerName: 'Company',
    flex: 0.12,
    renderCell: (params) => params?.row?.company?.name,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.12,
    renderCell: (params) => {
      const status = params.row.offers?.find((offer) => offer?.id === params.row.primaryOfferId)?.status;
      return status ? <StatusChip status={status} /> : <></>;
    },
    type: 'singleSelect',
    valueOptions: Object.values(OfferStatusFilters),
  },
];

export const manageExpandedRows = (
  gridRowId: GridRowId,
  expandedRows: GridRowId[],
  setExpandedRows: React.Dispatch<React.SetStateAction<GridRowId[]>>,
) => {
  const isExpanded = expandedRows.includes(gridRowId);
  if (isExpanded) {
    setExpandedRows(expandedRows.filter((rowId) => rowId !== gridRowId));
  } else {
    setExpandedRows([...expandedRows, gridRowId]);
  }
};

/**
 * Checks to see whether data has changed when a row is edited
 */
export const rowDataHasChanged = (newRow: GridRowModel, oldRow: GridRowModel) => (
  Object.keys(newRow).some((key) => newRow[key] !== oldRow[key])
);

/**
 * Removes temporary ids assigned to datagrid rows before new rows are passed back in mutation
 */
export const removeTempIds = (obj: TierScaling | SpaceConfiguration) => {
  const { id, ...rest } = obj;
  return rest;
};

type BundleGridRow = GridValidRowModel & BundlesPageFieldsFragment;

export const getBundlesColumns = <T extends BundleGridRow>(): GridColDef<T>[] => [
  {
    field: 'name',
    headerName: 'Bundle',
    flex: 0.17,
    renderCell: (params) => (
      <Link to={`/bundles/${params.row.id}`}>
        <Typography data-testid="child-bundle-link" variant="body2" color="info.main">
          {params.row.name}
        </Typography>
      </Link>
    ),
  },
  {
    field: 'headlinerIds',
    headerName: 'Headliner',
    flex: 0.12,
    // todo: confirm if only first headliner is needed or all headliners
    renderCell: (params) => params.row.headliners?.[0]?.artist?.name,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    flex: 0.12,
    renderCell: (params) => {
      // todo: add start date to AGG Bundles Type
      if (params.row.startDate === 'TBD') {
        return 'TBD';
      }

      const [formattedDate] = getUserTimezoneDate(params.row.startDate as string, 'll');

      return formattedDate;
    },
  },
  {
    field: 'buyerIds',
    headerName: 'Buyer',
    flex: 0.12,
    // todo: confirm if only first buyer is needed or all buyers
    renderCell: (params) => params?.row?.team?.buyers?.[0]?.name || 'Not Available',
  },
  {
    field: 'company',
    headerName: 'Company',
    flex: 0.12,
    renderCell: (params) => params?.row?.company?.name,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.12,
    renderCell: (params) => {
      const status = params.row.status as string;
      return status ? <StatusChip status={status} /> : <></>;
    },
    type: 'singleSelect',
    valueOptions: Object.values(OfferStatusFilters),
  },
];

/**
 * Disable all column interactions by default for all columns
 */
export const disableColumnInteractions = (column: GridColDef): GridColDef => ({
  sortable: false,
  filterable: false,
  pinnable: false,
  hideable: false,
  groupable: false,
  editable: false,
  resizable: false,
  disableColumnMenu: true,
  ...column,
});

export const leftPinnedColumn = {
  headerName: '',
  flex: 0.2,
  minWidth: 228,
  maxWidth: 248,
};
