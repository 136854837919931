/* eslint-disable max-lines-per-function */
/* eslint-disable no-restricted-syntax */
// Excel API actions require sequential execution, so "no-await-in-loop" is disabled to ensure correct order.
/* eslint-disable no-await-in-loop */
import {
  AEG_SETTLEMENT,
  ARTIST_SETTLEMENT,
  AS_BOX_OFFICE_SHEET_NAME,
  AS_ARTIST_PAYMENT_TABLE_NAME,
  AS_SETTLEMENT_SHEET_NAME,
  AS_VENUE_SETTLEMENT_TABLE_NAME,
  EXPENSES_FORMULAS,
  FAR_ANCILLARY_EARNINGS_TABLE_NAME,
  FAR_ARTIST_PAYMENT_TABLE_NAME,
  FAR_VENUE_SETTLEMENT_TABLE_NAME,
  FAR_BOX_OFFICE_SHEET_NAME,
  FAR_SETTLEMENT_SHEET_NAME,
  FEE_TABLE_NAME_KEY,
  getAegRoadFormula,
  NAMED_RANGES,
  NOS_SETTLEMENT_INITIALIZED_KEY,
  NOS_TABLE_PREFIXES,
  PRE_TAX_TABLE_NAME_KEY,
  SETTLEMENT_ADJUSTMENTS,
  SETTLEMENT_EMAIL_TEMPLATE_SHEET_NAME,
  SETTLEMENT_SUMMARY_SHEET_NAME,
  SHEETS_WITH_AEG_LOGO,
  TAX_FEE_FORMULAS,
  VARIABLE_COSTS_FORMULAS,
} from '@utils/excel/nosSettlement/constants';
import { EnvStatusContext } from '@providers';
import { useLoading, useFeatureFlags } from '@hooks';
import FeatureFlags from '@utils/featureFlags';
import { useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_PL_CATEGORIES } from '@gql/queries/profitLossCategories';
import {
  CMN_COMPANY_ID,
  EXPENSE_CATEGORY_IDS,
  Phases,
  PROFIT_LOSS_REF_SHEET_NAME,
  TABLE_IDENTIFIERS,
  WORKBOOK_ORIGINAL_PARENT_DIR_PATH,
  WORKBOOK_ORIGINAL_PARENT_DIR_TOUR_FOLDER,
} from '@utils/excel/shared/constants';
import {
  BoxOffice,
  BoxOfficeFee,
  BoxOfficeItem,
  Event,
  ExpenseInput,
  FixedCategoryItemInput,
  FixedCostInput,
  ModifyBoxOfficePhaseInput,
  SettlementPaymentInput,
  Show,
  VariableCategoryItemInput,
  VariableCostInput,
  SummaryDetailInput,
  FixedCategoryItem,
  ArtistNosSettlementDetailInput,
  InternalNosSettlementDetailInput,
  VariableCost,
  ExpensePhase,
  ExpensePhaseInput,
} from '@gql/types/graphql';
import { SYNC_NOS_SETTLEMENT } from '@gql/mutations/settlement';
import { GET_NOS_WITH_EVENT } from '@gql/queries/nosSettlements';
import {
  eventMarketName, toStringOrUndefined, getTimezoneDate, getPhaseIDForNOS,
} from '@utils/stringHelpers';
import { BOX_OFFICE_FEE_TABLE_ROW, BoxOfficeFeeRow } from '@utils/excel/shared/tableShapes';
import {
  buildPlCategoryPayload,
  generateBoxOfficeFeeItems,
  generateTableName,
  getPlCategoryMap,
  retainTemplateOrderForPlRefTable,
} from '@utils/excel/shared/helpers';
import { AegNOSSettlement } from '@utils/excel/nosSettlement/tableLibrary';
import {
  NOS_BOX_OFFICE_TABLE_ROW,
  SETTLEMENT_PAYMENT_TABLE_ROW,
  FixedCostRow,
  FIXED_COST_TABLE_ROW,
  EXPENSE_TABLE_ROW,
  ExpenseRow,
} from '@utils/excel/nosSettlement/tableShapes';
import { toNumberOrUndefined } from '@utils/numberHelpers';
import { ADD_OR_UPDATE_EXPENSE } from '@gql/mutations/expenses';
import { MODIFY_BOX_OFFICE_PHASE } from '@gql/mutations/events';
import {
  generateWorkbookInitCurrencyRanges,
  getShowAdjustmentData,
  getExpensesFormula,
} from '@utils/excel/nosSettlement/helpers';
import {
  NamedRangeAssignment, PopulateTablesData, useExcel, TableDataArray, TableData,
} from './useExcel';
import cmnLogoPath from '../../public/images/cmn-logo.png';

export function useAegNOSSettlement() {
  const { envStatus } = useContext(EnvStatusContext);
  const { displayAegLoadingDialog } = useLoading();

  const { eventId, nosId } = envStatus;

  const { getFlag } = useFeatureFlags();

  const {
    applyValuesToNamedRanges,
    addActivationListenerToCheckForSharepoint,
    applyFormatsToNamedRanges,
    checkIfWorkbookIsSyncedToSharepoint,
    checkWorksheetForNamedRange,
    currentWorkbookSheetNames,
    getCustomProperties,
    getValueByRangeName,
    getValuesFromNamedRanges,
    loadExcelParentDirectory,
    parseTable,
    populateMultipleTables,
    saveWorkbookOriginalFileLocation,
    setCustomProperty,
    setWorkbookLogo,
    updateTableName,
  } = useExcel();

  const { settlementTables } = AegNOSSettlement(parseTable);

  const [getNosEvent] = useLazyQuery(GET_NOS_WITH_EVENT);
  const [getPLCategories] = useLazyQuery(GET_PL_CATEGORIES);
  const [upsertBoxOfficePhase] = useMutation(MODIFY_BOX_OFFICE_PHASE);
  const [syncNosSettlement] = useMutation(SYNC_NOS_SETTLEMENT);
  const [updateExpenseMutation] = useMutation(ADD_OR_UPDATE_EXPENSE);

  const {
    SETTLEMENT, AS_DETAILS, FAR_DETAILS, NOS_SETTLEMENT_SUMMARY, NET_TO_POOL,
  } = NAMED_RANGES;

  const parseSettlementExpenses = async (sheetName: string, tablePrefix: string) => {
    const { expenseBreakdowns } = settlementTables;
    const includeSubtotals = true;
    const localCurrency = await getValueByRangeName<string>(NAMED_RANGES.AS_DETAILS.LOCAL_CURRENCY, sheetName);
    const exchangeRate = await getValueByRangeName<number>(NAMED_RANGES.AS_DETAILS.EXCHANGE_RATE, sheetName);

    const expenseBreakdownItems: Promise<FixedCategoryItemInput | VariableCategoryItemInput>[] = Object.values(
      expenseBreakdowns,
    ).map(async (value) => {
      const expenseBreakdownFactory = await value(tablePrefix);
      const expenseBreakdownTableData = await parseTable<ExpenseRow>(
        sheetName,
        expenseBreakdownFactory.name,
        EXPENSE_TABLE_ROW,
        includeSubtotals,
      );
      const expenseBreakdownInput: (FixedCostInput | VariableCostInput)[] = [];

      let subtotalBreakdown;
      let subtotalInternalNotes: string | undefined;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const subtotalIndex = expenseBreakdownTableData.findIndex((item) => item.name.includes('Sub-Total'));
      if (subtotalIndex !== -1) {
        const breakdownItem = expenseBreakdownTableData.splice(subtotalIndex, 1)[0];
        const {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          name: _subtotals,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          notes: _subtotalNotes,
          internalNotes: subtotalRowInternalNotes,
          ...breakdown
        } = breakdownItem;
        subtotalBreakdown = { ...breakdown };
        subtotalInternalNotes = subtotalRowInternalNotes;
      }

      expenseBreakdownTableData.forEach((item) => {
        const {
          name, internalNotes, rate, ...itemBreakdown
        } = item;
        const id = expenseBreakdownFactory.referenceTable.find((refRow) => refRow.name === name)?.id;
        if (id) {
          // Variable Cost Input table will have rate column and not notes
          if (rate) {
            const expenseBreakdownInputItem: VariableCostInput = {
              id,
              localCurrency,
              exchangeRate,
              rate,
              internalNotes,
              breakdown: {
                ...itemBreakdown,
                currency: localCurrency,
                exchangeRate,
              },
            };
            expenseBreakdownInput.push(expenseBreakdownInputItem);
          } else {
            const expenseBreakdownInputItem: FixedCostInput = {
              id,
              localCurrency,
              exchangeRate,
              internalNotes,
              breakdown: {
                ...itemBreakdown,
                currency: localCurrency,
                exchangeRate,
              },
            };
            expenseBreakdownInput.push(expenseBreakdownInputItem);
          }
        }
      });

      const totalBudgetNames = NAMED_RANGES.FIXED_COSTS_TOTALS.find(
        (namedRange) => namedRange.categoryId === expenseBreakdownFactory.categoryId,
      )?.names;

      let totalBudgetToBase: number | undefined;

      if (totalBudgetNames) {
        totalBudgetToBase = await getValueByRangeName<number>(totalBudgetNames.totalBudgetToBaseName, sheetName);
      }

      const categoryItem: FixedCategoryItemInput | VariableCategoryItemInput = {
        categoryId: expenseBreakdownFactory.categoryId,
        items: expenseBreakdownInput,
        internalNotes: subtotalInternalNotes as string,
        totalBudgetToBase,
        breakdown: {
          currency: localCurrency,
          exchangeRate,
        },
      };

      if (subtotalBreakdown) {
        categoryItem.breakdown = {
          ...categoryItem.breakdown,
          ...subtotalBreakdown,
        };
      }

      return categoryItem;
    });

    const settlementExpenses = await Promise.all(expenseBreakdownItems);
    // There is just one Variable Cost category -- all others are Fixed Costs, so splicing Variable Cost out
    const variableCostIndex = settlementExpenses
      .findIndex((item) => item.categoryId === EXPENSE_CATEGORY_IDS.VARIABLE_COST);
    const variableCategoryItems = settlementExpenses.splice(variableCostIndex, 1);

    return {
      variableCategoryItems,
      fixedCategoryItems: settlementExpenses,
    };
  };

  const saveSettlementExpenses = async (sheetName: string, tablePrefix: string, phase: string) => {
    const { variableCategoryItems, fixedCategoryItems } = await parseSettlementExpenses(sheetName, tablePrefix);
    const totalVariableCostsToBase = await getValueByRangeName<string>(
      // FAR & ARTIST named ranges are the same.
      ARTIST_SETTLEMENT.TOTAL_BUDGET_VARIABLE_COSTS_ACTUAL_TO_BASE,
      sheetName,
    );
    const totalBudgetVariableFixedCosts = await getValueByRangeName<string>(
      // FAR & ARTIST named ranges are the same.
      ARTIST_SETTLEMENT.TOTAL_BUDGET_TOTAL_ACTUAL_TO_BASE,
      sheetName,
    );

    let phaseId = phase;
    if (nosId) {
      phaseId = getPhaseIDForNOS(nosId, phaseId);
    }

    const expense: ExpenseInput = {
      id: eventId as string,
      phases: [
        {
          id: phaseId,
          variableCategoryItems,
          fixedCategoryItems,
          totalVariableCostsToBase: toNumberOrUndefined(totalVariableCostsToBase),
          // TODO: TECH DEBT: make a ToBase version totalBudgetVariableFixedCostsToBase (also in modeling)
          totalBudgetVariableFixedCosts: toNumberOrUndefined(totalBudgetVariableFixedCosts),
        },
      ] as ExpensePhaseInput[],
    };

    if (phase === Phases.INTERNAL_SETTLEMENT) {
      const namedRangeValues = await getValuesFromNamedRanges({
        sheet: sheetName,
        namedRanges: [NAMED_RANGES.FAR_DETAILS.TOTAL_ANCILLARIES, FAR_DETAILS.TOTAL_ANCILLARIES_TO_BASE],
      });

      const totalAncillaries = toNumberOrUndefined(namedRangeValues[FAR_DETAILS.TOTAL_ANCILLARIES]);
      const totalAncillariesToBase = toNumberOrUndefined(namedRangeValues[FAR_DETAILS.TOTAL_ANCILLARIES_TO_BASE]);

      if (expense.phases && totalAncillaries) {
        expense.phases[0].ancillaryCategoryItems = [
          {
            categoryId: EXPENSE_CATEGORY_IDS.ANCILLARY_EARNINGS,
            total: totalAncillaries,
            totalToBase: totalAncillariesToBase,
          },
        ];
      }
    }

    return updateExpenseMutation({
      variables: {
        expense,
      },
    });
  };

  const renameBoxOfficeFeeTables = (show: Show, tableIndex: number) => {
    const tableNameUpdates: Promise<void>[] = [];
    const snakeCaseShowId = show?.id?.replaceAll('-', '_');

    const updateNames = (sheetName: string, prefix: string) =>
      Object.values(TABLE_IDENTIFIERS.boxOfficeTables).forEach((identifier) => {
        const updatedTableName = generateTableName(prefix, identifier, undefined, snakeCaseShowId ?? '');

        tableNameUpdates.push(
          updateTableName({
            sheetName,
            currTableName: `${prefix}_${identifier}_${tableIndex}`,
            updatedTableName,
          }),
        );
      });

    updateNames(AS_BOX_OFFICE_SHEET_NAME, NOS_TABLE_PREFIXES.ARTIST_SETTLEMENT);
    updateNames(FAR_BOX_OFFICE_SHEET_NAME, NOS_TABLE_PREFIXES.FAR_SETTLEMENT);

    return tableNameUpdates;
  };

  const generateBoxOfficeFeeData = async (show: Show, prefix: string) => {
    const feeDataToPopulate: PopulateTablesData[] = [];

    const boxOfficeFeesSheetData = await settlementTables.boxOfficeFeeTables(prefix, show.boxOffice?.id as string);

    // Use the first phase, as the passed in show will only have the right box office
    const phaseMap = show?.boxOffice?.phases![0];

    const formulaMap = {
      preTax: TAX_FEE_FORMULAS.PRE_TAX_TOTAL,
      tax: TAX_FEE_FORMULAS.TAX_TOTAL,
      postTax: TAX_FEE_FORMULAS.POST_TAX_TOTAL,
    };

    Object.entries(boxOfficeFeesSheetData).forEach(([key, { name, referenceTable, data }]) => {
      const categoryIds = referenceTable.map((item) => item.id);
      phaseMap?.fees?.forEach((fee) => {
        const itemId = fee?.profitLossCategoryId as string;
        if (!fee || !itemId) return;

        if (categoryIds.includes(fee?.profitLossCategoryId ?? '')) {
          const category = referenceTable.find((categoryIdMap) => categoryIdMap.id === itemId);

          // fee tables reference the corresponding box office tables in the total column formulas
          const boxOfficeTableName = generateTableName(
            prefix,
            TABLE_IDENTIFIERS.boxOfficeTables.boxOffice,
            undefined,
            show.boxOffice?.id as string,
          );

          // Tax fees reference the pre-tax table
          const preTaxFeeTableName = generateTableName(
            prefix,
            TABLE_IDENTIFIERS.boxOfficeTables.preTax,
            undefined,
            show.boxOffice?.id as string,
          );

          const formula = formulaMap[key as keyof typeof formulaMap]
            .replaceAll(FEE_TABLE_NAME_KEY, boxOfficeTableName)
            .replaceAll(PRE_TAX_TABLE_NAME_KEY, preTaxFeeTableName);

          // second column in the fee tables is either the formula or the notes
          const secondCol = key === 'postTax' ? fee?.notes : fee.formula;

          const item = [category?.name || '', secondCol || '', fee?.amount || 0, formula];
          data.push(item);
        }
      });

      if (data.length > 0) {
        feeDataToPopulate.push({
          tableData: {
            name,
            data,
          },
          cleanInsert: true,
          batchInsert: false,
        });
      }
    });

    return feeDataToPopulate;
  };

  const populateExpensesTables = async (expensePhase: ExpensePhase): Promise<PopulateTablesData[]> => {
    const expensesTablesData: PopulateTablesData[] = [];
    const expensesFixedCategories = expensePhase.fixedCategoryItems as FixedCategoryItem[];

    if (!expensesFixedCategories) {
      return expensesTablesData;
    }

    const populateFixedCategoryTables = expensesFixedCategories.reduce(async (prevPromise, category) => {
      await prevPromise;

      const { categoryId, items: categoryItems } = category;

      if (!categoryId || !categoryItems || !categoryItems.length) {
        return;
      }

      const { expenseBudget, expenseBreakdowns } = settlementTables;
      const expenseBudgetRowsPromises = Object.values(expenseBudget).map(async (value) => {
        const expenseBudgetReference = await value(NOS_TABLE_PREFIXES.ARTIST_SETTLEMENT);
        const { name: tableName, categoryId: referenceCategoryId, referenceTable } = expenseBudgetReference;

        if (referenceCategoryId !== categoryId) {
          return;
        }

        const expenseTableDataArray: TableDataArray = [];

        const expenseBudgetTableData = await parseTable<FixedCostRow>(
          AS_SETTLEMENT_SHEET_NAME,
          tableName,
          FIXED_COST_TABLE_ROW,
        );

        expenseBudgetTableData.forEach((item) => {
          const id = referenceTable.find((refRow) => refRow.name === item.Expenses)?.id;
          if (id) {
            const categoryItem = categoryItems.find((refRow) => refRow.id === id);

            const expenseTableDataArrayItem = [
              item.Expenses,
              getExpensesFormula(tableName, EXPENSES_FORMULAS.NOTES),
              categoryItem?.budget || '',
              getExpensesFormula(tableName, EXPENSES_FORMULAS.ACTUAL),
              EXPENSES_FORMULAS.DIFFERENCE,
              EXPENSES_FORMULAS.ACTUAL_BASE,
            ];

            expenseTableDataArray.push(expenseTableDataArrayItem);
          }
        });

        if (expenseTableDataArray.length > 0) {
          expensesTablesData.push({
            tableData: {
              name: expenseBudgetReference.name,
              data: expenseTableDataArray,
            },
            cleanInsert: true,
            batchInsert: false,
          });
        }
      });

      await Promise.all(expenseBudgetRowsPromises);

      const expenseBreakdownRowsPromises = Object.values(expenseBreakdowns).map(async (value) => {
        const expenseBudgetReference = await value(NOS_TABLE_PREFIXES.ARTIST_SETTLEMENT);
        const { name: tableName, categoryId: referenceCategoryId, referenceTable } = expenseBudgetReference;

        if (referenceCategoryId !== categoryId) {
          return;
        }

        const expenseTableDataArray: TableDataArray = [];

        const expenseNotesTableData = await parseTable<ExpenseRow>(
          AS_SETTLEMENT_SHEET_NAME,
          tableName,
          EXPENSE_TABLE_ROW,
        );

        expenseNotesTableData.forEach((item) => {
          const id = referenceTable.find((refRow) => refRow.name === item.name)?.id;
          if (id) {
            const categoryItem = categoryItems.find((refRow) => refRow.id === id);

            const expenseTableDataArrayItem = [
              item.name,
              categoryItem?.notes || '',
              '',
              '',
              getExpensesFormula(tableName, getAegRoadFormula(tableName)),
              '',
              '',
              '',
              getExpensesFormula(tableName, EXPENSES_FORMULAS.BREAKDOWN_TOTAL),
              getExpensesFormula(tableName, EXPENSES_FORMULAS.BREAKDOWN_TOTAL_TO_BASE),
            ];

            expenseTableDataArray.push(expenseTableDataArrayItem);
          }
        });

        if (expenseTableDataArray.length > 0) {
          expensesTablesData.push({
            tableData: {
              name: expenseBudgetReference.name,
              data: expenseTableDataArray,
            },
            cleanInsert: true,
            batchInsert: false,
          });
        }
      });

      await Promise.all(expenseBreakdownRowsPromises);
    }, Promise.resolve());

    await populateFixedCategoryTables;

    return expensesTablesData;
  };

  const populateVariableCostTable = async (expensePhase: ExpensePhase): Promise<PopulateTablesData> => {
    const variableCosts = expensePhase.variableCategoryItems;
    const variableCostsItemsById: { [key:string]: VariableCost } = {};
    variableCosts?.[0]?.items?.forEach((item) => {
      if (!item.id) {
        throw new Error('variableCostItem is missing P&L category ID');
      }

      variableCostsItemsById[item.id] = item;
    });

    const { variableCostBudgetTable } = settlementTables;
    const { referenceTable, name: tableName } = await variableCostBudgetTable();

    const variableCostsTableData: TableData = {
      name: tableName,
      data: [],
    };

    referenceTable.forEach((row) => {
      const variableCostItem = variableCostsItemsById[row.id];
      variableCostsTableData.data.push([
        row.name,
        VARIABLE_COSTS_FORMULAS.NOTES,
        variableCostItem?.calculatedCost || '',
        getExpensesFormula(tableName, EXPENSES_FORMULAS.ACTUAL),
        EXPENSES_FORMULAS.DIFFERENCE,
        EXPENSES_FORMULAS.ACTUAL_BASE,
      ]);
    });

    return {
      tableData: variableCostsTableData,
      cleanInsert: true,
      batchInsert: false,
    };
  };

  const formatInitCurrencyRanges = async (localCurrencyISO: string, baseCurrencySymbol: string) => {
    console.time('formatInitSheetsCurrencyRanges');

    const allNamedRangeAssignments = generateWorkbookInitCurrencyRanges(baseCurrencySymbol, localCurrencyISO);

    for (const { sheet, namedRangeAssignments } of allNamedRangeAssignments) {
      await applyFormatsToNamedRanges({
        sheet,
        namedRangeAssignments,
      });
    }

    console.timeEnd('formatInitSheetsCurrencyRanges');
  };

  const replaceAegLogosWithCMN = async () => {
    const currentSheetNames = await currentWorkbookSheetNames();
    const currentSheetsWithAEGLogo = currentSheetNames.filter((sheetName) => SHEETS_WITH_AEG_LOGO.includes(sheetName));
    for (const sheetName of currentSheetsWithAEGLogo) {
      await setWorkbookLogo(sheetName, cmnLogoPath);
    }
  };

  // initId could be the Event ID, or the NOS ID
  const initNOSSettlementWorkbook = async (initId: string) => {
    const workbookParentDir = await loadExcelParentDirectory();

    if (!initId) {
      throw new Error('Missing required parameters');
    }

    const workbookProperties = await getCustomProperties();
    if (NOS_SETTLEMENT_INITIALIZED_KEY in workbookProperties) {
      // This applies backwards compatibility with existing workbooks:
      // eslint-disable-next-line max-len
      if (
        !(WORKBOOK_ORIGINAL_PARENT_DIR_PATH in workbookProperties)
        || !(WORKBOOK_ORIGINAL_PARENT_DIR_TOUR_FOLDER in workbookProperties)
      ) {
        await saveWorkbookOriginalFileLocation(workbookParentDir);
      }
      // check if file location changed when wb is first opened with Add-In
      await checkIfWorkbookIsSyncedToSharepoint();
      await addActivationListenerToCheckForSharepoint();

      return;
    }

    const hideAegLoadingDialog = await displayAegLoadingDialog();

    const { data: plCatResponse, error: plCatError } = await getPLCategories();

    const { data: nosLatest, error: eventError } = await getNosEvent({
      variables: { nosId: initId },
    });

    const nos = nosLatest?.nosSettlement;

    const couldNotGetData = plCatError || !plCatResponse?.profitLossCategories || eventError
      || !nosLatest?.nosSettlement || !nos?.event;
    if (couldNotGetData) {
      throw new Error('Failed to load data in workbook init');
    }

    const REPLACE_COMPANY_LOGO = getFlag(FeatureFlags.REPLACE_COMPANY_LOGO);
    if (nos?.event?.tourInformation?.company?.id === CMN_COMPANY_ID && REPLACE_COMPANY_LOGO) {
      await replaceAegLogosWithCMN();
    }

    const plCategoryMap = getPlCategoryMap(plCatResponse);
    const populatePLCategoryTablesData: PopulateTablesData[] = [];
    const unpopulatedVariableCostPlRefTable = (await settlementTables.variableCostBudgetTable()).referenceTable;

    plCategoryMap.forEach((entry, key) => {
      let data = entry;
      if (key === 'Variable Costs') {
        data = retainTemplateOrderForPlRefTable(entry, unpopulatedVariableCostPlRefTable);
      }
      const payload = buildPlCategoryPayload(key, data);
      populatePLCategoryTablesData.push(payload);
    });

    await populateMultipleTables(PROFIT_LOSS_REF_SHEET_NAME, populatePLCategoryTablesData);

    // Get Event data
    const event = nos?.event as Event;
    const expensePhase = nos?.populatedExpensePhase as ExpensePhase;
    const shows = nos?.populatedShows as Show[];

    const expenseTableData: PopulateTablesData[] = await populateExpensesTables(expensePhase);
    const variableCostTableData = await populateVariableCostTable(expensePhase);
    expenseTableData.push(variableCostTableData);
    await populateMultipleTables(AS_SETTLEMENT_SHEET_NAME, expenseTableData);

    const [firstShowDate] = getTimezoneDate(shows[0]?.showDateTime, event?.venue?.timezone);

    if (shows[0]?.showDateTime) {
      const artistSettlementData: PopulateTablesData[] = [];

      // Promise.all is problematic for Windows 32 bit Excel users
      for (let index = 0; index < shows?.length; index += 1) {
        const show = shows[index];
        const boxOfficePromises = renameBoxOfficeFeeTables(show, index + 1);
        for (const boxOfficePromise of boxOfficePromises) {
          await boxOfficePromise;
        }
        const artistTableData = await generateBoxOfficeFeeData(
          show,
          NOS_TABLE_PREFIXES.ARTIST_SETTLEMENT,
        );

        if (index === 0) {
          // Populate all tables for first show
          artistSettlementData.push(...artistTableData);
        } else {
          // For shows 2+, populate only Post Tax table (other tables are auto-filled)
          const postTaxFeesData = artistTableData.filter(
            (item) => item.tableData.name.includes(TABLE_IDENTIFIERS.boxOfficeTables.postTax),
          );
          artistSettlementData.push(...postTaxFeesData);
        }
      }

      await populateMultipleTables(AS_BOX_OFFICE_SHEET_NAME, artistSettlementData.flat());
    }

    const { localCurrency, baseCurrency } = event;

    // Set Named Ranges
    const settlementNamedRanges: NamedRangeAssignment[] = [
      {
        range: SETTLEMENT.ARTIST_NAME,
        value: String(event.headliner?.name),
      },
      {
        range: SETTLEMENT.TOUR_NAME,
        value: String(event.tourInformation?.tourName),
      },
      {
        range: SETTLEMENT.MARKET_NAME,
        value: eventMarketName(event),
      },
      {
        range: SETTLEMENT.VENUE_NAME,
        value: event.space?.name,
      },
      {
        range: SETTLEMENT.SHOW_DATE,
        value: String(firstShowDate),
      },
      {
        range: SETTLEMENT.NUM_SHOWS,
        value: shows?.length.toString(),
      },
      {
        range: SETTLEMENT.NUM_TRUCKS,
        value: event.tourInformation?.numberOfTrucks?.toString(),
      },
      {
        range: SETTLEMENT.STATUS,
        value: String(event.status),
      },
      {
        range: SETTLEMENT.LOCAL_CURRENCY,
        value: String(localCurrency),
      },
      {
        range: SETTLEMENT.BASE_CURRENCY,
        value: String(baseCurrency),
      },
      {
        range: SETTLEMENT.EXCHANGE_RATE,
        value: event.exchangeRate?.toString(),
      },
      {
        range: SETTLEMENT.COUNTRY,
        value: event.venue?.country?.toString(),
      },
      {
        range: AS_DETAILS.GUARANTEE,
        value: nos?.artistSettlement?.artistSettlement?.guarantee?.toString(),
      },
      {
        range: AS_DETAILS.PCT_NET_POOL_EARN,
        value: nos?.artistSettlement?.artistSettlement?.pctOfNetPoolEarnings?.toString(),
      },
      {
        range: AS_DETAILS.ADJ_NOTES,
        value: nos?.artistSettlement?.artistSettlement?.artistAdjustmentNote?.toString(),
      },
      {
        range: AS_DETAILS.ADJUSTMENT,
        value: nos?.artistSettlement?.artistSettlement?.artistAdjustmentTotal?.toString(),
      },
      {
        range: AS_DETAILS.ADJUSTMENT,
        value: nos?.artistSettlement?.artistSettlement?.artistAdjustmentTotal?.toString(),
      },
      {
        range: AS_DETAILS.NOTE_COMMENTS,
        value: nos?.artistSettlement?.notesAndComments?.toString(),
      },
    ];

    await applyValuesToNamedRanges({
      sheet: AS_SETTLEMENT_SHEET_NAME,
      namedRangeAssignments: settlementNamedRanges,
    });

    // check for existence of workbook v1.14 currency named range before adding currency formatting
    // const workbookHasCurrencyRanges = await checkWorksheetForNamedRange(
    //   FAR_SETTLEMENT_SHEET_NAME,
    //   NAMED_RANGES.FAR_SETTLEMENT.LOCAL_CURRENCY_ROUNDED[2], // currency range added in workbook version v1.14
    // );

    // if (workbookHasCurrencyRanges && baseCurrency && localCurrency) {
    //   const baseCurrencySymbol = getCurrencySymbol(baseCurrency);
    //   if (!baseCurrencySymbol) {
    //     throw new Error(`Currency symbol not found for ISO code ${baseCurrency}`);
    //   }

    //   await formatInitCurrencyRanges(localCurrency, baseCurrencySymbol);
    // } else {
    //   const warningMessage = workbookHasCurrencyRanges
    //     // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
    //     ? `Currency formatting not set. Base currency (${baseCurrency})
    // or local currency (${localCurrency}) not found for event`
    //     : 'Currency formatting not set. Workbook does not have currency named ranges';
    //   console.warn(warningMessage);
    // }

    await setCustomProperty(NOS_SETTLEMENT_INITIALIZED_KEY, new Date().toString());
    await saveWorkbookOriginalFileLocation(workbookParentDir);

    hideAegLoadingDialog();
  };

  const parseBoxOfficeFees = async (
    sheetName: string,
    tablePrefix: string,
    showId: string,
  ): Promise<BoxOfficeFee[]> => {
    const preTaxTable = generateTableName(tablePrefix, 'PreTaxFees', undefined, showId);
    const taxTable = generateTableName(tablePrefix, 'Taxes', undefined, showId);
    const postTaxTable = generateTableName(tablePrefix, 'PostTaxFees', undefined, showId);

    const preTaxData = await parseTable<BoxOfficeFeeRow>(sheetName, preTaxTable, BOX_OFFICE_FEE_TABLE_ROW);
    const taxData = await parseTable<BoxOfficeFeeRow>(sheetName, taxTable, BOX_OFFICE_FEE_TABLE_ROW);
    const postTaxData = await parseTable<BoxOfficeFeeRow>(sheetName, postTaxTable, BOX_OFFICE_FEE_TABLE_ROW);

    // Ref tables
    const boxOfficeFeesSheetData = await settlementTables.boxOfficeFeeTables(tablePrefix, showId);
    const { referenceTable: preTaxRefTable } = boxOfficeFeesSheetData.preTax;
    const { referenceTable: taxRefTable } = boxOfficeFeesSheetData.tax;
    const { referenceTable: postTaxRefTable } = boxOfficeFeesSheetData.postTax;

    const boxOfficeFees: BoxOfficeFee[] = [];

    boxOfficeFees.push(...generateBoxOfficeFeeItems(preTaxData, preTaxRefTable));
    boxOfficeFees.push(...generateBoxOfficeFeeItems(taxData, taxRefTable));
    boxOfficeFees.push(...generateBoxOfficeFeeItems(postTaxData, postTaxRefTable));

    return boxOfficeFees;
  };

  const getBoxOffices = async (shows: Show[], phase: Phases) => {
    if (!shows || shows.length === 0) return [];
    let tablePrefix = '';
    let sheetName = '';
    const tableShape = NOS_BOX_OFFICE_TABLE_ROW;
    if (phase === Phases.ARTIST_SETTLEMENT) {
      tablePrefix = NOS_TABLE_PREFIXES.ARTIST_SETTLEMENT;
      sheetName = AS_BOX_OFFICE_SHEET_NAME;
    } else if (phase === Phases.INTERNAL_SETTLEMENT) {
      tablePrefix = NOS_TABLE_PREFIXES.FAR_SETTLEMENT;
      sheetName = FAR_BOX_OFFICE_SHEET_NAME;
    } else {
      throw new Error('Invalid phase passed into getBoxOffices');
    }

    const boxOfficePromises = shows.map(async (show) => {
      if (!show || !show.id) {
        throw new Error('Show ID does not exist on Event Show');
      }

      const boxOfficeTableName = generateTableName(tablePrefix, 'BoxOffice', undefined, show?.id);
      const boxOfficeData = await parseTable<BoxOfficeItem>(sheetName, boxOfficeTableName, tableShape);
      const boxOfficeFees = await parseBoxOfficeFees(sheetName, tablePrefix, show.id);

      const result: BoxOffice = {
        id: show.id,
        phases: [
          {
            id: phase,
            items: boxOfficeData,
            fees: boxOfficeFees,
          },
        ],
      };
      return result;
    });

    const boxOffices = await Promise.all(boxOfficePromises);

    return boxOffices;
  };

  async function parseSettlementPaymentTable<SettlementPaymentInput extends Record<string, unknown>>(
    sheetName: string,
    tableName: string,
    rowShape: SettlementPaymentInput,
    namedRangeValues: Record<string, string | number | undefined>,
  ): Promise<SettlementPaymentInput[]> {
    const table = await parseTable<SettlementPaymentInput>(sheetName, tableName, rowShape);

    const data = table.map((item) => ({
      ...item,
      localCurrency: toStringOrUndefined(namedRangeValues[SETTLEMENT.LOCAL_CURRENCY]),
      exchangeRate: toNumberOrUndefined(namedRangeValues[SETTLEMENT.EXCHANGE_RATE]),
    }));

    return data;
  }

  const generateArtistSettlementInput = async (): Promise<ArtistNosSettlementDetailInput> => {
    if (!eventId) {
      throw new Error('Missing required parameters');
    }

    const filteredNamedRanges = Object.values(AS_DETAILS).filter(
      (item: string) =>
        item !== AEG_SETTLEMENT.EXPENSE_ADJUSTMENT
        && item !== AEG_SETTLEMENT.EXPENSE_ADJUSTMENT_BASE
        && item !== AEG_SETTLEMENT.OTHER_ADJUSTMENT
        && item !== AEG_SETTLEMENT.OTHER_ADJUSTMENT_BASE,
    );

    const namedRanges = filteredNamedRanges.concat(SETTLEMENT_ADJUSTMENTS);

    const namedRangeValues = await getValuesFromNamedRanges({
      sheet: AS_SETTLEMENT_SHEET_NAME,
      namedRanges,
    });

    const getString = (key: string) => toStringOrUndefined(namedRangeValues[key]);
    const getNum = (key: string) => toNumberOrUndefined(namedRangeValues[key]);

    const artistPaymentDueData = await parseSettlementPaymentTable<SettlementPaymentInput>(
      AS_SETTLEMENT_SHEET_NAME,
      AS_ARTIST_PAYMENT_TABLE_NAME,
      SETTLEMENT_PAYMENT_TABLE_ROW,
      namedRangeValues,
    );

    const venueSettlementData = await parseSettlementPaymentTable<SettlementPaymentInput>(
      AS_SETTLEMENT_SHEET_NAME,
      AS_VENUE_SETTLEMENT_TABLE_NAME,
      SETTLEMENT_PAYMENT_TABLE_ROW,
      namedRangeValues,
    );

    const showAdjustmentData = getShowAdjustmentData(namedRangeValues);

    const artistSettlementData: ArtistNosSettlementDetailInput = {
      actualBoxOfficeOverview: {
        totalAvailableCapacity: getNum(ARTIST_SETTLEMENT.TOTAL_AVAILABLE_CAPACITY),
        totalSellableCapacity: getNum(ARTIST_SETTLEMENT.TOTAL_SELLABLE_CAPACITY),
        totalGross: getNum(ARTIST_SETTLEMENT.TOTAL_GROSS),
        totalGrossToBase: getNum(ARTIST_SETTLEMENT.TOTAL_GROSS_TO_BASE),
      },
      actualPostTaxAdjustmentsTotal: getNum(ARTIST_SETTLEMENT.POST_TAX_ADJUSTMENTS_TOTAL),
      actualPostTaxAdjustmentsTotalToBase: getNum(ARTIST_SETTLEMENT.POST_TAX_ADJUSTMENTS_TOTAL_TO_BASE),
      netShowReceipts: {
        netGrossReceiptsTotal: getNum(ARTIST_SETTLEMENT.NET_GROSS_RECEIPTS),
        netGrossReceiptsTotalToBase: getNum(ARTIST_SETTLEMENT.NET_GROSS_RECEIPTS_TO_BASE),
        netShowExpensesTotal: getNum(ARTIST_SETTLEMENT.SHOW_EXPENSES),
        netShowExpensesTotalToBase: getNum(ARTIST_SETTLEMENT.SHOW_EXPENSES_TO_BASE),
        showAdjustments: showAdjustmentData,
        netShowReceiptsTotalToBase: getNum(AS_DETAILS.NET_SHOW_RECEIPTS_BASE),
      },
      localCurrency: getString(AS_DETAILS.LOCAL_CURRENCY),
      baseCurrency: getString(AS_DETAILS.BASE_CURRENCY),
      exchangeRate: getNum(AS_DETAILS.EXCHANGE_RATE),
      preparedBy: getString(AS_DETAILS.PREPARED_BY),
      preparedOn: getString(AS_DETAILS.PREPARED_ON),
      artistRep: getString(AS_DETAILS.ARTIST_REP),
      contact: getString(AS_DETAILS.CONTACT),
      notesAndComments: getString(AS_DETAILS.NOTE_COMMENTS),
      artistSettlement: {
        guarantee: getNum(AS_DETAILS.GUARANTEE),
        guaranteeToBase: getNum(AS_DETAILS.GUARANTEE_BASE),
        pctOfNetPoolEarnings: getNum(AS_DETAILS.PCT_NET_POOL_EARN),
        netPoolEarningsTotal: getNum(AS_DETAILS.NET_POOL_EARN),
        netPoolEarningsTotalToBase: getNum(AS_DETAILS.NET_POOL_EARN_BASE),
        artistAdjustmentNote: getString(AS_DETAILS.ADJ_NOTES),
        artistAdjustmentTotal: getNum(AS_DETAILS.ADJUSTMENT),
        artistAdjustmentTotalToBase: getNum(AS_DETAILS.ADJUSTMENT_BASE),
        artistNosSettlementTotal: getNum(AS_DETAILS.SETTLEMENT_TOTAL),
        artistNosSettlementTotalToBase: getNum(AS_DETAILS.SETTLEMENT_TOTAL_BASE),
      },
      aegSettlement: {
        netShowReceiptsTotal: getNum(AS_DETAILS.NET_SHOW_RECEIPTS),
        artistEarningsTotal: getNum(AS_DETAILS.ARTIST_EARN),
        artistEarningsTotalToBase: getNum(AS_DETAILS.ARTIST_EARN_BASE),
        netProfitOrLossTotal: getNum(AS_DETAILS.NET_PROFIT_LOSS),
        netProfitOrLossTotalToBase: getNum(AS_DETAILS.NET_PROFIT_LOSS_BASE),
        pctCoPromoterShare: getNum(AS_DETAILS.PCT_CO_PRO_SHARE),
        coPromoterShareTotal: getNum(AS_DETAILS.CO_PRO_SHARE),
        coPromoterShareTotalToBase: getNum(AS_DETAILS.CO_PRO_SHARE_BASE),
        netProfitOrLossToAegTotal: getNum(AS_DETAILS.AEG_NET_PROFIT_LOSS),
        netProfitOrLossToAegTotalToBase: getNum(AS_DETAILS.AEG_NET_PROFIT_LOSS_BASE),
      },
      compTicketSummary: {
        artistAndTour: getNum(AS_DETAILS.ARTIST_TOUR),
        venue: getNum(AS_DETAILS.VENUE),
        promoter: getNum(AS_DETAILS.PROMOTER),
        promotion: getNum(AS_DETAILS.PROMOTION),
        relocateAndTech: getNum(AS_DETAILS.RELOCATE_TECH),
        sponsor: getNum(AS_DETAILS.SPONSOR),
      },
      dropCountSummary: {
        paidAndComp: getNum(AS_DETAILS.PAID_COMP),
        suite: getNum(AS_DETAILS.SUITE),
        other: getNum(AS_DETAILS.OTHER),
        unredeemed: getNum(AS_DETAILS.UNREDEEMED),
      },
      artistPaymentDue: artistPaymentDueData,
      venueSettlement: venueSettlementData,
    };

    return artistSettlementData;
  };

  const generateFarSettlementInput = async (): Promise<InternalNosSettlementDetailInput> => {
    if (!eventId) {
      throw new Error('Missing required parameters');
    }

    const filteredNamedRanges = Object.values(FAR_DETAILS).filter(
      (item: string) => item !== AEG_SETTLEMENT.PCT_CO_PRO_SHARE,
    );

    const namedRanges = filteredNamedRanges.concat(SETTLEMENT_ADJUSTMENTS);

    const namedRangeValues = await getValuesFromNamedRanges({
      sheet: FAR_SETTLEMENT_SHEET_NAME,
      namedRanges,
    });

    const netToPoolNamedRanges = Object.values(NET_TO_POOL);

    const netToPooNamedRanges = await getValuesFromNamedRanges({
      sheet: SETTLEMENT_EMAIL_TEMPLATE_SHEET_NAME,
      namedRanges: netToPoolNamedRanges,
    });

    const getString = (key: string) => toStringOrUndefined(namedRangeValues[key]);
    const getNum = (key: string) => toNumberOrUndefined(namedRangeValues[key]);

    const artistPaymentDueData = await parseSettlementPaymentTable<SettlementPaymentInput>(
      FAR_SETTLEMENT_SHEET_NAME,
      FAR_ARTIST_PAYMENT_TABLE_NAME,
      SETTLEMENT_PAYMENT_TABLE_ROW,
      namedRangeValues,
    );

    const venueSettlementData = await parseSettlementPaymentTable<SettlementPaymentInput>(
      FAR_SETTLEMENT_SHEET_NAME,
      FAR_VENUE_SETTLEMENT_TABLE_NAME,
      SETTLEMENT_PAYMENT_TABLE_ROW,
      namedRangeValues,
    );

    const ancillaryEarningsData = await parseSettlementPaymentTable<SettlementPaymentInput>(
      FAR_SETTLEMENT_SHEET_NAME,
      FAR_ANCILLARY_EARNINGS_TABLE_NAME,
      SETTLEMENT_PAYMENT_TABLE_ROW,
      namedRangeValues,
    );

    const showAdjustmentData = getShowAdjustmentData(namedRangeValues);

    const getUseFarBoxOfficeValue = async () => {
      // check for existence of named range for backwards compatibility
      const namedRangeExists = await checkWorksheetForNamedRange(
        FAR_SETTLEMENT_SHEET_NAME,
        FAR_DETAILS.USE_FAR_BOX_OFFICE,
      );

      if (namedRangeExists) {
        return getString(FAR_DETAILS.USE_FAR_BOX_OFFICE) === 'Yes';
      }
      return undefined;
    };

    const farSettlementData: InternalNosSettlementDetailInput = {
      actualBoxOfficeOverview: {
        totalAvailableCapacity: getNum(FAR_DETAILS.TOTAL_AVAILABLE_CAPACITY),
        totalSellableCapacity: getNum(FAR_DETAILS.TOTAL_SELLABLE_CAPACITY),
        totalGross: getNum(FAR_DETAILS.TOTAL_GROSS),
        totalGrossToBase: getNum(FAR_DETAILS.TOTAL_GROSS_TO_BASE),
      },
      actualPostTaxAdjustmentsTotal: getNum(FAR_DETAILS.POST_TAX_ADJUSTMENTS_TOTAL),
      actualPostTaxAdjustmentsTotalToBase: getNum(FAR_DETAILS.POST_TAX_ADJUSTMENTS_TOTAL_TO_BASE),
      netShowReceipts: {
        netGrossReceiptsTotal: getNum(FAR_DETAILS.NET_GROSS_RECEIPTS),
        netGrossReceiptsTotalToBase: getNum(FAR_DETAILS.NET_GROSS_RECEIPTS_TO_BASE),
        netShowExpensesTotal: getNum(FAR_DETAILS.SHOW_EXPENSES),
        netShowExpensesTotalToBase: getNum(FAR_DETAILS.SHOW_EXPENSES_TO_BASE),
        showAdjustments: showAdjustmentData,
        netShowReceiptsTotalToBase: getNum(FAR_DETAILS.NET_SHOW_RECEIPTS_BASE),
      },
      localCurrency: getString(FAR_DETAILS.LOCAL_CURRENCY),
      baseCurrency: getString(FAR_DETAILS.BASE_CURRENCY),
      exchangeRate: getNum(FAR_DETAILS.EXCHANGE_RATE),
      preparedBy: getString(FAR_DETAILS.PREPARED_BY),
      preparedOn: getString(FAR_DETAILS.PREPARED_ON),
      artistRep: getString(FAR_DETAILS.ARTIST_REP),
      contact: getString(FAR_DETAILS.CONTACT),
      notesAndComments: getString(FAR_DETAILS.NOTE_COMMENTS),
      artistSettlement: {
        guarantee: getNum(FAR_DETAILS.GUARANTEE),
        guaranteeToBase: getNum(FAR_DETAILS.GUARANTEE_BASE),
        pctOfNetPoolEarnings: getNum(FAR_DETAILS.PCT_NET_POOL_EARN),
        netPoolEarningsTotal: getNum(FAR_DETAILS.NET_POOL_EARN),
        netPoolEarningsTotalToBase: getNum(FAR_DETAILS.NET_POOL_EARN_BASE),
        artistAdjustmentNote: getString(FAR_DETAILS.ADJ_NOTES),
        artistAdjustmentTotal: getNum(FAR_DETAILS.ADJUSTMENT),
        artistAdjustmentTotalToBase: getNum(FAR_DETAILS.ADJUSTMENT_BASE),
        artistNosSettlementTotal: getNum(FAR_DETAILS.SETTLEMENT_TOTAL),
        artistNosSettlementTotalToBase: getNum(FAR_DETAILS.SETTLEMENT_TOTAL_BASE),
      },
      aegSettlement: {
        netShowReceiptsTotal: getNum(FAR_DETAILS.NET_SHOW_RECEIPTS),
        artistEarningsTotal: getNum(FAR_DETAILS.ARTIST_EARN),
        artistEarningsTotalToBase: getNum(FAR_DETAILS.ARTIST_EARN_BASE),
        netProfitOrLossTotal: getNum(FAR_DETAILS.NET_PROFIT_LOSS),
        netProfitOrLossTotalToBase: getNum(FAR_DETAILS.NET_PROFIT_LOSS_BASE),
        coPromoterShareTotal: getNum(FAR_DETAILS.CO_PRO_SHARE),
        coPromoterShareTotalToBase: getNum(FAR_DETAILS.CO_PRO_SHARE_BASE),
        netProfitOrLossToAegTotal: getNum(FAR_DETAILS.AEG_NET_PROFIT_LOSS),
        netProfitOrLossToAegTotalToBase: getNum(FAR_DETAILS.AEG_NET_PROFIT_LOSS_BASE),
        expenseAdjustmentTotal: getNum(FAR_DETAILS.EXPENSE_ADJUSTMENT),
        expenseAdjustmentTotalToBase: getNum(FAR_DETAILS.EXPENSE_ADJUSTMENT_BASE),
        otherAdjustmentTotal: getNum(FAR_DETAILS.OTHER_ADJUSTMENT),
        otherAdjustmentTotalToBase: getNum(FAR_DETAILS.OTHER_ADJUSTMENT_BASE),
        ancillaryIncomeTotalToBase: getNum(FAR_DETAILS.ANCILLARY_INCOME_TO_BASE),
      },
      artistPaymentDue: artistPaymentDueData,
      venueSettlement: venueSettlementData,
      ancillaryEarnings: ancillaryEarningsData,
      netToPool: {
        actual: toNumberOrUndefined(netToPooNamedRanges[NET_TO_POOL.ACTUAL]),
        projected: toNumberOrUndefined(netToPooNamedRanges[NET_TO_POOL.PROJECTED]),
        variance: toNumberOrUndefined(netToPooNamedRanges[NET_TO_POOL.VARIANCE]),
      },
      useFarBoxOffice: await getUseFarBoxOfficeValue(),
    };

    return farSettlementData;
  };

  const generateNOSSettlementSummaryInput = async (): Promise<SummaryDetailInput> => {
    const namedRangeLookups = [
      ...Object.values(NOS_SETTLEMENT_SUMMARY.EARNINGS_SUMMARY),
      ...Object.values(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES),
      ...Object.values(NOS_SETTLEMENT_SUMMARY.ADJUSTMENTS),
      ...Object.values(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT),
    ];

    const namedRangeValues = await getValuesFromNamedRanges({
      sheet: SETTLEMENT_SUMMARY_SHEET_NAME,
      namedRanges: namedRangeLookups,
    });

    const getNum = (key: string) => toNumberOrUndefined(namedRangeValues[key]);

    const settlementSummaryData: SummaryDetailInput = {
      earningsSummary: {
        gross: getNum(NOS_SETTLEMENT_SUMMARY.EARNINGS_SUMMARY.GROSS),
        grossToBase: getNum(NOS_SETTLEMENT_SUMMARY.EARNINGS_SUMMARY.GROSS_BASE),
        preTaxAdjustment: getNum(NOS_SETTLEMENT_SUMMARY.EARNINGS_SUMMARY.PRE_TAX_ADJ),
        preTaxAdjustmentToBase: getNum(NOS_SETTLEMENT_SUMMARY.EARNINGS_SUMMARY.PRE_TAX_ADJ_BASE),
        taxes: getNum(NOS_SETTLEMENT_SUMMARY.EARNINGS_SUMMARY.TAXES),
        taxesToBase: getNum(NOS_SETTLEMENT_SUMMARY.EARNINGS_SUMMARY.TAXES_BASE),
        postTaxAdjustment: getNum(NOS_SETTLEMENT_SUMMARY.EARNINGS_SUMMARY.POST_TAX_ADJ),
        postTaxAdjustmentToBase: getNum(NOS_SETTLEMENT_SUMMARY.EARNINGS_SUMMARY.POST_TAX_ADJ_BASE),
        netGross: getNum(NOS_SETTLEMENT_SUMMARY.EARNINGS_SUMMARY.NET_GROSS),
        netGrossToBase: getNum(NOS_SETTLEMENT_SUMMARY.EARNINGS_SUMMARY.NET_GROSS_BASE),
      },
      showExpenses: {
        advertising: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.ADVERTISING),
        advertisingToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.ADVERTISING_BASE),
        artistProrates: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.ARTISTS_PRORATES),
        artistProratesToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.ARTISTS_PRORATES_BASE),
        productionProrates: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.PRODUCTION_PRORATES),
        productionProratesToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.PRODUCTION_PRORATES_BASE),
        support: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.SUPPORT),
        supportToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.SUPPORT_BASE),
        rent: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.RENT),
        rentToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.RENT_BASE),
        stagehands: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.STAGEHANDS),
        stagehandsToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.STAGEHANDS_BASE),
        catering: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.CATERING),
        cateringToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.CATERING_BASE),
        staffing: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.STAFFING),
        staffingToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.STAFFING_BASE),
        venue: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.VENUE),
        venueToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.VENUE_BASE),
        production: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.PRODUCTION),
        productionToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.PRODUCTION_BASE),
        transport: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.TRANSPORT),
        transportToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.TRANSPORT_BASE),
        other: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.OTHER),
        otherToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.OTHER_BASE),
        variable: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.VARIABLE),
        variableToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.VARIABLE_BASE),
        totalExpenses: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.TOTAL_EXPENSES),
        totalExpensesToBase: getNum(NOS_SETTLEMENT_SUMMARY.SHOW_EXPENSES.TOTAL_EXPENSES_BASE),
      },
      adjustments: {
        netShowReceipts: getNum(NOS_SETTLEMENT_SUMMARY.ADJUSTMENTS.NET_SHOW_RECEIPTS),
        netShowReceiptsToBase: getNum(NOS_SETTLEMENT_SUMMARY.ADJUSTMENTS.NET_SHOW_RECEIPTS_BASE),
      },
      artistSettlement: {
        guarantee: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.GUARANTEE),
        guaranteeToBase: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.GUARANTEE_BASE),
        pctOfNetPoolEarnings: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.PCT_OF_NET_POOL_EARNINGS),
        netPoolEarnings: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.NET_POOL_EARNINGS),
        netPoolEarningsToBase: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.NET_POOL_EARNINGS_BASE),
        artistAdjustment: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.ARTIST_ADJUSTMENT),
        artistAdjustmentToBase: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.ARTIST_ADJUSTMENT_BASE),
        artistEarnings: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.ARTIST_EARNINGS),
        artistEarningsToBase: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.ARTIST_EARNINGS_BASE),
        deposits: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.DEPOSITS),
        depositsToBase: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.DEPOSITS_BASE),
        artistTickets: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.ARTIST_TICKETS),
        artistTicketsToBase: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.ARTIST_TICKETS_BASE),
        cashAdvances: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.CASH_ADVANCES),
        cashAdvancesToBase: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.CASH_ADVANCES_BASE),
        bandReimbursements: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.BAND_REIMBURSEMENTS),
        bandReimbursementsToBase: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.BAND_REIMBURSEMENTS_BASE),
        other: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.AS_SUM_OTHER),
        otherToBase: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.AS_SUM_OTHER_BASE),
        totalDueArtist: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.TOTAL_DUE_ARTIST),
        totalDueArtistToBase: getNum(NOS_SETTLEMENT_SUMMARY.ARTIST_SETTLEMENT.TOTAL_DUE_ARTIST_BASE),
      },
    };

    return settlementSummaryData;
  };

  const createBoxOfficeMutationPromises = (boxOffice: BoxOffice) => {
    let phaseId = boxOffice.phases?.[0].id ?? '';
    if (nosId) {
      phaseId = getPhaseIDForNOS(nosId, phaseId);
    }

    const modifyBoxOfficeInput: ModifyBoxOfficePhaseInput = {
      items: boxOffice.phases?.[0]?.items,
      fees: boxOffice.phases?.[0]?.fees,
    };
    const modifyPayload = {
      variables: {
        boxOfficeId: boxOffice.id,
        boxOfficePhaseId: phaseId,
        boxOfficePhase: modifyBoxOfficeInput,
      },
    };

    return upsertBoxOfficePhase(modifyPayload);
  };

  const syncNosSettlementWorkbook = async () => {
    if (!eventId) {
      throw new Error('Missing required parameters');
    }

    await checkIfWorkbookIsSyncedToSharepoint();

    const artistSettlement = await generateArtistSettlementInput();
    const internalSettlement = await generateFarSettlementInput();
    const summary = await generateNOSSettlementSummaryInput();

    // Get Event data
    const nosLatest = await getNosEvent({ variables: { nosId: eventId } });
    const shows = nosLatest.data?.nosSettlement?.populatedShows as Show[];

    // Sync the Event Box Offices
    const boxOfficePromises: Promise<unknown>[] = [];
    const asBoxOffices = await getBoxOffices(shows, Phases.ARTIST_SETTLEMENT);
    const mutateASBoxOffices = asBoxOffices.map((boxOffice) => createBoxOfficeMutationPromises(boxOffice));

    const farBoxOffices = await getBoxOffices(shows, Phases.INTERNAL_SETTLEMENT);
    const mutateFARBoxOffices = farBoxOffices.map((boxOffice) => createBoxOfficeMutationPromises(boxOffice));

    boxOfficePromises.push(...mutateASBoxOffices, ...mutateFARBoxOffices);

    await Promise.allSettled(boxOfficePromises);

    // Sync, Parse, and Save Artist Settlement Expenses
    await saveSettlementExpenses(
      AS_SETTLEMENT_SHEET_NAME,
      NOS_TABLE_PREFIXES.ARTIST_SETTLEMENT,
      Phases.ARTIST_SETTLEMENT,
    );

    // Sync, Parse, and Save FAR/Internal Settlement Expenses
    await saveSettlementExpenses(
      FAR_SETTLEMENT_SHEET_NAME,
      NOS_TABLE_PREFIXES.FAR_SETTLEMENT,
      Phases.INTERNAL_SETTLEMENT,
    );

    const NosSettlementPayload = {
      variables: {
        nosSettlement: {
          id: nosId ?? eventId,
          artistSettlement,
          internalSettlement,
          summary,
        },
      },
    };

    await syncNosSettlement(NosSettlementPayload);
  };

  return {
    initNOSSettlementWorkbook,
    syncNosSettlementWorkbook,
  };
}
