// TODO uncomment after UAT testing

export enum ModelingActionOptions {
  RoutingConfirmed,
  Confirmed,
  Refresh,
}

interface ActionDropdownOptions {
  canConfirm?: boolean
}

export const actionOptions = (options?: ActionDropdownOptions) => ([
  { value: ModelingActionOptions.RoutingConfirmed, label: 'Share with Marketing' },
  ...(options && options.canConfirm ? [{ value: ModelingActionOptions.Confirmed, label: 'Confirm Offer' }] : []),
]);
