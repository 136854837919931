/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-lines-per-function */
import { useAegModeling, useExcel, useExcelErrorHandler } from '@hooks';
import {
  DialogContext,
  EnvStatusContext,
  EnvironmentStatus,
  ExcelErrorType,
  ExcelUserContext,
  LoadingContext,
  NotificationContext,
} from '@providers';
import { CircularProgress } from '@mui/material';
import {
  useCallback, useContext, useEffect,
} from 'react';
import { Outlet } from 'react-router-dom';
import { AegDialog, AegSnackbar } from '@components';
import { ExcelWorkbookLaunchMode } from '@types';
import { GET_TOUR_AND_OFFER_ONLY } from '@gql/queries/tours';
import { getNetworkErrorInfo } from '@utils/errorHelpers';
import { nosIdWithVersionRegex, uuidRegex } from '@utils/stringHelpers';
import { useLazyQuery } from '@apollo/client';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import { WORKBOOK_CUSTOM_PROPERTIES } from '@utils/excel/shared/constants';
import { StyledBackdrop } from './ExcelTaskPaneLayout.styled';

export const ExcelTaskPaneLayout = () => {
  const appInsights = useAppInsightsContext();
  const { envStatus, updateEnvStatusProperty } = useContext(EnvStatusContext);
  const loadingContext = useContext(LoadingContext);
  const notification = useContext(NotificationContext);
  const dialog = useContext(DialogContext);
  const user = useContext(ExcelUserContext);
  const setErrorState = useExcelErrorHandler();
  const { loadExcelFileUrl, getCustomProperties, setCustomProperty } = useExcel();
  const { getAegContext } = useAegModeling();

  const [getTour, { data }] = useLazyQuery(GET_TOUR_AND_OFFER_ONLY, {
    onError(error) {
      // eslint-disable-next-line no-console
      console.error(error);
      const info = getNetworkErrorInfo(error);
      let message;
      if (Array.isArray(info?.message)) {
        message = info?.message.join(', ');
      } else {
        message = info?.message ?? '';
      }
      setErrorState({
        type: ExcelErrorType.CannotLoad,
        message,
      });
    },
    onCompleted() {
      OfficeExtension.config.extendedErrorLogging = true;
      Office.context.document.settings.set(
        'Office.AutoShowTaskpaneWithDocument',
        true,
      );
      Office.context.document.settings.saveAsync();
    },
  }); // TODO: remove for automation

  useEffect(() => {
    if (!envStatus.isAutomationEnabled) {
      const script = document.createElement('script');
      script.setAttribute('data-testid', 'officejs-script');
      script.src = 'https://appsforoffice.microsoft.com/lib/1/hosted/office.js';
      script.onload = () => {
        if (envStatus.isAutomationEnabled) {
          updateEnvStatusProperty({ isExcelReady: true });
        } else {
          void Office.onReady(() => {
            updateEnvStatusProperty({ isExcelReady: true });
          });
        }
      };
      document.body.appendChild(script);
    } else {
      updateEnvStatusProperty({ isExcelReady: true });
    }
  }, []);

  // eslint-disable-next-line max-len
  async function setIdsForWorkbook(launchMode: string, eventOrOfferId?: string, nosIdFromUrl?: string): Promise<{ offerId: string | undefined, eventId: string | undefined, nosId: string | undefined }> {
    const requiresOfferId = launchMode === ExcelWorkbookLaunchMode.MODELING
    || launchMode === ExcelWorkbookLaunchMode.TOUR_SETTLEMENT_FAR
    || launchMode === ExcelWorkbookLaunchMode.TOUR_SETTLEMENT_ARTIST;
    const requiresEventId = launchMode === ExcelWorkbookLaunchMode.NOS_SETTLEMENT;
    const customProperties = await getCustomProperties();
    let offerId;
    let eventId;
    let nosId;

    if (requiresOfferId) {
      const workbookOfferId = customProperties[WORKBOOK_CUSTOM_PROPERTIES.OFFER_ID];
      const offerIdFromUrl = eventOrOfferId;
      const didNotSetOffer = !workbookOfferId && offerIdFromUrl;
      const isWrongOfferSet = workbookOfferId && offerIdFromUrl && workbookOfferId !== offerIdFromUrl;

      if (!workbookOfferId && !offerIdFromUrl) {
        throw new Error('Offer Id not found');
      } else if (didNotSetOffer || isWrongOfferSet) {
        // if no offer id is stored or offer id is wrong because it is a cloned workbook
        // use offerId from file url then save the offer id in the workbook
        offerId = offerIdFromUrl;
        await setCustomProperty(WORKBOOK_CUSTOM_PROPERTIES.OFFER_ID, offerIdFromUrl);
      } else if (typeof workbookOfferId === 'string' && workbookOfferId) {
        offerId = workbookOfferId;
      }
    } else if (requiresEventId) {
      const workbookEventId = customProperties[WORKBOOK_CUSTOM_PROPERTIES.EVENT_ID];
      const eventIdFromUrl = eventOrOfferId;
      if (!workbookEventId && !eventIdFromUrl) {
        throw new Error('Event Id not found');
      } else if (workbookEventId && typeof workbookEventId === 'string') {
        eventId = workbookEventId;
      } else if (eventIdFromUrl) {
        eventId = eventIdFromUrl;
        await setCustomProperty(WORKBOOK_CUSTOM_PROPERTIES.EVENT_ID, eventIdFromUrl);
      }
    }

    // To support versioning for NOS, we can pull off the NOS Settlement ID from the file path
    //   For workbooks created before NOS versioning, the NOS id will be the Event ID
    if (launchMode === ExcelWorkbookLaunchMode.NOS_SETTLEMENT) {
      const workbookNosId = customProperties[WORKBOOK_CUSTOM_PROPERTIES.NOS_ID];

      if (workbookNosId && typeof workbookNosId === 'string') {
        nosId = workbookNosId;
      } else if (nosIdFromUrl) {
        nosId = nosIdFromUrl;
        await setCustomProperty(WORKBOOK_CUSTOM_PROPERTIES.NOS_ID, nosIdFromUrl);
      } else if (eventId) {
        nosId = eventId;
        await setCustomProperty(WORKBOOK_CUSTOM_PROPERTIES.NOS_ID, eventId);
      }
    }

    return { offerId, eventId, nosId };
  }

  const prepareData = useCallback(async () => {
    const aegContext = await getAegContext();

    if (!aegContext) {
      setErrorState({
        type: ExcelErrorType.AegContext,
        message: 'AEGContext not set on Excel workbook.',
      });
      return;
    }

    const launchMode = aegContext.toLowerCase() as ExcelWorkbookLaunchMode;

    updateEnvStatusProperty({ launchMode });

    const fileUrl = await loadExcelFileUrl();

    if (!fileUrl.includes('aegworldwide.sharepoint.com')) {
      setErrorState({
        type: ExcelErrorType.NotSynced,
        message: 'Excel workbook is not synced with Modern Elvis.',
      });
      return;
    }

    const templateWorkBookNames = [
      'modeling_template',
      'nos_settlement_template',
      'tour_settlement_artist_template',
      'tour_settlement_far_template',
    ];

    const guids = fileUrl.match(uuidRegex);
    const nosIds = fileUrl.match(nosIdWithVersionRegex);

    if (guids?.length) {
      const tourId = guids[0]; // First GUID in file url is the tourID

      const eventOrOfferIdFromUrl = guids[1]; // Second GUID in the url is the offer or event id based on launch type
      let nosId;
      if (nosIds?.length) {
        [nosId] = nosIds;
      }
      const optionalWorkbookIds = await setIdsForWorkbook(launchMode, eventOrOfferIdFromUrl, nosId);

      updateEnvStatusProperty({ ...optionalWorkbookIds, tourId });

      // First GQL sets up the Excel User Object
      await getTour({
        errorPolicy: 'all',
        variables: {
          tourId,
        },
      });
    } else if (templateWorkBookNames.some((templateName) => fileUrl.includes(templateName))) {
      Office.context.document.settings.set(
        'Office.AutoShowTaskpaneWithDocument',
        true,
      );
      Office.context.document.settings.saveAsync();

      setErrorState({
        type: ExcelErrorType.FileUpdate,
        message: 'Excel workbook needs to be updated with auto open property.',
      });
    } else {
      setErrorState({
        type: ExcelErrorType.FileUpdate,
        message: 'Could not parse workbook URL.',
      });
    }
  }, []);

  // TODO: Update prepare here for automation
  const prepareAutomationData = useCallback(async () => {
    if (!envStatus.automationTourId && !(envStatus.automationOfferId || envStatus.automationEventId)) {
      // eslint-disable-next-line max-len
      logError(appInsights, 'In automation mode, automationTourId & (automationOfferId || automationEventId) parameters should be defined.');
    }
    const envStatusPayload: Partial<EnvironmentStatus> = {
      tourId: envStatus.automationTourId as string,
    };
    if (envStatus.automationSettlementType === 'FAR' && envStatus.automationOfferId) {
      envStatusPayload.launchMode = ExcelWorkbookLaunchMode.TOUR_SETTLEMENT_FAR;
      envStatusPayload.offerId = envStatus.automationOfferId;
    } else if (envStatus.automationSettlementType === 'ARTIST' && envStatus.automationOfferId) {
      envStatusPayload.launchMode = ExcelWorkbookLaunchMode.TOUR_SETTLEMENT_ARTIST;
      envStatusPayload.offerId = envStatus.automationOfferId;
    } else if (envStatus.automationOfferId) {
      envStatusPayload.launchMode = ExcelWorkbookLaunchMode.MODELING;
      envStatusPayload.offerId = envStatus.automationOfferId;
    } else if (envStatus.automationEventId) {
      envStatusPayload.launchMode = ExcelWorkbookLaunchMode.NOS_SETTLEMENT;
      envStatusPayload.eventId = envStatus.automationEventId;
    }

    updateEnvStatusProperty(envStatusPayload);

    await getTour({
      errorPolicy: 'all',
      variables: {
        tourId: envStatus.automationTourId as string,
      },
    });
  }, []);

  useEffect(() => {
    if (envStatus.isExcelReady) {
      if (envStatus.isAutomationEnabled) {
        void prepareAutomationData();
      } else {
        void prepareData();
      }
    }
  }, [envStatus.isExcelReady]);

  return envStatus.isExcelReady && envStatus.tourId && envStatus.launchMode ? (
    <>
      { envStatus.isExcelReady && user && data?.tour ? <Outlet /> : <>Loading...</> }
      <StyledBackdrop
          open={loadingContext.loading}
          data-testid='task-pane-loading'
        >
        <CircularProgress color='inherit' />
      </StyledBackdrop>
      <AegSnackbar
        type={notification?.type}
        message={notification?.text || ''}
        duration={notification?.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      />
      <AegDialog
        titles={dialog?.titles}
        description={dialog?.description}
        submit={dialog?.submit}
        cancel={dialog?.cancel}
        severity={dialog?.severity}
      />
    </>
  ) : (
    <>Initializing...</>
  );
};
