/* eslint-disable quote-props */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */

/**
 * Sheet Names
 */
export const VARIABLE_COST_RATES_REF_SHEET_NAME = 'Variable Cost Rates';
export const TEMPLATE_MARKET_SHEET_NAME = 'market_sheet_template';
export const SHOW_DETAIL_SHEET_NAME = 'Show Detail';
export const SUMMARY_SHEET_NAME = 'Summary';
export const SUMMARY_UNCROSSED_SHEET_NAME = 'Summary - Uncrossed';
export const OVERHEAD_COSTS_SHEET_NAME = 'Overhead Costs';
export const QUICK_SUM_SHEET_NAME = 'Quick Sum';
export const LATERAL_SUM_SHEET_NAME = 'Lateral Sum (Potential)';
export const APPROVAL_EMAIL_SHEET_NAME = 'Approval Email';
export const APPROVAL_REVISED_SHEET_NAME = 'Approval Revised';
export const MANAGED_SHEETS_SHEET_NAME = 'managed_sheets';
export const RELEASE_NOTES_SHEET_NAME = 'Release Notes';
export const PRS_RATES = 'prs_rates';
export const PROFIT_LOSS_REFERENCE_SHEET_NAME = 'profit_loss_reference_sheet';
export const SHEETS_WITH_AEG_LOGO = [
  TEMPLATE_MARKET_SHEET_NAME,
  SUMMARY_SHEET_NAME,
  SUMMARY_UNCROSSED_SHEET_NAME,
  SHOW_DETAIL_SHEET_NAME,
  QUICK_SUM_SHEET_NAME,
  LATERAL_SUM_SHEET_NAME,
];
/**
 * Managed Sheets
 */
export const MANAGED_MODELING_SHEETS = {
  release_notes: RELEASE_NOTES_SHEET_NAME,
  summary: SUMMARY_SHEET_NAME,
  show_detail: SHOW_DETAIL_SHEET_NAME,
  quick_sum: QUICK_SUM_SHEET_NAME,
  lateral_sum_potential: LATERAL_SUM_SHEET_NAME,
  overhead_costs: OVERHEAD_COSTS_SHEET_NAME,
  approved_email: APPROVAL_EMAIL_SHEET_NAME,
  approval_revised: APPROVAL_REVISED_SHEET_NAME,
  variable_cost_rates: VARIABLE_COST_RATES_REF_SHEET_NAME,
  prs_rates: PRS_RATES,
  market_sheet_template: TEMPLATE_MARKET_SHEET_NAME,
  profit_loss_reference_sheet: PROFIT_LOSS_REFERENCE_SHEET_NAME,
  managed_sheets: MANAGED_SHEETS_SHEET_NAME,
};

// Initialize Workbook Key
export const MODELING_INITIALIZED_KEY = 'modeling_workbook_initialized';

// Refresh Workbook Key
export const WORKBOOK_REFRESH_INITIALIZED_KEY = 'post_workbook_refresh_initialized';

export const INACTIVE_TAB_COLOR = 'D7D7D7';
export const DEFAULT_TAB_COLOR = '';

/**
 * Box Office Formulas
 */
export const BOX_OFFICE_SUMMARY = {
  AVAILABLE_CAPACITY: '=[@capacity]-[@kills]',
  SELLABLE: '=[@availableCapacity]-[@comps]',
  PRICE_TO_BASE: '=IFERROR([@price]/ExchangeRate, 0)',
  GROSS: '=[@sellableCapacity]*[@price]',
  GROSS_TO_BASE: '=IFERROR([@grossPotential]/ExchangeRate, 0)',
} as const;

export const BOX_OFFICES = {
  AVAILABLE_CAPACITY: '=[@capacity]-[@kills]',
  SELLABLE: '=[@availableCapacity]-[@comps]',
  GROSS: '=[@sellableCapacity]*[@price]',
} as const;

/**
 * Ancillary Earnings table Formulas
 */
export const ANCILLARY_EARNINGS_PROJECTED = (eventSafeMarketName: string): string => `=IF([@[Calc. Type]]="Flat Rate", [@[Per Ticket]], [@[Per Ticket]]*(${eventSafeMarketName}_Projections[[#Totals],[Sold (Avg.)]]*NumShows))`;

export const ANCILLARY_EARNINGS = {
  GROSS_POTENTIAL: '=IF([@[Calc. Type]]="Flat Rate", [@[Per Ticket]], TotalSellable*[@[Per Ticket]])',
  PER_TICKET_TO_BASE: '=[@[Per Ticket]]/ExchangeRate',
  PROJ_TO_BASE: '=[@Projected]/ExchangeRate',
  POT_TO_BASE: '=[@[Gross Potential]]/ExchangeRate',
  CO_PRO_ONE: '=CoProPotPct1',
  CO_PRO_TWO: '=CoProPotPct2',
} as const;

/**
 * Variable Cost Reference Sheets Tables
 */
export const VARIABLE_COST_RATES_REF_SHEET_TABLES = {
  RATES: 'Rates',
} as const;

/**
 * Managed Sheets Tables
 */
export const MANAGED_SHEETS_TABLES = {
  MANAGED_SHEETS: 'managed_sheets',
} as const;

/**
 * Tax Fee Formulas
 */
export const TAX_FEE_FORMULAS = {
  PRE_TAX: {
    TOTAL:
      '=IFERROR(IFERROR(SWITCH([@formula], "Divisor",  TotalGross/(1+[@amount])-TotalGross, "Multiplier",TotalGross*(-[@amount]), "Per Ticket", TotalSellable *(-[@amount]), "Per Ticket (On Top)", TotalSellable *(-[@amount])), -[@amount]), "")',
    TOTAL_TO_BASE: '=IFERROR([@total]/ExchangeRate, 0)',
  },
  TAX: {
    TOTAL:
    '=IFERROR(SWITCH([@formula], "Divisor",  SUM(TotalGross,PreTaxTotal)/(1+[@amount])-SUM(TotalGross,PreTaxTotal), "Multiplier",SUM(TotalGross,PreTaxTotal)*(-[@amount]), "Per Ticket", TotalSellable *(-[@amount]), "Per Ticket (On Top)", TotalSellable *(-[@amount])), "")',
    TOTAL_TO_BASE: '=IFERROR([@total]/ExchangeRate, 0)',
  },
  POST_TAX: {
    TOTAL: '=[@amount]',
    TOTAL_TO_BASE: '=IFERROR([@total]/ExchangeRate, 0)',
  },
} as const;

/**
 * Variable Costs table Formulas
 */
export const VARIABLE_COSTS_TBL_FORMULAS = {
  CALCULATED_COST: '=VariableCost_Calculation([@name],[@rate],[@minimum],[@maximum])',
  RATE: '=IFERROR(LET(variableRate, XLOOKUP([@name],Rates[Category],Rates[Rate (% NGR)],0), IF(OR([@name]="PRS (Sliding Scale)", [@name]="PRS"), "", IF(OR(AND(OR([@name]="ASCAP (% of Net Gross)", [@name]="BMI (% of Net Gross)", [@name]="GMR (% of Net Gross)", [@name]="SESAC (% of Net Gross)"), Country<>"United States"), AND([@name]="SOCAN (% of Net Gross)", Country<>"Canada")), 0, IF([@name]="Insurance ($ per ticket)", variableRate*ExchangeRate, variableRate)))), 0)',
  COST_TO_BASE: '=IFERROR([@calculatedCost]/ExchangeRate, 0)',
  BLANK_COLUMN_0:
    '=IF(AND(NOT(ISBLANK([@minimum])), NOT(ISBLANK([@maximum]))), IF([@minimum]>[@maximum], "WARNING: Min is greater than max", ""), "")',
  BLANK_COLUMN_1: '',
  BLANK_COLUMN_2: '',
} as const;

/**
 * Fixed Costs
 */
export const FIXED_COSTS_TBL_FORMULAS = {
  BUDGET_TO_BASE: '=IFERROR([@budget]/ExchangeRate, 0)',
} as const;

/**
 * Potential Earnings
 */
export const POTENTIAL_EARNINGS = {} as const;

/**
 * Overhead Cost Sheets Tables
 */
export const OVERHEAD_COSTS_SHEET_TABLES = {
  SUMMARY: 'summary',
  OFFICE_STAFF: 'officeStaff',
  ROAD_STAFF: 'roadStaff',
  TOUR_SPECIFIC_STAFF: 'tourSpecificStaff',
  PRE_TOUR_STAFF: 'preTourStaff',
} as const;

/**
 * Named Ranges
 */

export const NAMED_RANGES = {
  EVENT_INFO: {
    ARTIST_NAME: 'ArtistName',
    TOUR_NAME: 'TourName',
    MARKET_NAME: 'MarketName',
    COUNTRY: 'Country',
    VENUE_NAME: 'VenueName',
    NUM_SHOWS: 'NumShows',
    NUM_TRUCKS: 'NumTrucks',
    STAGE_HANDS_COST_PER_TRUCK: 'CostPerTruck',
    STATUS: 'Status',
    LOCAL_CURRENCY: 'LocalCurrency',
    BASE_CURRENCY: 'BaseCurrency',
    EXCHANGE_RATE: 'ExchangeRate',
    PREPARED_BY: 'PreparedBy',
    PREPARED_ON: 'PreparedOn',
    EVENT_SELLABLE_CAPACITY: 'EventSellableCapacity',
    COMP_BREAKDOWN: 'CompBreakdown',
    MERCHANDISE_DEAL: 'MerchandiseDeal',
    NOTES_AND_COMMENTS: 'NotesComments',
    DEAL_LANGUAGE: 'DealLanguage',
    SHOW_DATE: 'ShowDate',
    PROJECTED_PCT_SOLD: 'ProjectedPctSold',
    TOTAL_CAPACITY: 'TotalCapacity',
    TOTAL_COSTS_TO_BASE: 'TotalCostsToBase',
    TOTAL_AVAILABLE_CAPACITY: 'TotalAvailableCapacity',
    TOTAL_SELLABLE: 'TotalSellable',
    TOTAL_GROSS: 'TotalGross',
    TOTAL_GROSS_TO_BASE: 'TotalGrossToBase',
    TOTAL_VARIABLE_COSTS_TO_BASE: 'TotalVariableCostsToBase',
    POST_TAX_ADJUSTMENTS_TO_BASE: 'PostTaxAdjustmentsToBase',
    NET_GROSS_RECEIPTS: 'NetGrossReceipts',
    NET_GROSS_RECEIPTS_TO_BASE: 'NetGrossReceiptsToBase',
    BUDGETED_SHOW_EXPENSES: 'BudgetedShowExpenses',
    BUDGETED_SHOW_EXPENSES_TO_BASE: 'BudgetedShowExpensesToBase',
    SELL_OFF_ADJUSTMENT: 'SellOffAdjustment',
    SELL_OFF_ADJUSTMENT_TO_BASE: 'SellOffAdjustmentToBase',
    NET_SHOW_RECEIPTS: 'NetShowReceipts',
    NET_SHOW_RECEIPTS_TO_BASE: 'NetShowReceiptsToBase',
    GUARANTEE: 'Guarantee',
    GUARANTEE_TO_BASE: 'GuaranteeToBase',
    PCT_NET_POOL_EARNINGS: 'PctNetPoolEarnings',
    NET_POOL_EARNINGS: 'NetPoolEarnings',
    NET_POOL_EARNINGS_TO_BASE: 'NetPoolEarningsToBase',
    ARTIST_ADJ_NOTES: 'ArtistAdjNotes',
    ARTIST_ADJUSTMENT: 'ArtistAdjustment',
    ARTIST_ADJUSTMENT_TO_BASE: 'ArtistAdjustmentToBase',
    ARTIST_EARNINGS: 'ArtistEarnings',
    ARTIST_EARNINGS_TO_BASE: 'ArtistEarningsToBase',
    // Projected / Actual
    SEQUENTIAL_SALES: 'SequentialSales',
    SEQUENTIAL_GROSS_SHOW_RECEIPTS: 'SequentialGrossShowReceipts',
    SEQUENTIAL_NET_GROSS_RECEIPTS: 'SequentialNetGrossReceipts',
    SEQUENTIAL_TOTAL_SHOW_COSTS: 'SequentialTotalShowCosts',
    SEQUENTIAL_NET_TO_POOL: 'SequentialNetToPool',
    ARTIST_EARNINGS_SEQ_ROUNDED: 'ArtistEarningsSeqRounded',
    TOTAL_ANCILLARY_PROJECTED_TO_BASE: 'TotalAncillaryProjectedToBase',
    SEQUENTIAL_CO_PRO_SHARE: 'SequentialCoProShare',
    SEQUENTIAL_AEG_NET_SHOW_EARNINGS: 'SequentialAegNetShowEarnings',
    // Costings
    SHOW_INCOME_SEQ_VARIABLE_COSTS: 'ShowIncomeSeqVariableCosts',
    PROJECTED_TOTAL_SOLD_SEQ: 'ProjectedTotalSoldSeq',
    TOTAL_ANCILLARY_PROJECTED: 'TotalAncillaryProjected',
    TOTAL_ANCILLARY_POTENTIAL: 'TotalAncillaryPotential',
    CO_PRO_1_POTENTIAL_PERCENT: 'CoProPotPct1',
    CO_PRO_2_POTENTIAL_PERCENT: 'CoProPotPct2',
    SELL_OFF_CALC_POTENTIAL_GUARANTEE: 'SellOffPotGuarantee',
    SELL_OFF_CALC_POTENTIAL_VS_PERCENT: 'SellOffPotVsPct',
    SELL_OFF_CALC_POTENTIAL_INCOME_POOL: 'SellOffPotIncomePool',
    FIXED_COSTS_TOTALS: [
      { categoryId: 'Advertising', names: { totalBudgetName: 'TotalAdvertising', totalBudgetToBaseName: 'TotalAdvertisingToBase' } },
      { categoryId: 'Artist Prorates', names: { totalBudgetName: 'TotalArtistProrates', totalBudgetToBaseName: 'TotalArtistProratesToBase' } },
      { categoryId: 'Catering', names: { totalBudgetName: 'TotalCatering', totalBudgetToBaseName: 'TotalCateringToBase' } },
      { categoryId: 'Other Costs', names: { totalBudgetName: 'TotalOtherCosts', totalBudgetToBaseName: 'TotalOtherCostsToBase' } },
      { categoryId: 'Production', names: { totalBudgetName: 'TotalProduction', totalBudgetToBaseName: 'TotalProductionToBase' } },
      { categoryId: 'Production Prorates 1', names: { totalBudgetName: 'TotalProductionProrates', totalBudgetToBaseName: 'TotalProductionProratesToBase' } },
      { categoryId: 'Rent', names: { totalBudgetName: 'TotalRent', totalBudgetToBaseName: 'TotalRentToBase' } },
      { categoryId: 'Staffing', names: { totalBudgetName: 'TotalStaffing', totalBudgetToBaseName: 'TotalStaffingToBase' } },
      { categoryId: 'Stagehands', names: { totalBudgetName: 'TotalStagehands', totalBudgetToBaseName: 'TotalStagehandsToBase' } },
      { categoryId: 'Support', names: { totalBudgetName: 'TotalSupport', totalBudgetToBaseName: 'TotalSupportToBase' } },
      { categoryId: 'Band Transport', names: { totalBudgetName: 'TotalTransport', totalBudgetToBaseName: 'TotalTransportToBase' } },
      { categoryId: 'Venue', names: { totalBudgetName: 'TotalVenue', totalBudgetToBaseName: 'TotalVenueToBase' } },
    ],
    LOCAL_CURRENCY_VARIABLE_COSTS_RATES: 'LocalCurrencyMarketVariableCostsRates',
    BASE_CURRENCY_PARENT_DECIMAL: [ // parent range
      'BaseCurrencyMarketBoxOffice',
      'BaseCurrencyMarketAncillaryAmount',
    ],
    BASE_CURRENCY_PARENT_ROUNDED: [ // parent range
      'BaseCurrencyMarketFixedCosts',
      'BaseCurrencyMarketVariableCosts',
      'BaseCurrencyMarketAdjustments',
      'BaseCurrencyMarketPotentialEarnings',
      'BaseCurrencyMarketAncillaryTotals',
      'BaseCurrencyMarketBreakEvenCalculations',
    ],
    LOCAL_CURRENCY_PARENT_DECIMAL: [ // parent range
      'LocalCurrencyMarketBoxOffice',
      'LocalCurrencyMarketAdjustments',
      'LocalCurrencyMarketShowSummaryAvgTicketPrice',
      'LocalCurrencyMarketAncillaryAmount',
      'LocalCurrencyMarketPotentialEarnings',
    ],
    LOCAL_CURRENCY_PARENT_ROUNDED: [ // parent range
      'LocalCurrencyMarketVariableCosts',
      'LocalCurrencyMarketFixedCosts',
      'LocalCurrencyMarketPotentialShowSummary',
      'LocalCurrencyMarketAvgShowSummary',
      'LocalCurrencyMarketSeqShowSummary',
      'LocalCurrencyMarketAncillaryTotals',
      'LocalCurrencyBreakEvenCalculations',
      'LocalCurrencyMarketEventInfoPerTruckCost',
    ],
    BREAK_EVEN_CALCULATIONS: {
      BREAK_EVEN_AVG_OTHER: 'BreakEvenAvgOther',
      BREAK_EVEN_SEQ_OTHER: 'BreakEvenSeqOther',
    },
    SELL_OFF_CALCULATIONS: {
      SELL_OFF_ADJUSTMENTS_POOL: 'SellOffSeqAdjustmentsPool',
    },
  },
  ARTIST_OFFER: {
    GUARANTEE: 'Guarantee',
    NET_POOL_EARN: 'PctNetPoolEarnings',
    ADJ_NOTES: 'ArtistAdjNotes',
    ADJUSTMENT: 'ArtistAdjustment',
  },
  SUMMARY: {
    TOUR_NAME: 'WorkbookTourName',
    ARTIST_NAME: 'WorkbookArtistName',
    BASE_CURRENCY: 'WorkbookBaseCurrency',
    NUM_TRUCKS: 'WorkbookNumTrucks',
    BASE_CURRENCY_FIELDS: 'SummaryBaseCurrencyFields',
    BASE_CURRENCY_DECIMAL: 'BaseCurrencySummaryDecimal',
    BASE_CURRENCY_ROUNDED: 'BaseCurrencySummaryRounded',
    BASE_CURRENCY_AVG_TICKET_PRICE: 'BaseCurrencySummaryAvgTicketPrice',
    TOTAL_POOL_INCOME: {
      MAX_MANUAL_ADJUSTMENTS: 8,
      TICKETING_REVENUE_AVG: 'TotalPoolIncomeTicketingRevenue_Avg',
      SPONSORSHIP_REVENUE_AVG: 'TotalPoolIncomeSponsorshipRevenue_Avg',
      PREMIUM_TICKETING_MODIFIER: 'TotalPoolIncomePremiumTicketing_Modifier',
      CANCEL_INSURANCE_POTENTIAL: 'TotalPoolIncomeCancellationInsurance_Potential',
      TOUR_POOL_BONUS_POTENTIAL: 'TotalPoolIncomeTourPoolBonus_Potential',
      TOUR_POOL_BONUS_MODIFIER: 'TotalPoolIncomeTourPoolBonus_Modifier',
      FINANCING_COSTS_AVG: 'TotalPoolIncomeFinancingCosts_Avg',
      // PREMIUM: {
      //   // GROSS: 'TotalPoolIncomeTicketingRevenue_Gross',
      //   AVG: 'TotalPoolIncomeTicketingRevenue_Avg',
      //   // SEQ: 'TotalPoolIncomeTicketingRevenue_Seq',
      //   // BLENDED: 'TotalPoolIncomeTicketingRevenue_Blended',
      // },
      // SPONSORSHIP: {
      //   // GROSS: 'TotalPoolIncomeSponsorshipRevenue_Gross',
      //   AVG: 'TotalPoolIncomeSponsorshipRevenue_Avg',
      //   // SEQ: 'TotalPoolIncomeSponsorshipRevenue_Seq',
      //   // BLENDED: 'TotalPoolIncomeSponsorshipRevenue_Blended',
      // },
      MANUAL_ADJUSTMENTS: {
        NAME: 'TotalPoolIncomeManualAdjustment_Text_',
        // GROSS: 'TotalPoolIncomeManualAdjustment_Gross_',
        AVG: 'TotalPoolIncomeManualAdjustment_Avg_',
        // SEQ: 'TotalPoolIncomeManualAdjustment_Seq_',
        // BLENDED: 'TotalPoolIncomeManualAdjustment_Blended_',
      },
    },
    TOTAL_ARTIST_EARNINGS: {
      MAX_MANUAL_ADJUSTMENTS: 5,
      SPLIT_POINT_PERCENT: 'TotalArtistEarningsSplitPointPercent',
      PERCENT_OVER_SPLIT_POINT: 'TotalArtistEarningsPercentOverSplitPoint',
      BONUS_PER_TICKET_POTENTIAL: 'TotalArtistEarningsArtistBonusPerTicket_Potential',
      BONUS_PER_TICKET_MODIFIER: 'TotalArtistEarningsArtistBonusPerTicket_Modifier',
      BONUS_FLAT_AVG: 'TotalArtistEarningsArtistBonusFlat_Avg',
      MARKETING_FUND_AVG: 'TotalArtistEarningsArtistMarketingFund_Avg',
      MANUAL_ADJUSTMENTS: {
        NAME: 'TotalArtistEarningsManualAdjustment_Text_',
        // GROSS: 'TotalArtistEarningsManualAdjustment_Gross_',
        AVG: 'TotalArtistEarningsManualAdjustment_Avg_',
        // SEQ: 'TotalArtistEarningsManualAdjustment_Seq_',
        // BLENDED: 'TotalArtistEarningsManualAdjustment_Blended_',
      },
    },
    NET_GROSS_PROMOTER_INCOME: {
      AEG_PARTNER_SHARE_AVG: 'NetPromoterIncomeAegPartnerShare_Avg',
      MAX_MANUAL_ADJUSTMENTS: 4,
      // ANCILLARY: {
      //   NAME: 'NetGrossPromoterIncomeAncillaryRevenue_Text',
      //   GROSS: 'NetGrossPromoterIncomeAncillaryRevenue_Gross',
      //   AVG: 'NetGrossPromoterIncomeAncillaryRevenue_Avg',
      //   SEQ: 'NetGrossPromoterIncomeAncillaryRevenue_Seq',
      //   BLENDED: 'NetGrossPromoterIncomeAncillaryRevenue_Blended',
      // },
      MANUAL_ADJUSTMENTS: {
        NAME: 'NetGrossPromoterIncomeManualAdjustment_Text_',
        // GROSS: 'NetGrossPromoterIncomeManualAdjustment_Gross_',
        AVG: 'NetGrossPromoterIncomeManualAdjustment_Avg_',
        // SEQ: 'NetGrossPromoterIncomeManualAdjustment_Seq_',
        // BLENDED: 'NetGrossPromoterIncomeManualAdjustment_Blended_',
      },
    },
    BREAK_EVEN: {
      ARTIST_BONUS_FLAT: {
        NAME: 'BreakEvenArtistBonusFlat_Text',
        AVG: 'BreakEvenArtistBonusFlat_Avg',
        SEQ: 'BreakEvenArtistBonusFlat_Seq',
        BLENDED: 'BreakEvenArtistBonusFlat_Blended_Avg',
      },
      MARKETING_FUND: {
        NAME: 'BreakEvenMarketingFund_Text',
        AVG: 'BreakEvenMarketingFund_Avg',
        SEQ: 'BreakEvenMarketingFund_Seq',
        BLENDED: 'BreakEvenMarketingFund_Blended_Avg',
      },
      MAX_MANUAL_ADJUSTMENTS: 13,
      MANUAL_ADJUSTMENTS: {
        NAME: 'BreakEvenManualAdjustment_Text_',
        AVG: 'BreakEvenManualAdjustment_Avg_',
        SEQ: 'BreakEvenManualAdjustment_Seq_',
        BLENDED: 'BreakEvenManualAdjustment_Blended_Avg_',
      },
    },
  },
  SUMMARY_UNCROSSED: {
    BASE_CURRENCY_DECIMAL: 'BaseCurrencyUncrossedSummaryDecimal',
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyUncrossedSummaryRounded',
    BASE_CURRENCY_AVG_TICKET_PRICE: 'BaseCurrencyUncrossedSummaryAvgTicketPrice',
  },
  QUICK_SUM: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyQuickSumRounded',
    BASE_CURRENCY_DECIMAL: 'BaseCurrencyQuickSumDecimal',
  },
  LATERAL_SUM_POTENTIAL: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyLateralSumRounded',
    BASE_CURRENCY_DECIMAL: 'BaseCurrencyLateralSumDecimal',
  },
  SHOW_DETAIL: {
    BASE_CURRENCY_ROUNDED: [ // parent range
      'BaseCurrencyShowDetailCoProEquation',
      'BaseCurrencyShowDetailFixedCosts',
      'BaseCurrencyShowDetailPotentialRevenue',
      'BaseCurrencyShowDetailProjectedResults',
      'BaseCurrencyShowDetailSellOff',
      'BaseCurrencyShowDetailShowByShow',
      'BaseCurrencyShowDetailTierScaling',
      'BaseCurrencyShowDetailVariableCosts',
      'BaseCurrencyBelowShowDetailCoProEquation',
      'BaseCurrencyBelowShowDetailFixedCosts',
      'BaseCurrencyBelowShowDetailPotentialRevenue',
      'BaseCurrencyBelowShowDetailProjectedResults',
      'BaseCurrencyBelowShowDetailSellOff',
      'BaseCurrencyBelowShowDetailShowByShow',
      'BaseCurrencyBelowShowDetailTierScaling',
      'BaseCurrencyBelowShowDetailVariableCosts',
    ],
    BASE_CURRENCY_DECIMAL: [ // parent range
      'BaseCurrencyShowDetailTierScalingPrice',
      'BaseCurrencyBelowShowDetailTierScalingPrice',
    ],
  },
  OVERHEAD_COSTS: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyOverheadCostsRounded',
    FINANCING_COSTS: {
      DEPOSIT_AMOUNT: 'FinancingCostsDepositAmount',
      AMOUNT_DUE_RATE_1: 'FinancingCostsAmountDueRate1',
      FINANCING_COSTS_MONTHS_1: 'FinancingCostsMonths1',
      FINANCING_COSTS_RATE_1: 'FinancingCostsRate1',
      AMOUNT_DUE_RATE_2: 'FinancingCostsAmountDueRate2',
      FINANCING_COSTS_MONTHS_2: 'FinancingCostsMonths2',
      FINANCING_COSTS_RATE_2: 'FinancingCostsRate2',
      AMOUNT_DUE_RATE_3: 'FinancingCostsAmountDueRate3',
      FINANCING_COSTS_MONTHS_3: 'FinancingCostsMonths3',
      FINANCING_COSTS_RATE_3: 'FinancingCostsRate3',
    },
  },
  APPROVAL_EMAIL: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyApprovalEmailRounded',
    BASE_CURRENCY_DECIMAL: 'BaseCurrencyApprovalEmailDecimal',
    BASE_CURRENCY_DECIMAL_TEST: 'BaseCurrencyApprovalEmailDecimalTest',
    CURRENT_MODEL: {
      TERRITORY: 'ApprovalEmailCurrentTerritory',
      VENUE_TYPE: 'ApprovalEmailCurrentVenueType',
      TIME_PERIOD: 'ApprovalEmailCurrentTimePeriod',
      FLAT_BONUS_NOTE: 'ApprovalEmailCurrentFlatBonusNote',
      ARTIST_ADVANCE: 'ApprovalEmailCurrentArtistAdvance',
      SUPPORT: 'ApprovalEmailCurrentSupport',
      AGENT: 'ApprovalEmailCurrentAgent',
      MANAGER: 'ApprovalEmailCurrentManager',
      AEG_POINT_PERSON: 'ApprovalEmailCurrentAegPointPerson',
      OPERATOR: 'ApprovalEmailCurrentOperator',
      MANUAL_ADJUSTMENT_NOTE_1: 'ApprovalEmailCurrentManualAdjustmentNote1',
      MANUAL_ADJUSTMENT_NOTE_2: 'ApprovalEmailCurrentManualAdjustmentNote2',
      MANUAL_ADJUSTMENT_NOTE_3: 'ApprovalEmailCurrentManualAdjustmentNote3',
      MANUAL_ADJUSTMENT_NOTE_4: 'ApprovalEmailCurrentManualAdjustmentNote4',
      MANUAL_ADJUSTMENT_NOTE_5: 'ApprovalEmailCurrentManualAdjustmentNote5',
    },
    ORIGINAL_MODEL: {
      TERRITORY: 'ApprovalEmailOriginalTerritory',
      NUMBER_OF_SHOWS: 'ApprovalEmailOriginalNumShows',
      TOTAL_COMMITMENT: 'ApprovalEmailOriginalTotalCommitment',
      GUARANTEE: 'ApprovalEmailOriginalGuarantee',
      PRODUCTION: 'ApprovalEmailOriginalProduction',
      PRODUCED_TOUR_PRORATES: 'ApprovalEmailOriginalProducedTourProrate',
      TOTAL_COMMITMENT_PER_SHOW: 'ApprovalEmailOriginalTotalCommitmentPerShow',
      GUARANTEE_PER_SHOW: 'ApprovalEmailOriginalGuaranteePerShow',
      PRODUCTION_PER_SHOW: 'ApprovalEmailOriginalProductionPerShow',
      SUPPORT_COMMITMENT: 'ApprovalEmailOriginalSupportCommitment',
      SUPPORT_PER_SHOW: 'ApprovalEmailOriginalSupportPerShow',
      PRODUCED_TOUR_PRORATES_PER_SHOW: 'ApprovalEmailOriginalProducedTourProratePerShow',
      VENUE_TYPE: 'ApprovalEmailOriginalVenueType',
      TIME_PERIOD: 'ApprovalEmailOriginalTimePeriod',
      MARKETING_FUND: 'ApprovalEmailOriginalMarketingFund',
      FLAT_BONUS: 'ApprovalEmailOriginalFlatBonus',
      FLAT_BONUS_NOTE: 'ApprovalEmailOriginalFlatBonusNote',
      MANUAL_ADJUSTMENT_1: 'ApprovalEmailOriginalManualAdjustment1',
      MANUAL_ADJUSTMENT_2: 'ApprovalEmailOriginalManualAdjustment2',
      MANUAL_ADJUSTMENT_3: 'ApprovalEmailOriginalManualAdjustment3',
      MANUAL_ADJUSTMENT_4: 'ApprovalEmailOriginalManualAdjustment4',
      MANUAL_ADJUSTMENT_5: 'ApprovalEmailOriginalManualAdjustment5',
      MANUAL_ADJUSTMENT_LABEL_1: 'ApprovalEmailOriginalManualAdjustmentLabel1',
      MANUAL_ADJUSTMENT_LABEL_2: 'ApprovalEmailOriginalManualAdjustmentLabel2',
      MANUAL_ADJUSTMENT_LABEL_3: 'ApprovalEmailOriginalManualAdjustmentLabel3',
      MANUAL_ADJUSTMENT_LABEL_4: 'ApprovalEmailOriginalManualAdjustmentLabel4',
      MANUAL_ADJUSTMENT_LABEL_5: 'ApprovalEmailOriginalManualAdjustmentLabel5',
      MANUAL_ADJUSTMENT_NOTE_1: 'ApprovalEmailOriginalManualAdjustmentNote1',
      MANUAL_ADJUSTMENT_NOTE_2: 'ApprovalEmailOriginalManualAdjustmentNote2',
      MANUAL_ADJUSTMENT_NOTE_3: 'ApprovalEmailOriginalManualAdjustmentNote3',
      MANUAL_ADJUSTMENT_NOTE_4: 'ApprovalEmailOriginalManualAdjustmentNote4',
      MANUAL_ADJUSTMENT_NOTE_5: 'ApprovalEmailOriginalManualAdjustmentNote5',
      ARTIST_ADVANCE: 'ApprovalEmailOriginalArtistAdvance',
      BREAKEVEN: 'ApprovalEmailOriginalBreakeven',
      BREAKEVEN_PERCENTAGE: 'ApprovalEmailOriginalBreakevenPercentage',
      AVERAGE_NET_TICKET_PRICE: 'ApprovalEmailOriginalAverageNetTicketPrice',
      NET_GLOBAL_TOURING_PROFIT: 'ApprovalEmailOriginalNetGlobalTouringProfit',
      NET_GLOBAL_TOURING_PROFIT_PERCENTAGE: 'ApprovalEmailOriginalNetGlobalTouringProfitPercentage',
      ANCILLARIES: 'ApprovalEmailOriginalAncillaries',
      NUMBER_OF_TRUCKS_CAP: 'ApprovalEmailOriginalNumTrucksCap',
      SUPPORT: 'ApprovalEmailOriginalSupport',
      TOUR_POOL_BONUS: 'ApprovalEmailOriginalTourPoolBonus',
      PER_TICKET_BONUS: 'ApprovalEmailOriginalPerTicketBonus',
      PREMIUM_TICKETING: 'ApprovalEmailOriginalPremiumTicketing',
      PREMIUM_TICKETING_PER_SHOW: 'ApprovalEmailOriginalPremiumTicketingPerShow',
      SPONSORSHIP: 'ApprovalEmailOriginalSponsorship',
      AGENT: 'ApprovalEmailOriginalAgent',
      MANAGER: 'ApprovalEmailOriginalManager',
      AEG_POINT_PERSON: 'ApprovalEmailOriginalAegPointPerson',
      OPERATOR: 'ApprovalEmailOriginalOperator',
    },
  },
  APPROVAL_REVISED: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyApprovalRevisedRounded',
    BASE_CURRENCY_DECIMAL: 'BaseCurrencyApprovalRevisedDecimal',
  },
  VALIDATION_FIELDS: {
    SUMMARY: {
      NUM_OF_SHOWS: 'TotalNumShows',
      GROSS_TICKET_SALES: 'TotalGrossTicketSales',
      LESS_SHOW_COSTS: 'TotalShowCosts',
      NET_POOL_INCOME_ARTIST_SPLIT: 'NetPoolIncomeBeforeArtistSplit',
      TOTAL_ARTIST_EARNINGS: 'TotalArtistEarnings',
      NET_PROMOTER_INCOME: 'NetPromoterIncome',
    },
  },
  ARTIST_DEAL_SUMMARY: {
    EXPENSE_BUYOUT_NOTE: 'ArtistDealSummaryExpenseBuyoutNote',
    PRODUCTION_CONTRIBUTION_NOTE: 'ArtistDealSummaryProductionContributionNote',
    OTHER_LABEL: 'ArtistDealSummaryOtherLabel',
    OTHER_VALUE: 'ArtistDealSummaryOtherValue',
    OTHER_NOTE: 'ArtistDealSummaryOtherNote',
    VS_NOTE: 'ArtistDealSummaryVsNote',
    WHT_TOGGLE: 'ArtistDealSummaryWHTToggle',
    WHT_VALUE: 'ArtistDealSummaryWHTValue',
    WHT_NOTE: 'ArtistDealSummaryWHTNote',
  },
} as const;

export const ARTIST_DEAL_SUMMARY_WHT_TOGGLE_VALUE = 'WHT %' as const;

export const MARKET_SHEET_TABLE_IDENTIFIERS = {
  BOX_OFFICE: 'BoxOffice',
  PRE_TAX_FEES: 'PreTaxFees',
  TAXES: 'Taxes',
  POST_TAX_FEES: 'PostTaxFees',
  VARIABLE_COSTS: 'VariableCosts',
  ANCILLARY_EARNINGS: 'Ancillary',
};

export const SHEET_NAME_KEY = '{EVENT_SHEET_NAME}';

export const MINIMUM_BOX_OFFICE_ROWS = 15;

/**
 * Show Detail
 *    Keep this one last...
 */

export const SHOW_DETAIL_TABLE_NAME = 'ShowDetail';
export const BELOW_SHOW_DETAIL_TABLE_NAME = 'BelowShowDetail';
export const SAFE_SHEET_NAME_KEY = '{EVENT_SAFE_SHEET_NAME}';
export const SHOW_DETAIL_COLUMN_LENGTH = 244;

export const createShowDetailRowFormulas = (eventSheetName: string, eventSafeMarketName: string) => ({
  'EVENT_SAFE_SHEET_NAME': `${eventSheetName}`, // DEPRECATED: Used by old workbooks
  'View Sheet': '', // View Sheet column, removed in SANE-6254
  'Status': `='${eventSheetName}'!Status`,
  'Date': `='${eventSheetName}'!ShowDate`,
  'City': `='${eventSheetName}'!MarketName`,
  'Venue Name': `='${eventSheetName}'!VenueName`,
  'Per Show Capacity': `=${eventSafeMarketName}_BoxOffice_0[[#Totals],[sellableCapacity]]`,
  'Num Shows': `='${eventSheetName}'!NumShows`,
  'Total Capacity': '=+[@[Num Shows]]*[@[Per Show Capacity]]',
  'Cap. Vs. Scaling Check': '=IFERROR([@[Total Capacity]]-[@[Seats 1]]-[@[Seats 2]]-[@[Seats 3]]-[@[Seats 4]]-[@[Seats 5]]-[@[Seats 6]]-[@[Seats 7]]-[@[Seats 8]]-[@[Seats 9]]-[@[Seats 10]]-[@[Seats 11]]-[@[Seats 12]]-[@[Seats 13]]-[@[Seats 14]]-[@[Seats 15]]-[@[Seats 16+]], 0)',
  'Local Currency': `='${eventSheetName}'!LocalCurrency`,
  'Exchange Rate': `='${eventSheetName}'!ExchangeRate`,
  'Scale 1': '=IFERROR([@[Seats 1]]/[@[Total Capacity]], 0)',
  'Price 1': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],1)/[@[Exchange Rate]], 0)`,
  'Seats 1': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],1), 0)*[@[Num Shows]]`,
  'Sub-Total 1': '=IFERROR(+[@[Seats 1]]*[@[Price 1]], 0)',
  'Scale 2': '=IFERROR([@[Seats 2]]/[@[Total Capacity]], 0)',
  'Price 2': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],2)/[@[Exchange Rate]], 0)`,
  'Seats 2': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],2), 0)*[@[Num Shows]]`,
  'Sub-Total 2': '=IFERROR(+[@[Seats 2]]*[@[Price 2]], 0)',
  'Scale 3': '=IFERROR([@[Seats 3]]/[@[Total Capacity]], 0)',
  'Price 3': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],3)/[@[Exchange Rate]], 0)`,
  'Seats 3': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],3), 0)*[@[Num Shows]]`,
  'Sub-Total 3': '=IFERROR(+[@[Seats 3]]*[@[Price 3]], 0)',
  'Scale 4': '=IFERROR([@[Seats 4]]/[@[Total Capacity]], 0)',
  'Price 4': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],4)/[@[Exchange Rate]], 0)`,
  'Seats 4': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],4), 0)*[@[Num Shows]]`,
  'Sub-Total 4': '=IFERROR(+[@[Seats 4]]*[@[Price 4]], 0)',
  'Scale 5': '=IFERROR([@[Seats 5]]/[@[Total Capacity]], 0)',
  'Price 5': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],5)/[@[Exchange Rate]], 0)`,
  'Seats 5': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],5), 0)*[@[Num Shows]]`,
  'Sub-Total 5': '=IFERROR(+[@[Seats 5]]*[@[Price 5]], 0)',
  'Scale 6': '=IFERROR([@[Seats 6]]/[@[Total Capacity]], 0)',
  'Price 6': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],6)/[@[Exchange Rate]], 0)`,
  'Seats 6': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],6), 0)*[@[Num Shows]]`,
  'Sub-Total 6': '=IFERROR(+[@[Seats 6]]*[@[Price 6]], 0)',
  'Scale 7': '=IFERROR([@[Seats 7]]/[@[Total Capacity]], 0)',
  'Price 7': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],7), 0)/[@[Exchange Rate]]`,
  'Seats 7': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],7), 0)*[@[Num Shows]]`,
  'Sub-Total 7': '=IFERROR(+[@[Seats 7]]*[@[Price 7]], 0)',
  'Scale 8': '=IFERROR([@[Seats 8]]/[@[Total Capacity]], 0)',
  'Price 8': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],8), 0)/[@[Exchange Rate]]`,
  'Seats 8': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],8), 0)*[@[Num Shows]]`,
  'Sub-Total 8': '=IFERROR(+[@[Seats 8]]*[@[Price 8]], 0)',
  'Scale 9': '=IFERROR([@[Seats 9]]/[@[Total Capacity]], 0)',
  'Price 9': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],9), 0)/[@[Exchange Rate]]`,
  'Seats 9': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],9), 0)*[@[Num Shows]]`,
  'Sub-Total 9': '=IFERROR(+[@[Seats 9]]*[@[Price 9]], 0)',
  'Scale 10': '=IFERROR([@[Seats 10]]/[@[Total Capacity]], 0)',
  'Price 10': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],10), 0)/[@[Exchange Rate]]`,
  'Seats 10': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],10), 0)*[@[Num Shows]]`,
  'Sub-Total 10': '=IFERROR(+[@[Seats 10]]*[@[Price 10]], 0)',
  'Scale 11': '=IFERROR([@[Seats 11]]/[@[Total Capacity]], 0)',
  'Price 11': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],11), 0)/[@[Exchange Rate]]`,
  'Seats 11': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],11), 0)*[@[Num Shows]]`,
  'Sub-Total 11': '=IFERROR(+[@[Seats 11]]*[@[Price 11]], 0)',
  'Scale 12': '=IFERROR([@[Seats 12]]/[@[Total Capacity]], 0)',
  'Price 12': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],12), 0)/[@[Exchange Rate]]`,
  'Seats 12': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],12), 0)*[@[Num Shows]]`,
  'Sub-Total 12': '=IFERROR(+[@[Seats 12]]*[@[Price 12]], 0)',
  'Scale 13': '=IFERROR([@[Seats 13]]/[@[Total Capacity]], 0)',
  'Price 13': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],13), 0)/[@[Exchange Rate]]`,
  'Seats 13': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],13), 0)*[@[Num Shows]]`,
  'Sub-Total 13': '=IFERROR(+[@[Seats 13]]*[@[Price 13]], 0)',
  'Scale 14': '=IFERROR([@[Seats 14]]/[@[Total Capacity]], 0)',
  'Price 14': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],14), 0)/[@[Exchange Rate]]`,
  'Seats 14': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],14), 0)*[@[Num Shows]]`,
  'Sub-Total 14': '=IFERROR(+[@[Seats 14]]*[@[Price 14]], 0)',
  'Scale 15': '=IFERROR([@[Seats 15]]/[@[Total Capacity]], 0)',
  'Price 15': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[price],15), 0)/[@[Exchange Rate]]`,
  'Seats 15': `=IFERROR(INDEX(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],15), 0)*[@[Num Shows]]`,
  'Sub-Total 15': '=IFERROR(+[@[Seats 15]]*[@[Price 15]], 0)',
  'Scale 16+': '=IFERROR([@[Seats 16+]]/[@[Total Capacity]], 0)',
  'Price 16+': '',
  'Seats 16+': `=IFERROR(IF(ROWS(${eventSafeMarketName}_BoxOffice_0[sellableCapacity])>15, SUM(TAKE(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],-(ROWS(${eventSafeMarketName}_BoxOffice_0[sellableCapacity])-15))), 0), 0)*[@[Num Shows]]`,
  'Sub-Total 16+': `=IFERROR(IF(ROWS(${eventSafeMarketName}_BoxOffice_0[sellableCapacity])>15, SUM(MAP(TAKE(${eventSafeMarketName}_BoxOffice_0[sellableCapacity],-(ROWS(${eventSafeMarketName}_BoxOffice_0[sellableCapacity])-15)), TAKE(${eventSafeMarketName}_BoxOffice_0[price],-(ROWS(${eventSafeMarketName}_BoxOffice_0[price])-15)), LAMBDA(sellable,price, sellable*price)))/[@[Exchange Rate]], 0), 0)*[@[Num Shows]]`,
  'Check Total 1': '=IFERROR(SUM([@[Sub-Total 1]],[@[Sub-Total 2]],[@[Sub-Total 3]],[@[Sub-Total 4]],[@[Sub-Total 5]],[@[Sub-Total 6]],[@[Sub-Total 7]],[@[Sub-Total 8]],[@[Sub-Total 9]], [@[Sub-Total 10]],[@[Sub-Total 11]],[@[Sub-Total 12]],[@[Sub-Total 13]],[@[Sub-Total 14]],[@[Sub-Total 15]],[@[Sub-Total 16+]])-[@[Potential Gross]], 0)',
  'Potential Gross': `=IFERROR('${eventSheetName}'!TotalGross/[@[Exchange Rate]], 0)`,
  'Tax Rate': `=IFERROR(SUM(SUMIF(${eventSafeMarketName}_Taxes_0[formula],"Divisor",${eventSafeMarketName}_Taxes_0[amount]), SUMIF(${eventSafeMarketName}_Taxes_0[formula],"Multiplier",${eventSafeMarketName}_Taxes_0[amount])), 0)`,
  'Per Head Charges': `=IFERROR(SUM(SUMIF(${eventSafeMarketName}_PreTaxFees_0[category],"FMF",${eventSafeMarketName}_PreTaxFees_0[amount]), SUMIF(${eventSafeMarketName}_PreTaxFees_0[category],"Charity",${eventSafeMarketName}_PreTaxFees_0[amount]), SUMIF(${eventSafeMarketName}_PreTaxFees_0[category],"Parking",${eventSafeMarketName}_PreTaxFees_0[amount]), SUMIF(${eventSafeMarketName}_PreTaxFees_0[category],"Pre Tax Other 1",${eventSafeMarketName}_PreTaxFees_0[amount]), SUMIF(${eventSafeMarketName}_PreTaxFees_0[category],"Pre Tax Other 2",${eventSafeMarketName}_PreTaxFees_0[amount]), SUMIF(${eventSafeMarketName}_PreTaxFees_0[category],"Pre Tax Other 3",${eventSafeMarketName}_PreTaxFees_0[amount])), 0)`,
  'Potential Net': `=IFERROR('${eventSheetName}'!NetGrossReceipts/[@[Exchange Rate]], 0)`,
  'Check Total 2': `=[@[Potential Net]]-('${eventSheetName}'!NetGrossReceipts/[@[Exchange Rate]])`,
  'Projected Avg. Pct.': `=IFERROR(IF('${eventSheetName}'!ProjectedPctSold <= 0 , '${eventSheetName}'!ProjectedTotalSoldAvg/'${eventSheetName}'!TotalSellable, '${eventSheetName}'!ProjectedPctSold), 0)`,
  'Projected Avg. Tickets Sold': '',
  'Projected Avg. Fcast Pct. Sold': '=IFERROR([@[Projected Avg. Fcast Attend]]/[@[Total Capacity]], 0)',
  'Projected Avg. Fcast Attend': '=IF([@[Projected Avg. Pct.]]>0,[@[Projected Avg. Pct.]]*[@[Total Capacity]],[@[Projected Avg. Tickets Sold]])',
  'Projected Avg. Fcast Gross': `=(${eventSafeMarketName}_Projections[[#Totals],[Gross (Avg.)]]*'${eventSheetName}'!NumShows)/[@[Exchange Rate]]`,
  'Projected Avg. Fcast Net': `='${eventSheetName}'!ShowIncomeAvgNetGrossReceipts/[@[Exchange Rate]]`,
  'Projected Seq. Fcast Gross': `='${eventSheetName}'!ShowIncomeSeqGrossReceipts/[@[Exchange Rate]]`,
  'Projected Seq. Fcast Net': `='${eventSheetName}'!ShowIncomeSeqNetGrossReceipts/[@[Exchange Rate]]`,
  'Artist S&L': `=${eventSafeMarketName}_FixedCostCategory_Prorate[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  'Production Prorates': `=${eventSafeMarketName}_FixedCostCategory_ProdProrate[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  'Support': `=${eventSafeMarketName}_FixedCostCategory_Support[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  'Rent': `=${eventSafeMarketName}_FixedCostCategory_Rent[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  'Stagehands': `=${eventSafeMarketName}_FixedCostCategory_Stagehands[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  'Staffing': `=${eventSafeMarketName}_FixedCostCategory_Staffing[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  'Catering': `=${eventSafeMarketName}_FixedCostCategory_Catering[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  'Production': `=${eventSafeMarketName}_FixedCostCategory_Production[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  'Venue': `=${eventSafeMarketName}_FixedCostCategory_Venue[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  'Advertising': `=${eventSafeMarketName}_FixedCostCategory_Advertising[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  'Band Transport': `=${eventSafeMarketName}_FixedCostCategory_Transport[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  'Misc. Costs': `=${eventSafeMarketName}_FixedCostCategory_OtherCosts[[#Totals],[Budget]]/[@[Exchange Rate]]`,
  'Total Fixed Costs': '=SUM([@[Artist S&L]], [@[Production Prorates]],[@Support],[@Rent],[@Stagehands],[@Staffing],[@Catering],[@Production],[@Venue],[@Advertising],[@[Band Transport]],[@[Misc. Costs]])',
  'Check Total 3': `=[@[Total Fixed Costs]]-'${eventSheetName}'!FixedCostsSubtotal/[@[Exchange Rate]]`,
  'Variable Rent Projected Avg.': `=IFERROR(SUM(SUMIFS(${eventSafeMarketName}_ProjectedCosts[Projected Average],${eventSafeMarketName}_ProjectedCosts[Expenses],{"Rent (% of Gross)","Rent (% of Net Gross)"})), 0)`,
  'Variable Rent Projected Seq.': `=IFERROR(SUM(SUMIFS(${eventSafeMarketName}_ProjectedCosts[Projected Sequential],${eventSafeMarketName}_ProjectedCosts[Expenses],{"Rent (% of Gross)","Rent (% of Net Gross)"})), 0)`,
  'Variable Rent Sellout': `=IFERROR(SUM(SUMIFS(${eventSafeMarketName}_VariableCosts[calculatedCost],${eventSafeMarketName}_VariableCosts[name],{"Rent (% of Gross)","Rent (% of Net Gross)"})), 0)`,
  'Total Variable Projected Avg.': `=IFERROR(-'${eventSheetName}'!ShowIncomeAvgVariableCosts/[@[Exchange Rate]], 0)`,
  'Total Variable Projected Seq.': `=IFERROR(-'${eventSheetName}'!ShowIncomeSeqVariableCosts/[@[Exchange Rate]], 0)`,
  'Total Variable Sellout': `=IFERROR(${eventSafeMarketName}_VariableCosts[[#Totals],[calculatedCost]]/[@[Exchange Rate]], 0)`,
  'Total Expenses Avg.': '=IFERROR([@[Total Fixed Costs]]+[@[Total Variable Projected Avg.]], 0)',
  'Total Expenses Seq.': '=IFERROR([@[Total Fixed Costs]]+[@[Total Variable Projected Seq.]], 0)',
  'Total Expenses Sellout': '=IFERROR([@[Total Fixed Costs]]+[@[Total Variable Sellout]], 0)',
  'Check Total 4': `=SUM([@[Total Expenses Sellout]],('${eventSheetName}'!ShowIncomePotVariableCosts/[@[Exchange Rate]]))`,
  'Projected Net Receipts @ Avg.': '=IFERROR([@[Projected Avg. Fcast Net]]-[@[Total Expenses Avg.]]+[@[Sell-Off Adj. Avg.]], 0)',
  'Projected Net Receipts Avg. % Fcast': "=IFERROR([@[Projected Net Receipts '@ Avg.]]/[@[Projected Avg. Fcast Net]], 0)",
  'Check Total 5': `=[@[Projected Net Receipts '@ Avg.]]-'${eventSheetName}'!ShowIncomeAvgPoolToSplit`,
  'Projected Net Receipts @ Seq.': '=[@[Projected Seq. Fcast Net]]-[@[Total Expenses Seq.]]+[@[Sell-Off Adj. Seq.]]',
  'Projected Net Receipts Seq. % Fcast': "=IFERROR([@[Projected Net Receipts '@ Seq.]]/[@[Projected Seq. Fcast Net]], 0)",
  'Check Total 6': `=[@[Projected Net Receipts '@ Seq.]]-('${eventSheetName}'!ShowIncomeSeqPoolToSplit/[@[Exchange Rate]])`,
  'Projected Net Receipts @ Sellout': '=IFERROR(+[@[Potential Net]]-[@[Total Expenses Sellout]]+[@[Sell-Off Adj. Potential]], 0)',
  'Projected Net Receipts Sellout % Fcast': "=IFERROR(IF([@[Potential Net]]=0,0,[@[Projected Net Receipts '@ Sellout]]/[@[Potential Net]]), 0)",
  'Check Total 7': `=[@[Projected Net Receipts '@ Sellout]]-('${eventSheetName}'!ShowIncomePotPoolToSplit/[@[Exchange Rate]])`,
  'Guarantee': `=IFERROR('${eventSheetName}'!Guarantee/[@[Exchange Rate]], 0)`,
  'Gross Artist Earnings': `=IFERROR(IF(('${eventSheetName}'!PctNetPoolEarnings*[@[Projected Net Receipts '@ Sellout]])>[@Guarantee], '${eventSheetName}'!PctNetPoolEarnings*[@[Projected Net Receipts '@ Sellout]], [@Guarantee]), 0)`,
  'Gross Promoter Profit': "=IFERROR([@[Projected Net Receipts '@ Sellout]]-[@[Gross Artist Earnings]], 0)",
  'Avg. Artist Earnings': `=IFERROR(IF(('${eventSheetName}'!PctNetPoolEarnings*[@[Projected Net Receipts '@ Avg.]])>[@Guarantee],'${eventSheetName}'!PctNetPoolEarnings*[@[Projected Net Receipts '@ Avg.]],[@Guarantee]), 0)`,
  'Avg. Promoter Profit': "=IFERROR([@[Projected Net Receipts '@ Avg.]]-[@[Avg. Artist Earnings]], 0)",
  'Seq. Artist Earnings': `=IFERROR(IF(('${eventSheetName}'!PctNetPoolEarnings*[@[Projected Net Receipts '@ Seq.]])>[@Guarantee],'${eventSheetName}'!PctNetPoolEarnings*[@[Projected Net Receipts '@ Seq.]],[@Guarantee]), 0)`,
  'Seq. Promoter Profit': "=[@[Projected Net Receipts '@ Seq.]]-[@[Seq. Artist Earnings]]",
  'Rebate/Ancil Pool Income Fcast': `=IF([@[Exchange Rate]]=0,${eventSafeMarketName}_Ancillary[[#Totals],[Projected]], ${eventSafeMarketName}_Ancillary[[#Totals],[Projected]]/[@[Exchange Rate]])`,
  'Rebate/Ancil Pool Income Sellout': `=IF([@[Exchange Rate]]=0,${eventSafeMarketName}_Ancillary[[#Totals],[Gross Potential]],${eventSafeMarketName}_Ancillary[[#Totals],[Gross Potential]]/[@[Exchange Rate]])`,
  'Sell-Off NumShows': `=IF('${eventSheetName}'!SellOffPotGuarantee>0,'${eventSheetName}'!NumShows,0)`,
  'Sell-Off Guarantee': `='${eventSheetName}'!SellOffPotGuarantee/[@[Exchange Rate]]`,
  'Sell-Off Vs.': `='${eventSheetName}'!SellOffPotVsPct`,
  'Sell-Off Income to Pool': `='${eventSheetName}'!SellOffPotIncomePool`,
  'Sell-Off Earnings Potential': `='${eventSheetName}'!SellOffPotEarningsPool/[@[Exchange Rate]]`,
  'Sell-Off Earnings Avg.': `='${eventSheetName}'!SellOffAvgEarningsPool/[@[Exchange Rate]]`,
  'Sell-Off Sequential': `='${eventSheetName}'!SellOffSeqEarningsPool/[@[Exchange Rate]]`,
  'Sell-Off Adj. Potential': `='${eventSheetName}'!SellOffPotAdjustmentsPool/[@[Exchange Rate]]`,
  'Sell-Off Adj. Avg.': `='${eventSheetName}'!SellOffAvgAdjustmentsPool/[@[Exchange Rate]]`,
  'Sell-Off Adj. Seq.': `='${eventSheetName}'!SellOffSeqAdjustmentsPool/[@[Exchange Rate]]`,
  'Break-Even Potential': `='${eventSheetName}'!SellOffPotBreakEvenAdj/[@[Exchange Rate]]`,
  'Break-Even Avg.': `='${eventSheetName}'!SellOffAvgBreakEvenAdj/[@[Exchange Rate]]`,
  'Break-Even Seq.': `='${eventSheetName}'!SellOffSeqBreakEvenAdj/[@[Exchange Rate]]`,
  'Net Receipts Potential': `='${eventSheetName}'!SellOffPotNetShowReceipts/[@[Exchange Rate]]`,
  'Net Receipts Avg.': '',
  'Net Receipts Seqential': '',
  'Purchaser Pays Potential': '',
  'Purchaser Pays Avg.': `='${eventSheetName}'!SellOffAvgPurchaserPays/[@[Exchange Rate]]`,
  'Purchaser Pays Seq.': `='${eventSheetName}'!SellOffSeqPurchaserPays/[@[Exchange Rate]]`,
  'Purchaser Earnings Potential': `='${eventSheetName}'!SellOffPotPurchaserEarning/[@[Exchange Rate]]`,
  'Purchaser Earnings Avg.': `='${eventSheetName}'!SellOffAvgPurchaserEarning/[@[Exchange Rate]]`,
  'Purchaser Earnings Seq.': `='${eventSheetName}'!SellOffSeqPurchaserEarning/[@[Exchange Rate]]`,
  'CoPro NumShows': `=IF(OR('${eventSheetName}'!CoProPotPct1>0, '${eventSheetName}'!CoProPotPct2>0), '${eventSheetName}'!NumShows, 0)`,
  'CoPro1 Ancils': `=IF([@[CoPro NumShows]]>0, '${eventSheetName}'!CoProPotSoftRent1/[@[Exchange Rate]], 0)`,
  'CoPro2 Ancils': `=IF([@[CoPro NumShows]]>0, '${eventSheetName}'!CoProPotSoftRent2/[@[Exchange Rate]], 0)`,
  'Soft Rent Net Sellout': `=SUM('${eventSheetName}'!ShowIncomePotPoolToSplit, ${eventSafeMarketName}_Ancillary[[#Totals],[Gross Potential]])/[@[Exchange Rate]]`,
  'Soft Rent Net Avg.': `=SUM('${eventSheetName}'!ShowIncomeAvgPoolToSplit, ${eventSafeMarketName}_Ancillary[[#Totals],[Gross Potential]])/[@[Exchange Rate]]`,
  'Soft Rent Net Seq.': `=SUM('${eventSheetName}'!ShowIncomeSeqPoolToSplit, ${eventSafeMarketName}_Ancillary[[#Totals],[Gross Potential]])/[@[Exchange Rate]]`,
  'CoPro AEG Take Split %': `=IF([@[CoPro NumShows]]>0, 1-'${eventSheetName}'!CoProPotPct1-'${eventSheetName}'!CoProPotPct2, 0)`,
  'CoPro Guarantee': `=IF([@[CoPro NumShows]]>0, '${eventSheetName}'!CoProPotGuarantee1/[@[Exchange Rate]], 0)`,
  'CoPro Artist Backend': `=LET(a,'${eventSheetName}'!CoProPotArtistPct1,b,'${eventSheetName}'!CoProPotArtistPct2, IFERROR(SUM(a,b)/(AND(a<>0,a<>"")+AND(b<>0,b<>"")), 0))`,
  'CoPro Artst Earnings Sellout': `=IF([@[CoPro NumShows]]>0,'${eventSheetName}'!CoProPotArtistEarnings1/[@[Exchange Rate]], 0)`,
  'CoPro Artst Earnings Avg.': `=IF([@[CoPro NumShows]]>0,'${eventSheetName}'!CoProAvgArtistEarnings1/[@[Exchange Rate]], 0)`,
  'CoPro Artst Earnings Seq.': `=IF([@[CoPro NumShows]]>0,'${eventSheetName}'!CoProSeqArtistEarnings1/[@[Exchange Rate]], 0)`,
  'Partner Net to Split Sellout': `=IF([@[CoPro NumShows]]>0,'${eventSheetName}'!CoProPotNetToSplit1/[@[Exchange Rate]], 0)`,
  'Partner Net to Split Avg.': `=IF([@[CoPro NumShows]]>0,'${eventSheetName}'!CoProAvgNetToSplit1/[@[Exchange Rate]], 0)`,
  'Partner Net to Split Seq.': `=IF([@[CoPro NumShows]]>0,'${eventSheetName}'!CoProSeqNetToSplit1/[@[Exchange Rate]], 0)`,
  'Partner Share Sellout': `=SUM('${eventSheetName}'!CoProPotPartnerShare1, '${eventSheetName}'!CoProPotPartnerShare2)/[@[Exchange Rate]]`,
  'Partner Share Avg.': `=SUM('${eventSheetName}'!CoProAvgPartnerShare1, '${eventSheetName}'!CoProAvgPartnerShare2)/[@[Exchange Rate]]`,
  'Partner Share Seq.': `=SUM('${eventSheetName}'!CoProSeqPartnerShare1, '${eventSheetName}'!CoProSeqPartnerShare2)/[@[Exchange Rate]]`,
  'AEG Gain/Loss Sellout': `='${eventSheetName}'!CoProPotAEGShareGain/[@[Exchange Rate]]`,
  'AEG Gain/Loss Avg.': `='${eventSheetName}'!CoProAvgAEGShareGain/[@[Exchange Rate]]`,
  'AEG Gain/Loss Seq.': `='${eventSheetName}'!CoProSeqAEGShareGain/[@[Exchange Rate]]`,
  'Downside Protection Num Shows': '',
  "Downside Protection $'s": '',
  'Downside Protection Artist Guarantee': '',
  'Downside Protection Artist %': '',
  'Downside Protection Artist Earnings Sellout': '',
  'Downside Protection Artist Earnings Avg.': '',
  'Downside Protection Artist Earnings Seq.': '',
  'Downside Protection Earnings Sellout': '',
  'Downside Protection Earnings Avg.': '',
  'Downside Protection Earnings Seq.': '',
  'Downside Protection Pickup Sellout': '',
  'Downside Protection Pickup Avg.': '',
  'Downside Protection Pickup Seq.': '',
  'Total Sales': '=[@[Projected Avg. Fcast Attend]]',
  'P1 Sales': '=IF([@[Total Sales]]>[@[Seats 1]],[@[Seats 1]],[@[Total Sales]])',
  'P2 Sales': '=IF([@[P1 Sales]]<[@[Seats 1]],0,IF(([@[Seats 1]]+[@[Seats 2]])<[@[Total Sales]],[@[Seats 2]],[@[Total Sales]]-[@[P1 Sales]]))',
  'P3 Sales': '=IF([@[P2 Sales]]<[@[Seats 2]],0,IF(([@[P1 Sales]]+[@[P2 Sales]]+[@[Seats 3]])<[@[Total Sales]],[@[Seats 3]],([@[Total Sales]]-[@[P1 Sales]]-[@[P2 Sales]])))',
  'P4 Sales': '=IF([@[P3 Sales]]<[@[Seats 3]],0,(IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]])+[@[Seats 4]])<[@[Total Sales]],[@[Seats 4]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]])))))',
  'P5 Sales': '=IF([@[P4 Sales]]<[@[Seats 4]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]])+[@[Seats 5]])<[@[Total Sales]],[@[Seats 5]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]]))))',
  'P6 Sales': '=IF([@[P5 Sales]]<[@[Seats 5]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]])+[@[Seats 6]])<[@[Total Sales]],[@[Seats 6]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]]))))',
  'P7 Sales': '=IF([@[P6 Sales]]<[@[Seats 6]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]])+[@[Seats 7]])<[@[Total Sales]],[@[Seats 7]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]]))))',
  'P8 Sales': '=IF([@[P7 Sales]]<[@[Seats 7]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]])+[@[Seats 8]])<[@[Total Sales]],[@[Seats 8]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]]))))',
  'P9 Sales': '=IF([@[P8 Sales]]<[@[Seats 8]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]])+[@[Seats 9]])<[@[Total Sales]],[@[Seats 9]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]]))))',
  'P10 Sales': '=IF([@[P9 Sales]]<[@[Seats 9]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]])+[@[Seats 10]])<[@[Total Sales]],[@[Seats 10]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]]))))',
  'P11 Sales': '=IF([@[P10 Sales]]<[@[Seats 10]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]])+[@[Seats 11]])<[@[Total Sales]],[@[Seats 11]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]]))))',
  'P12 Sales': '=IF([@[P11 Sales]]<[@[Seats 11]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]])+[@[Seats 12]])<[@[Total Sales]],[@[Seats 12]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]]))))',
  'P13 Sales': '=IF([@[P12 Sales]]<[@[Seats 12]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]])+[@[Seats 13]])<[@[Total Sales]],[@[Seats 13]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]]))))',
  'P14 Sales': '=IF([@[P13 Sales]]<[@[Seats 13]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]], [@[Seats 13]])+[@[Seats 14]])<[@[Total Sales]],[@[Seats 14]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]], [@[Seats 13]]))))',
  'P15 Sales': '=IF([@[P14 Sales]]<[@[Seats 14]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]], [@[Seats 13]], [@[Seats 14]])+[@[Seats 15]])<[@[Total Sales]],[@[Seats 15]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]], [@[Seats 13]], [@[Seats 14]]))))',
  'P16+ Sales': '=IF([@[P15 Sales]]<[@[Seats 15]],0,IF((SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]], [@[Seats 13]], [@[Seats 14]], [@[Seats 15]])+[@[Seats 16+]])<[@[Total Sales]],[@[Seats 16+]],([@[Total Sales]]-SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]], [@[P9 Sales]], [@[Seats 10]], [@[Seats 11]], [@[Seats 12]], [@[Seats 13]], [@[Seats 14]], [@[Seats 15]]))))',
  'Reconcile': '=SUM([@[P1 Sales]],[@[P2 Sales]],[@[P3 Sales]],[@[P4 Sales]],[@[P5 Sales]],[@[P6 Sales]],[@[P7 Sales]],[@[P8 Sales]],[@[P9 Sales]],[@[P10 Sales]],[@[P11 Sales]],[@[P12 Sales]],[@[P13 Sales]],[@[P14 Sales]],[@[P15 Sales]],[@[P16+ Sales]])-[@[Total Sales]]',
  'Tour on Tour Proj. P.H.': '',
  'Tour on Tour Gross @ Sellout': '',
  'Tour on Tour Tax %': '',
  'Tour on Tour Net @ Sellout': '',
  'Tour on Tour Cost of Goods %': '',
  'Tour on Tour Cost of Goods Amt.': '',
  'Tour on Tour Freight & Road %': '',
  'Tour on Tour Freight & Road Amt.': '',
  'Tour on Tour Bootleg %': '',
  'Tour on Tour Bootleg Amt.': '',
  'Tour on Tour Hall & Vendors %': '',
  'Tour on Tour Hall & Vendors Amt.': '',
  'Tour on Tour Costs Sellout': '',
  'Tour on Tour Net to Pot Sellout': '',
  'Tour on Tour Fcast Sales %': '',
  'Tour on Tour Gross @ Fcast': '',
  'Tour on Tour Net @ Fcast': '',
  'Tour on Tour Costs Fcast': '',
  'Tour on Tour Net to Pot Fcast': '',
  'Retail on Tour Proj. P.H.': '',
  'Retail on Tour Gross @ Sellout': '',
  'Retail on Tour Tax %': '',
  'Retail on Tour Net @ Sellout': '',
  'Retail on Tour Cost of Goods %': '',
  'Retail on Tour Cost of Goods Amt.': '',
  'Retail on Tour Freight & Road %': '',
  'Retail on Tour Freight & Road Amt.': '',
  'Retail on Tour Bootleg %': '',
  'Retail on Tour Bootleg Amt.': '',
  'Retail on Tour Hall & Vendors %': '',
  'Retail on Tour Hall & Vendors Amt.': '',
  'Retail on Tour Costs Sellout': '',
  'Retail on Tour Net to Pot Sellout': '',
  'Retail on Tour Fcast Sales %': '',
  'Retail on Tour Gross @ Fcast': '',
  'Retail on Tour Net @ Fcast': '',
  'Retail on Tour Costs Fcast': '',
  'Retail on Tour Net to Pot Fcast': '',
  'Taxes and Fees': `=SUM(${eventSafeMarketName}_PreTaxFees_0[[#Totals],[convertedTotal]], ${eventSafeMarketName}_Taxes_0[[#Totals],[convertedTotal]])`, // Added in SANE-9130
  'Premium Ticketing': `=SUM(SUMIFS(${eventSafeMarketName}_PostTaxFees_0[total],${eventSafeMarketName}_PostTaxFees_0[category],{"VIP","Platinum"}))/[@[Exchange Rate]]`, // Added in SANE-9272
});
