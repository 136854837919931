export const CANCEL_EVENT_DIALOG = {
  TITLES: {
    dialogTitle: 'Are you sure you want to cancel this event?',
    alertTitle: 'This action can not be undone.',
  },
  SUBMIT: 'Cancel Event',
  CANCEL: 'Keep Event',
};

export const REMOVE_EVENT_DIALOG = {
  TITLES: {
    dialogTitle: 'Are you sure you want to remove this event from routing?',
    alertTitle: 'This action can not be undone.',
  },
  SUBMIT: 'Remove Event',
  CANCEL: 'Keep Event',
};

export const REMOVE_SHOW_DIALOG = {
  TITLES: {
    dialogTitle: 'Are you sure you want to remove this show?',
    alertTitle: 'Removing the show will remove the box office from the market sheet. This action can not be undone.',
  },
  SUBMIT: 'Remove Show',
  CANCEL: 'Keep Show',
};

export const SAVE_VENUE_CHANGES_DIALOG = {
  TITLES: {
    dialogTitle: 'Are you sure you want to save these changes?',
    alertTitle: 'Clicking save will update the Q&D venue data.',
  },
  SUBMIT: 'Save',
  CANCEL: 'Cancel',
};

export const CANCEL_VENUE_CHANGES_DIALOG = {
  TITLES: {
    dialogTitle: 'Are you sure you want to cancel your changes?',
    alertTitle: 'Canceling will discard all unsaved updates.',
  },
  SUBMIT: 'Yes, cancel',
  CANCEL: 'No, continue editing',
};

export const REMOVE_BUNDLE_EVENT_DIALOG = {
  TITLES: {
    dialogTitle: 'Are you sure you want to remove this event?',
  },
  // eslint-disable-next-line max-len
  DESCRIPTION: 'This will remove the event from this Offer within the Bundle. The event can be added again later if you change your mind.',
  SUBMIT: 'Confirm',
  CANCEL: 'Cancel',
};

export const KILL_EVENT_CONFIRMATION_DIALOG = {
  TITLES: {
    dialogTitle: 'Are you sure you want to kill this event',
    alertTitle: 'This will change the status of the events to Dead',
    alertList: ['This action can\'t be undone', 'The event can be removed from the routing once killed.'],
  },
  SUBMIT: 'Confirm',
  CANCEL: 'Cancel',
};
