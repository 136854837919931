import { createTheme } from '@mui/material/styles';

export const ElvisTheme = createTheme({
  spacing: 8,
  palette: {
    icon: {
      main: '#FF9800',
    },
    chip: {
      main: '#AEAEAE',
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.38)',
      enabled: 'rgba(0, 0, 0, 0.6)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      hoverOpacity: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
    },
    alert: {
      errorContent: '#541313',
      errorFill: '#FBEAEA',
      infoFill: '#E6F3FA',
      successFill: '#EAF2EA',
      warningFill: '#FDF0E6',
    },
    error: {
      main: '#D32F2F',
      contrast: '#FFFFFF',
      dark: '#C62828',
      light: 'rgba(211, 47, 47, 0.2)',
    },
    info: {
      main: '#0288D1',
      light: '#03A9F4',
      dark: '#01579B',
      states: {
        hover: 'rgba(2, 136, 209, 0.04)',
      },
    },
    input: {
      standard: {
        enabledBorder: 'rgba(0, 0, 0, 0.42)',
      },
      disabled: {
        color: 'rgba(0, 0, 0, 0.38)',
        background: '#F5F5F5',
        border: '#E0E0E0',
      },
    },
    primary: {
      main: '#052E54',
      contrast: '#FFFFFF',
      dark: '#03172A',
      states: {
        hover: '#f5f7f8',
        selected: '#ebeef1',
        outlineBorder: 'rgba(5, 46, 84, 0.5)',
      },
      light: {
        50: 'RGB(5, 46, 84, 0.12)',
        100: '#ECEFF5',
        150: '#F2F2F2',
        200: '#F9F9F9',
        250: '#A9BDD6',
        300: '#375876',
        400: '#063C6D',
      },
    },
    secondary: {
      main: '#D41034',
      contrast: '#FFFFFF',
      dark: 'rgba(5, 46, 84, 0.1)',
      light: 'rgba(5, 46, 84, 0.3)',
    },
    success: {
      main: '#2E7D32',
      light: '#4CAF50',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      base: '#000000',
    },
  },
  typography: {
    'caption-small': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      color: 'rgba(0,0,0,0.6)',
    },
    'body1-normal': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
    },
    'body1-medium': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
    },
    'body1-bold': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: () => ({
        body: {
          backgroundColor: '#F5F5F5',
        },
        a: {
          color: 'inherit',
          textDecoration: 'none',
        },
      }),
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '24px',
          '.MuiDataGrid-cell &': {
            marginTop: 1,
          },
        },
        filled: {
          backgroundColor: 'rgba(5, 46, 84, 0.5)',
          color: '#FFF',
        },
        outlined: {
          border: '1px solid #616161',
        },
        label: {
          padding: '0px 10px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#D32F2F',
          marginTop: 12,
          marginLeft: 0,
        },
        contained: {
          marginTop: 12,
          marginLeft: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#D32F2F',
          '&$error': {
            color: '#D32F2F',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& placeholder': {
            color: 'grey',
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& [role=alert]': {
            width: 'auto',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          // keeps poppers indexed above fixed table elements
          zIndex: 10,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          color: '#541313',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xsAddIn: 256,
      smAddIn: 370,
      mdAddIn: 640,
    },
  },
});
