/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable max-lines-per-function */
import {
  ReactNode,
  useCallback, useContext, useMemo, useState,
} from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import {
  Button, Divider, Grid, Switch,
} from '@mui/material';
import { GridColDef, GridPinnedRowsProp } from '@mui/x-data-grid-pro';
import { ArrowBackIos } from '@mui/icons-material';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import {
  CondensedAltRowColorGrid, FeatureGate, Loading, SnackbarType, StatusChip,
} from '@components';
import { getFragmentData } from '@gql/types';
import { EVENT_FRAGMENT, SEARCH_EVENTS } from '@gql/queries/events';
import { GET_PRIMARY_NOS_SETTLEMENT_DOWNLOAD_URL } from '@gql/queries/nosSettlements';
import { addColumn, AddColumnDirection, chevronRightColumn } from '@utils/datagridHelpers';
import { getTimezoneDate, sortDates } from '@utils/stringHelpers';
import { canSaveNosWorkbookByStatus } from '@utils/statusHelpers';
import { SearchEventFieldsFragment } from '@gql/types/graphql';
import { useAegModeling } from '@hooks';
import { NotificationDispatchContext } from '@providers';
import { logError } from '@services/telemetry-service';
import FeatureFlags from '@utils/featureFlags';
import {
  BackToButton,
  CloneOfferButtonContainer,
  VenueMetadata,
} from '../AddEventToOffer/AddEventToOffer.styled';
import {
  AddInAccordion,
  AccordionTableHeader,
  CloneOffersHeader,
  NonPrimaryToggleContainer,
  SearchEventsLoadingContainer,
  SwitchContainer,
  SwitchText,
} from './CloneTab.styled';
import {
  BOX_OFFICE_COLUMNS,
  EXPENSES_COLUMNS,
  generateBoxOfficeRowItems,
  generateExpensesRowItems,
  generateOfferMetadataRowItems,
  generatePinnedBoxOfficeRowItems,
} from './CloneTab.util';

// Un-Comment this block to re-enable row expansion
// import { expandColum, manageExpandedRows } from '@utils/datagridHelpers';
// import {
//   GRID_DETAIL_PANEL_TOGGLE_FIELD,
//   GridColDef,
//   GridEventListener,
//   GridRowId,
//   useGridApiRef,
// } from '@mui/x-data-grid-pro';
// import { TicketTierPanel } from './TicketTierPanel';

interface CloneTabGridData extends SearchEventFieldsFragment {
  numShows?: number | null;
  inPrimaryOffer?: 'Yes' | 'No';
  bold?: boolean;
}

export const CloneTab = ({
  venueId,
  spaceId,
  disableVirtualization = false,
}: {
  venueId: string;
  spaceId: string;
  disableVirtualization?: boolean;
}) => {
  const [singleEventSelected, setSingleOfferSelected] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<SearchEventFieldsFragment | null>(null);
  const [hideNonPrimary, setHideNonPrimary] = useState(true);
  const appInsights = useAppInsightsContext();
  const { cloneEvent } = useAegModeling();
  const setNotification = useContext(NotificationDispatchContext);

  const [getPrimaryNosDownloadUrl] = useLazyQuery(GET_PRIMARY_NOS_SETTLEMENT_DOWNLOAD_URL);

  // Un-Comment this block to re-enable row expansion
  // const [expandedRows, setExpandedRows] = useState<GridRowId[]>([]);
  // const expandRowClick = (gridRowId: GridRowId) => {
  //   manageExpandedRows(gridRowId, expandedRows, setExpandedRows);
  // };

  const cloneEventRowToOffer = async (row: SearchEventFieldsFragment) => {
    if (!row.id) {
      return;
    }
    try {
      await cloneEvent(row.id);
    } catch (e) {
      logError(appInsights, 'clone event error', e as Error);
      setNotification({
        type: SnackbarType.ERROR,
        text: 'Event could not be added. Please try again.',
        duration: 6000,
      });
    }
  };

  const columns: GridColDef<CloneTabGridData>[] = [
    // ...expandColumn(expandedRows, expandRowClick), // Un-Comment to re-enable row expansion
    ...addColumn<CloneTabGridData>(
      cloneEventRowToOffer,
      'Clone',
      AddColumnDirection.left,
      { width: 60 },
    ),
    ...chevronRightColumn<CloneTabGridData>((row) => {
      setSingleOfferSelected(true);
      setSelectedEvent(row);
    }),
    // {
    //   field: 'lastUpdatedDate',
    //   headerName: 'Last Updated',
    //   minWidth: 115,
    // },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      renderCell: (params) => <StatusChip status={params.row.status || ''} />,
    },
    {
      field: 'numShows',
      headerName: 'Shows',
      minWidth: 50,
      valueGetter: ({ row }) => row.shows?.length,
    },
    {
      field: 'headliner',
      headerName: 'Headliner',
      minWidth: 130,
      valueGetter: (params) => params.row.headliner?.name,
    },
    {
      field: 'eventDate',
      headerName: 'Event Date',
      minWidth: 115,
      sortComparator: sortDates,
      valueGetter: ({ row }) => {
        if (row.shows?.length) {
          const [firstShow] = row.shows;

          if (firstShow?.showDateTime) {
            return getTimezoneDate(firstShow.showDateTime, row.venue?.timezone)[1];
          }
        }
        return undefined;
      },
      valueFormatter(params) {
        if (!params.value) {
          return 'TBD';
        }

        const sortedDate = params.value as Dayjs;
        return sortedDate.format('L');
      },
    },
    {
      field: 'availableCapacity',
      headerName: 'Available Capacity',
      minWidth: 145,
    },
    {
      field: 'configuration',
      headerName: 'Configuration',
      minWidth: 125,
    },
    {
      field: 'buyer',
      headerName: 'Buyer',
      minWidth: 110,
    },
    {
      field: 'inPrimaryOffer',
      headerName: 'Primary',
      minWidth: 100,
    },
  ];

  const { data, error, loading: eventsSearchLoading } = useQuery(SEARCH_EVENTS, {
    errorPolicy: 'ignore',
    variables: {
      filter: {
        startDate: dayjs().subtract(2, 'year').format('YYYY-MM-DD'),
        returnTBD: true, // NOTE: this filter requires startDate to be set
        top: 200,
        venueId,
        spaceId,
      },
    },
  });

  const renderBoldCell = useCallback((params: {
    row: CloneTabGridData;
    value: ReactNode;
  }) => {
    if (params.row.bold) {
      return (<strong>{params.value}</strong>);
    }
    return params.value;
  }, []);

  const rows = useMemo<CloneTabGridData[]>(() => {
    if (data?.searchEvents) {
      const { searchEvents: events } = data;
      return events.flatMap((eventData, i) => {
        const event = getFragmentData(EVENT_FRAGMENT, eventData);
        if (!event?.canSelectEventFromCloneList || (hideNonPrimary && !event?.tourInformation?.offerIsPrimary)) {
          return [];
        }
        return [
          {
            key: event.id as string,
            ...event,
            buyer: event.primaryBuyer?.name,
            headliner: event.tourInformation?.headliner,
            availableCapacity: event.projectedBoxOfficeOverview?.totalAvailableCapacity,
            lastUpdatedDate: 'tbd', // temporary
            inPrimaryOffer: event?.tourInformation?.offerIsPrimary ? 'Yes' : 'No',
          },
        ];
      });
    }
    return [];
  }, [data, hideNonPrimary]);

  // Un-Comment this block to re-enable row expansion
  // const getDetailPanelContent = useCallback(
  //   ({ row }: { row: CloneTabGridData }) => (
  //     <TicketTierPanel
  //       disableVirtualization={disableVirtualization}
  //       defaultBoxOffice={row?.defaultBoxOffice}
  //       currency={row.venue?.localCurrencyCode}
  //     />
  //   ),
  //   [],
  // );
  // const apiRef = useGridApiRef();
  // const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
  //   (params) => {
  //     expandRowClick(params.id);
  //     apiRef.current.toggleDetailPanel(params.id);
  //   },
  //   [apiRef, expandedRows],
  // );

  if (eventsSearchLoading) {
    return <SearchEventsLoadingContainer>
      <Loading />
    </SearchEventsLoadingContainer>;
  }

  const onRowClick = (params: { row: SearchEventFieldsFragment }) => {
    setSingleOfferSelected(true);
    setSelectedEvent(params.row);
  };

  if (singleEventSelected && selectedEvent?.id && data?.searchEvents) {
    const downloadPrimarySettlementFile = async () => {
      if (selectedEvent.id) {
        const downloadUrlResponse = await getPrimaryNosDownloadUrl(
          { variables: { eventId: selectedEvent.id } },
        );
        const url = downloadUrlResponse?.data?.getPrimaryNOSSettlementDownloadUrl?.url;
        if (url) {
          window.open(url);
        }
      }
    };

    const { searchEvents: events } = data;
    const eventFragments = events
      .flatMap((eventData) =>
        getFragmentData(EVENT_FRAGMENT, eventData)) as unknown as SearchEventFieldsFragment[];

    const selectedSearchedEvent = eventFragments.find((e) => e.id === selectedEvent.id);

    const singleOfferMetadataRowItems = generateOfferMetadataRowItems(selectedSearchedEvent);

    const expensesColumns: GridColDef<CloneTabGridData>[] = EXPENSES_COLUMNS.map((column) => ({
      ...column,
      renderCell: renderBoldCell,
    }) as GridColDef<CloneTabGridData>);

    const boxOfficeColumns: GridColDef<CloneTabGridData>[] = BOX_OFFICE_COLUMNS.map((column) => ({
      ...column,
      renderCell: renderBoldCell,
    }) as GridColDef<CloneTabGridData>);

    const expensesRows = generateExpensesRowItems(selectedSearchedEvent) as unknown as CloneTabGridData[];

    const boxOfficeRows = generateBoxOfficeRowItems(selectedSearchedEvent) as unknown as CloneTabGridData[];
    const pinnedBoxOfficeRows = generatePinnedBoxOfficeRowItems(selectedSearchedEvent) as
      unknown as GridPinnedRowsProp<CloneTabGridData>;
    const commonGridProps = {
      columnHeaderHeight: 38,
      disableRowSelectionOnClick: true,
      disableVirtualization,
      hideFooter: true,
      getRowClassName: (params: { indexRelativeToCurrentPage: number }) =>
        (params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'),
      rowHeight: 38,
      slots: {
        noRowsOverlay: () => null,
      },
    };
    return (
      <div>
        <Divider />
        <CloneOffersHeader>
          <BackToButton
            data-testid="venue-details-back-to-offer-results"
            onClick={() => {
              setSingleOfferSelected(false);
            }}
          >
            <ArrowBackIos fontSize="inherit" /> Offers
          </BackToButton>
        </CloneOffersHeader>
        <VenueMetadata>
          <FeatureGate configFlag={FeatureFlags.DISPLAY_CLONE_OFFER_DETAILS}>
            <Grid
              container
              direction="row"
              alignItems="center"
              data-testid="offer-details-metadata"
              justifyContent="space-between"
            >
              {singleOfferMetadataRowItems}
            </Grid>
          </FeatureGate>
        </VenueMetadata>
        <CloneOfferButtonContainer>
          <FeatureGate configFlag={FeatureFlags.DISPLAY_DOWNLOAD_SETTLEMENT_BUTTON}>
            <Button
              variant="outlined"
              data-testid="download-settlement-button"
              disabled={!canSaveNosWorkbookByStatus(selectedEvent.status)}
              style={{
                marginRight: '12px',
              }}
              onClick={() => {
                void downloadPrimarySettlementFile();
              }}>
              Download Settlement
            </Button>
          </FeatureGate>
          <Button
            variant="contained"
            data-testid="clone-offer-button"
            onClick={() => {
              void cloneEventRowToOffer(selectedEvent);
            }}>
            Clone Offer
          </Button>
        </CloneOfferButtonContainer>
        <FeatureGate configFlag={FeatureFlags.DISPLAY_CLONE_EXPENSES_TABLE}>
          <AddInAccordion
            header={<AccordionTableHeader headerText="Expenses" />}
            isExpanded
            >
            <CondensedAltRowColorGrid
              {...commonGridProps}
              columns={expensesColumns}
              rows={expensesRows}
              getCellClassName={(params) => (params.field === 'expenseName' ? 'bold-cell' : '')}
            />
          </AddInAccordion>
        </FeatureGate>
        <FeatureGate configFlag={FeatureFlags.DISPLAY_CLONE_BOX_OFFICE_TABLE}>
          <AddInAccordion
            header={<AccordionTableHeader headerText="Box Office" />}
            isExpanded
          >
            <CondensedAltRowColorGrid
              {...commonGridProps}
              columns={boxOfficeColumns}
              rows={boxOfficeRows}
              pinnedRows={pinnedBoxOfficeRows}
            />
          </AddInAccordion>
        </FeatureGate>
      </div>);
  }

  return (
    data?.searchEvents && (
      <>
        <NonPrimaryToggleContainer>
          <SwitchContainer>
            <SwitchText>Hide Non-Primary</SwitchText>
            <Switch
              data-testid={'toggle-hide-primary-offer'}
              checked={hideNonPrimary}
              onChange={(e) => {
                setHideNonPrimary(e.target.checked);
              }}
            />
          </SwitchContainer>
        </NonPrimaryToggleContainer>
        <CondensedAltRowColorGrid
          hideFooter
          columns={columns}
          rows={rows}
          onRowClick={onRowClick}
          disableRowSelectionOnClick
          disableVirtualization={disableVirtualization}
          initialState={{
            pinnedColumns: {
              // left: [GRID_DETAIL_PANEL_TOGGLE_FIELD], // Un-Comment to re-enable row expansion
              left: ['addEvent'],
              right: ['chevronRight'],
            },
          }}
          // Un-Comment props below to re-enable row expansion
          // apiRef={apiRef}
          // onRowClick={onRowClick}
          // getDetailPanelContent={getDetailPanelContent}
          // getDetailPanelHeight={() => 'auto'
        />
      </>
    )
  );
};
