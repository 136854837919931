import * as React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ModalContainer, ModalHeader, ModalTitle } from './GenericModal.styled';
import { CloseIconGrey } from '../Icons/StyledMuiIcons';
import { FormStylingMode } from '../Form';

interface GenericModalProps {
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description?: string;
  children?: React.ReactNode;
  mode?: FormStylingMode;
  errorComponent?: React.ReactNode;
}

export function GenericModal({
  open,
  setOpen,
  title,
  description,
  children,
  mode,
  errorComponent,
}: GenericModalProps) {
  return (
    <Modal
      data-testid="generic-modal"
      open={open}
      aria-labelledby="search-modal-title"
      aria-describedby="search-modal-description"
    >
      <ModalContainer mode={mode}>
        {errorComponent}
        <ModalHeader mode={mode}>
          <ModalTitle>
            <Typography data-testid="search-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            {setOpen && <CloseIconGrey data-testid="search-modal-close" onClick={() => setOpen(false)} />}
          </ModalTitle>
          {description && <Typography data-testid="search-modal-description">
            {description}
          </Typography>}
        </ModalHeader>
        {children}
      </ModalContainer>
    </Modal>
  );
}
