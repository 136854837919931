export const PROFIT_LOSS_REF_SHEET_NAME = 'profit_loss_reference_sheet';

export const WORKBOOK_CUSTOM_PROPERTIES = {
  OFFER_ID: 'Offer_Id',
  EVENT_ID: 'Event_Id',
  NOS_ID: 'NOS_Id',
  REFRESH_DISMISSED: 'Refresh_Dismissed',
  FAILED_REFRESH_DISMISSED: 'Failed_Refresh_Dismissed',
  IS_RETIRED: 'Is_Retired',
};

export const WORKBOOK_ORIGINAL_PARENT_DIR = 'workbook_original_parent_dir';
export const WORKBOOK_ORIGINAL_PARENT_DIR_TOUR_FOLDER = 'workbook_original_parent_dir_tour_folder';
export const WORKBOOK_ORIGINAL_PARENT_DIR_PATH = 'workbook_original_parent_dir_path';
export const WORKBOOK_ORIGINAL_FILE_DEVIATION_ERROR_MSG = 'Excel workbook is not synced with Modern Elvis.';

export const MAX_CHARS_TOUR_NAME = 75;

// CMN company ID
export const CMN_COMPANY_ID = 'companymaster-0000000180';
export const COMPANY_LOGO_SHAPE_NAME = 'CompanyLogo';

export const EXPENSE_CATEGORY_IDS = {
  FIXED_COSTS: 'Fixed Costs',
  VARIABLE_COST: 'Variable Cost',
  ANCILLARY_EARNINGS: 'Ancillary Earnings',
  FIXED_COST_PARENT_CATEGORIES: {
    ADVERTISING: 'Advertising',
    ARTIST_PRORATES: 'Artist Prorates',
    CATERING: 'Catering',
    OTHER_COSTS: 'Other Costs',
    PRODUCTION: 'Production',
    PRODUCTION_PRORATES: 'Production Prorates 1',
    RENT: 'Rent',
    STAFFING: 'Staffing',
    STAGEHANDS: 'Stagehands',
    SUPPORTS: 'Support',
    TRANSPORT: 'Band Transport',
    VENUE: 'Venue',
  },
} as const;

/**
 * Profit Loss Reference Sheet Tables
 */
export const PROFIT_LOSS_REF_SHEET_TABLES = {
  ANCILLARY_EARNINGS: 'AncillaryEarningsListTbl',
  PRE_TAX: 'PreTaxFeesListTbl',
  TAX: 'TaxesListTbl',
  POST_TAX: 'PostTaxFeesListTbl',
  VARIABLE_COSTS: 'VariableCostsListTbl',
  FIXED_COSTS_REF_TABLE: {
    ADVERTISING: 'AdvertisingListTbl',
    ARTIST_PRORATE: 'ArtistProratesListTbl',
    CATERING: 'CateringListTbl',
    OTHER_COSTS: 'OtherCostsListTbl',
    PRODUCTION: 'ProductionListTbl',
    PRODUCTION_PRORATES: 'ProductionProratesListTbl',
    RENT: 'RentListTbl',
    STAFFING: 'StaffingListTbl',
    STAGEHANDS: 'StagehandsListTbl',
    SUPPORTS: 'SupportListTbl',
    TRANSPORT: 'BandTransportListTbl',
    VENUE: 'VenueListTbl',
  } as const,
} as const;

/**
 * standardized phase IDs
 */
export enum Phases {
  MODELING = 'modeling',
  ARTIST_SETTLEMENT = 'artist_settlement',
  INTERNAL_SETTLEMENT = 'internal_settlement',
}

/**
 * for use as keys in TABLE_IDENTIFIERS
 */
export enum WorkbookReferences {
  MODELING = 'modeling',
  NOS_SETTLEMENT_BREAKDOWN = 'nosSettlement_breakdown',
  NOS_SETTLEMENT_BUDGET = 'nosSettlement_budget',
}

type FixedExpenseCategory = {
  advertising: string;
  artistProrates: string;
  catering: string;
  otherCosts: string;
  production: string;
  productionProrates: string;
  rent: string;
  staffing: string;
  stagehands: string;
  supports: string;
  transport: string;
  venue: string;
  variableCosts?: string;
};

type VariableExpenseCategory = {
  variableCosts: string;
};

type FixedExpenseCategories = {
  [key: string]: FixedExpenseCategory;
};

type VariableExpenseCategories = {
  [key: string]: VariableExpenseCategory;
};

export const TABLE_IDENTIFIERS = {
  boxOfficeTables: {
    preTax: 'PreTaxFees',
    tax: 'Taxes',
    postTax: 'PostTaxFees',
    boxOffice: 'BoxOffice',
  },
  variableExpenseCategories: {
    nosSettlement_breakdown: {
      variableCosts: 'VariableCosts_Breakdown',
    },
  } as VariableExpenseCategories,
  fixedExpenseCategories: {
    modeling: {
      advertising: 'FixedCostCategory_Advertising',
      artistProrates: 'FixedCostCategory_Prorate',
      catering: 'FixedCostCategory_Catering',
      otherCosts: 'FixedCostCategory_OtherCosts',
      production: 'FixedCostCategory_Production',
      productionProrates: 'FixedCostCategory_ProdProrate',
      rent: 'FixedCostCategory_Rent',
      staffing: 'FixedCostCategory_Staffing',
      stagehands: 'FixedCostCategory_Stagehands',
      supports: 'FixedCostCategory_Support',
      transport: 'FixedCostCategory_Transport',
      venue: 'FixedCostCategory_Venue',
    },
    nosSettlement_breakdown: {
      advertising: 'Advertising_Breakdown',
      artistProrates: 'ArtistProrate_Breakdown',
      catering: 'Catering_Breakdown',
      otherCosts: 'OtherCosts_Breakdown',
      production: 'Production_Breakdown',
      productionProrates: 'ProdProrate_Breakdown',
      rent: 'Rent_Breakdown',
      staffing: 'Staffing_Breakdown',
      stagehands: 'Stagehands_Breakdown',
      supports: 'Support_Breakdown',
      transport: 'Transport_Breakdown',
      venue: 'Venue_Breakdown',
    },
    nosSettlement_budget: {
      advertising: 'Advertising_Budget',
      artistProrates: 'ArtistProrate_Budget',
      catering: 'Catering_Budget',
      otherCosts: 'OtherCosts_Budget',
      production: 'Production_Budget',
      productionProrates: 'ProdProrate_Budget',
      rent: 'Rent_Budget',
      staffing: 'Staffing_Budget',
      stagehands: 'Stagehands_Budget',
      supports: 'Support_Budget',
      transport: 'Transport_Budget',
      venue: 'Venue_Budget',
    },
  } as FixedExpenseCategories,
} as const;
