import { AlertColor } from '@mui/material';
import React, { ReactNode } from 'react';

export type Dialog = {
  titles?: {
    dialogTitle: string;
    alertTitle?: string;
    alertList?: ReactNode[];
  },
  description?: React.ReactNode;
  submit?: {
    text: string;
    action: () => void;
  },
  cancel?: {
    text: string;
    action: () => void;
  },
  isLoading?: boolean;
  severity?: AlertColor;
} | null;

const DialogContext = React.createContext<Dialog>(null);
const DialogDispatchContext = React.createContext<
React.Dispatch<React.SetStateAction<Dialog>>
>(() => {});

type ProviderProps = {
  children: React.ReactNode;
};
function DialogProvider({ children }: ProviderProps) {
  const [dialog, setDialog] = React.useState<Dialog>(null);

  return (
    <DialogContext.Provider value={dialog}>
      <DialogDispatchContext.Provider value={setDialog}>
        {children}
      </DialogDispatchContext.Provider>
    </DialogContext.Provider>
  );
}

export {
  DialogProvider,
  DialogContext,
  DialogDispatchContext,
};
