import { useExcel } from '@hooks';
import { ActionButton, GenericMessage } from '../ErrorPage.styled';
import { LayoutWarning } from './LayoutWarning';

export function NewVersionAvailableWarning({ context }: { context?: Record<string, unknown> }) {
  const { closeWorkbook } = useExcel();
  return (
    <LayoutWarning header={'New version available'}>
      <GenericMessage data-testid="generic-message">
        This workbook has been retired or a refresh is in progress.
      </GenericMessage>
      <GenericMessage data-testid="generic-message">
        Please close this file and launch the new version from the Tour Detail Page.
      </GenericMessage>
      <ActionButton>
        <a
          href={`${window.location.origin}/tours/${context?.tourId as string ?? ''}`}
          onClick={() => {
            void closeWorkbook();
          }}
          target="_blank"
        >
          Close file and launch Modern Elvis
        </a>
      </ActionButton>
    </LayoutWarning>
  );
}
