enum FeatureFlags {
  BUNDLES = 'BUNDLES',
  CAN_UPDATE_VENUE = 'CAN_UPDATE_VENUE',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  NOS_SETTLEMENT_EMAIL = 'NOS_SETTLEMENT_EMAIL',
  NOS_SETTLEMENT_VERSIONING = 'NOS_SETTLEMENT_VERSIONING',
  ONE_OFFS_EVENTS_LIST = 'ONE_OFFS_EVENTS_LIST',
  CAN_REFRESH_WORKBOOK = 'CAN_REFRESH_WORKBOOK',
  DISPLAY_DOWNLOAD_SETTLEMENT_BUTTON = 'DISPLAY_DOWNLOAD_SETTLEMENT_BUTTON',
  DISPLAY_CLONE_OFFER_DETAILS = 'DISPLAY_CLONE_OFFER_DETAILS',
  DISPLAY_CLONE_EXPENSES_TABLE = 'DISPLAY_CLONE_EXPENSES_TABLE',
  DISPLAY_CLONE_BOX_OFFICE_TABLE = 'DISPLAY_CLONE_BOX_OFFICE_TABLE',
  REPLACE_COMPANY_LOGO = 'REPLACE_COMPANY_LOGO',
  CAN_KILL_EVENT = 'CAN_KILL_EVENT',
  CAN_UPDATE_VENUE_ROUTING_CONFIRMED_AND_CONFIRMED = 'CAN_UPDATE_VENUE_ROUTING_CONFIRMED_AND_CONFIRMED',
}

export default FeatureFlags;
