import { useMutation, useQuery } from '@apollo/client';
import { NOS_SETTLEMENTS_EMAIL_MODAL } from '@gql/queries/nosSettlements';
import { SEND_NOS_EMAIL } from '@gql/mutations/nosSettlements';
import { User } from '@gql/types/graphql';
import { CircularProgress } from '@mui/material';
import { NotificationDispatchContext } from '@providers';
import { useContext } from 'react';
import { SendEmailForm } from '../shared/SendEmailForm/SendEmailForm';
import { NOSSummaryTable } from './NOSSummaryTable/NOSSummaryTable';
import { SendEmailFormFields } from '../shared/SendEmailForm/SendEmailForm.models';
import { nosSummaryTableData } from './NOSSummaryEmail.models';
import {
  ErrorOutlineStyled,
  LoadingContainer,
  NosDisclaimerContainer,
  NosDisclaimerText,
} from './NOSSummaryEmail.styled';
import { SnackbarType } from '../Snackbar/Snackbar';

// eslint-disable-next-line max-len
const WARNING_MESSAGE = 'Before continuing, please confirm that recipients are authorized to view internal NOS Settlement data.';
const ERROR_MESSAGE = 'Enter email recipients to continue sending NOS Summary email.';

export interface NOSSummaryEmailProps {
  onEmailSend: () => void;
  onEmailDiscarded: () => void;
  tourId: string,
  nosSettlementId: string,
  expenseId: string,
  expensePhaseId: string,
  version?: number
}

// eslint-disable-next-line max-lines-per-function
export const NOSSummaryEmail = ({
  onEmailSend,
  onEmailDiscarded,
  tourId,
  nosSettlementId,
  expenseId,
  expensePhaseId,
  version,
}: NOSSummaryEmailProps) => {
  const setNotification = useContext(NotificationDispatchContext);

  const {
    data: settlementQueryData,
    loading: settlementQueryLoading,
  } = useQuery(
    NOS_SETTLEMENTS_EMAIL_MODAL,
    {
      variables: {
        tourId,
        expenseId,
        nosSettlementId,
        expensePhaseId,
      },
    },
  );

  const [sendEmail] = useMutation(
    SEND_NOS_EMAIL,
    {
      onCompleted: () => {
        setNotification({
          text: 'Email successfully sent.',
          type: SnackbarType.SUCCESS,
          duration: 6000,
        });
      },
      onError: (error) => {
        setNotification({
          text: `Error sending email: ${error.message}`,
          type: SnackbarType.ERROR,
          duration: 6000,
        });
      },
    },
  );

  const summaryData = settlementQueryData && nosSummaryTableData(settlementQueryData);
  const toUsers: User[] | undefined | null = settlementQueryData && settlementQueryData.tour?.nosEmailDistributionList;

  const userToEmail = (user: User[]): string[] => user.map((u) => u.email);

  const handleOnSend = async (data: SendEmailFormFields) => {
    onEmailSend();
    await sendEmail({
      variables: {
        nosSettlementEmailData: {
          tourId,
          eventId: expenseId,
          version,
          toEmails: userToEmail(data.toUsers),
          ccEmails: userToEmail(data.ccUsers),
          comments: data.comments,
        },
      },
    });
  };

  const handleOnDiscard = () => {
    onEmailDiscarded();
  };

  if (settlementQueryLoading) {
    return <LoadingContainer><CircularProgress /></LoadingContainer>;
  }

  return (
    <SendEmailForm
      title='Send NOS Summary'
      warningMessageOnValidForm={WARNING_MESSAGE}
      errorMessageOnInvalidToRecipients={ERROR_MESSAGE}
      defaultValues={
        {
          toUsers: toUsers || [],
          ccUsers: [],
          comments: '',
        }
      }
      onSend={(data) => {
        void handleOnSend(data);
      }}
      onDiscard={handleOnDiscard}
    >
      {summaryData
        && <div>
          <NosDisclaimerContainer>
            <ErrorOutlineStyled />
            <NosDisclaimerText>
              Math assumes tour is in overage, use manual template for tours not in overage and other edge cases.
            </NosDisclaimerText>
          </NosDisclaimerContainer>
          <NOSSummaryTable title={'NOS Summary'} rows={summaryData} />
        </div>
      }
    </SendEmailForm>
  );
};
