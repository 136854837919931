/* eslint-disable max-lines-per-function */
/* eslint-disable import/no-extraneous-dependencies */
import {
  useForm,
  FormProvider,
  SubmitHandler,
} from 'react-hook-form';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { DevTool } from '@hookform/devtools';
import { ApolloError } from '@apollo/client';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import {
  RadioInput,
  ButtonStyled,
  FormTextInput,
  FormErrorComponent,
} from '@components';
import { useYupValidationResolver } from '@hooks';
import { logError } from '@services/telemetry-service';
import { CreateBundleOfferInput } from '@gql/types/graphql';

import {
  crossedOptions,
  BundleOfferFormFields,
} from './BundleOfferForm.models';
import { bundleOfferFormValidationSchema } from './BundleOfferForm.schema';
import { SubHeader, ButtonContainer, SubmitButtonStyled } from './BundleOfferForm.styled';

interface BundleOfferFormProps {
  title: string;
  subTitle: string;
  submitText?: string;
  cancelText?: string;
  onFormSubmit: SubmitHandler<CreateBundleOfferInput>;
  onFormCancel?: () => void;
  isFormSaving: boolean; // Push saving state into form
  defaultValues: Partial<BundleOfferFormFields>
  mutationError?: ApolloError;
}

export function BundleOfferForm({
  title,
  subTitle,
  submitText = 'Save',
  cancelText = 'Cancel',
  onFormSubmit,
  onFormCancel,
  isFormSaving,
  defaultValues,
  mutationError,
}: BundleOfferFormProps) {
  const appInsights = useAppInsightsContext();
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);

  const resolver = useYupValidationResolver(bundleOfferFormValidationSchema);

  const formMethods = useForm<BundleOfferFormFields>({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const {
    control,
    reset,
    handleSubmit,
  } = formMethods;

  const handleInvalidSubmit = () => setIsFormErrorOpen(false);

  const handleValidSubmit = async (formData: BundleOfferFormFields) => {
    const modifyBundleInput: CreateBundleOfferInput = {
      name: formData.name.trim(),
      crossed: formData.crossed,
    };

    try {
      await onFormSubmit(modifyBundleInput);
      reset();
    } catch (error) {
      setIsFormErrorOpen(true);
      logError(appInsights, 'BundleOfferForm submit error', error as Error);
    }
  };

  return (
    <>
      <Typography variant="h5" data-testid="create-new-bundle-offer-form-header-text">
        {title}
      </Typography>

      <SubHeader
        variant="body2"
        data-testid="create-new-bundle-offer-form-sub-header-text"
      >
        {subTitle}
      </SubHeader>

      <FormProvider {...formMethods}>
        <form onSubmit={(e) => {
          void handleSubmit(handleValidSubmit, handleInvalidSubmit)(e);
        }}>
          <FormErrorComponent
            isDismissable={false}
            error={mutationError}
            isOpen={isFormErrorOpen}
            setIsOpen={setIsFormErrorOpen}
          />

          <FormTextInput
            control={control}
            fieldName="name"
            label="Offer Name"
            isRequired
            placeholderText="Enter Bundle Offer Name"
          />

          <RadioInput
            fieldName="crossed"
            control={control}
            label={'Crossed?'}
            options={crossedOptions}
          />

          <ButtonContainer>
            {onFormCancel && (
              <ButtonStyled
                variant="outlined"
                data-testid="cancel-button-form"
                type="button"
                onClick={onFormCancel}
                disabled={isFormSaving}
              >
                {cancelText}
              </ButtonStyled>
            )}
            <SubmitButtonStyled
              variant="contained"
              data-testid="create-button-form"
              type="submit"
              disabled={isFormSaving}
            >
              {submitText}
            </SubmitButtonStyled>
          </ButtonContainer>
        </form>
      </FormProvider>
      <DevTool control={control} />
    </>
  );
}
