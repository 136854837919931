/* eslint-disable max-len */
import { CustomTheme, styled } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

// Datagrids have a type bug that makes it necessary to cast a styled() datagrid as a datagrid
// read more here https://github.com/mui/mui-x/issues/6014

/**
 * A condensed datagrid with column and row height of 40px & max length of 550px
 */
export const CondensedGrid = styled(DataGridPro)(({ theme }: { theme?: CustomTheme }) => ({
  borderRadius: 0,
  borderColor: `${theme?.palette.primary.light[150] || ''}!important`,
  maxWidth: theme?.spacing(68.75),
  marginBottom: theme?.spacing(3),
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
    {
      outline: 'none',
    },
  '& .MuiDataGrid-row:not(.MuiDataGrid-row--detailPanelExpanded), & .MuiDataGrid-pinnedColumns, & .MuiDataGrid-detailPanel':
    {
      border: 'none',
    },
  '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-pinnedColumnHeaders': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
    border: 'none',
  },
})) as typeof DataGridPro;

/** Regular datagrid with alternating white & grey rows */
export const AltRowColorGrid = styled(DataGridPro)(({ theme, onRowClick }: { theme?: CustomTheme, onRowClick?: () => void }) => ({
  borderRadius: theme?.spacing(0),
  width: '100%',
  '& .MuiDataGrid-row:not(.MuiDataGrid-row--detailPanelExpanded), & .MuiDataGrid-pinnedColumns, & .MuiDataGrid-detailPanel':
    {
      borderBottom: `solid 1px ${theme?.palette.action.disabledBackground as string}`,
    },
  '& .MuiDataGrid-row': {
    cursor: onRowClick ? 'pointer' : 'auto',
  },
  '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: theme?.palette.info.states?.hover,
  },
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
    {
      outline: 'none',
    },
  '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: theme?.palette.primary.states?.hover,
    boxShadow: 'none',
  },
  '& .MuiDataGrid-pinnedColumnHeaders--right': {
    padding: '0!important',
  },
  "& .MuiDataGrid-cell[data-field='expandEvent'], & .MuiDataGrid-cell[data-field='addEvent']": {
    padding: 0,
  },
  '& .MuiDataGrid-pinnedColumns': {
    boxShadow: 'none',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: theme?.palette.primary.states?.hover,
  },
  '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
    border: 'none',
  },
})) as typeof DataGridPro;

export const CondensedAltRowColorGrid = styled(AltRowColorGrid)(({ theme, onRowClick }: { theme?: CustomTheme, onRowClick?: () => void }) => ({
  width: '100%',
  border: 'none',
  '& .MuiDataGrid-row:not(.MuiDataGrid-row--detailPanelExpanded), & .MuiDataGrid-pinnedColumns, & .MuiDataGrid-detailPanel':
  {
    borderBottom: 'none',
  },
  '.MuiDataGrid-pinnedRows': {
    boxShadow: 'none',
  },
  '& .bold-cell': {
    fontWeight: 'bold',
  },
  '& .even-row': {
    backgroundColor: theme?.palette?.primary?.states?.hover,
  },
  '& .odd-row': {
    backgroundColor: theme?.palette?.primary?.states?.selected,
  },
  '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: theme?.palette?.primary?.states?.selected,
    boxShadow: 'none',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: theme?.palette?.primary?.states?.selected,
  },
})) as typeof DataGridPro;

export const VenueDatagrid = styled(DataGridPro)(({ theme }: { theme?: CustomTheme }) => ({
  borderRadius: theme?.spacing(0),
  border: 'none',
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
    {
      outline: 'none',
    },
  '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: theme?.palette.info.states?.hover,
  },
  '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: theme?.palette.primary.states?.hover,
    border: 'none',
    borderBottom: 'solid',
    borderWidth: theme?.spacing(0.125),
    borderColor: theme?.palette.secondary.light,
    boxShadow: 'none',
  },
  '& .MuiDataGrid-pinnedColumns': {
    boxShadow: 'none',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: theme?.palette.primary.states?.hover,
  },
  '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
    border: 'none',
    borderBottom: 'solid',
    borderWidth: theme?.spacing(0.125),
    borderColor: theme?.palette.secondary.dark,
    paddingLeft: theme?.spacing(3),
  },
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme?.palette.info.main,
  },
  '.border': {
    borderColor: `${theme?.palette.action.disabledBackground || ''} !important`,
    border: '1px solid',
    borderTop: 'none',
    boxShadow: 'none !important',
  },
  '.borderLeft': {
    borderRight: 'none',
  },
  '.borderRight': {
    borderLeft: 'none',
  },
  '& .MuiDataGrid-cell--editing, .MuiDataGrid-row--editing': {
    boxShadow: 'none !important',
  },
  '& .MuiInputBase-input': {
    paddingLeft: theme?.spacing(3),
    fontSize: '0.875rem', // default datagrid value fontSize
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiDataGrid-cell--editing .MuiOutlinedInput-root:not(.Mui-focused) .MuiSelect-icon': {
    display: 'none',
  },
  '& .MuiDataGrid-cell.validation-error': {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    border: `solid 1px ${theme?.palette.error.dark} !important`,
  },
})) as typeof DataGridPro;

export const NestedDataGrid = styled(DataGridPro)(({ theme }: { theme?: CustomTheme }) => ({
  borderRadius: theme?.spacing(0),
  border: 'none !important',
  '& .MuiDataGrid-row': {
    borderBottom: 'solid',
    borderWidth: theme?.spacing(0.125),
    borderColor: theme?.palette?.secondary?.light,
    '&:hover, &.Mui-hovered': {
      backgroundColor: theme?.palette.info.states?.hover,
    },
  },
  '& .MuiDataGrid-pinnedColumns': {
    boxShadow: 'none',
    borderRight: 'solid',
    borderRadius: theme?.spacing(0),
    borderWidth: theme?.spacing(0.125),
    borderColor: theme?.palette?.secondary?.light,
  },
  '& .MuiDataGrid-cell': {
    paddingLeft: theme?.spacing(3),
    border: 'none',
    '&:focus-within, &:focus': {
      outline: 'none',
    },
  },
})) as typeof DataGridPro;
