import { Box, Button, styled } from '@mui/material';

export const WarningContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#FEBF2F',
  width: '100%',
  padding: theme.spacing(2),
  fontSize: theme.spacing(2),
  color: 'black',
}));

export const FailedContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#D41034',
  width: '100%',
  padding: theme.spacing(2),
  fontSize: theme.spacing(2),
  color: 'white',
}));

export const SuccessContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#0B7A75',
  width: '100%',
  padding: theme.spacing(2),
  fontSize: theme.spacing(2),
  color: 'white',
}));

export const FlexRow = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.25),
  display: 'flex',
  gap: theme.spacing(2),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  color: 'black',
  '&:hover': {
    backgroundColor: 'white',
  },
}));

export const ButtonWhiteText = styled(Button)(({ theme }) => ({
  color: 'white',
  '&:hover': {
    color: 'white',
  },
}));
