import { useState } from 'react';
import { Box, Divider, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import {
  StyledAccordion, StyledAccordionSummary, StyledAccordionDetails,
} from 'src/components/shared/Accordion/Accordion.styled';

export const DetailContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 4),
}));

export const CloneOffersHeader = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 1, 1, 1),
}));

export const StarContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.warning.light,
}));

export const SearchEventsLoadingContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

export const HeaderText = styled(Box)(({ theme }) => ({
  fontSize: theme.spacing(1.75),
  fontWeight: '500',
  lineHeight: theme.spacing(2.75),
  marginRight: theme.spacing(1),
}));
export const SubHeaderText = styled(Box)(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  fontWeight: '400',
  lineHeight: theme.spacing(2.75),
  display: 'flex',
  alignItems: 'center',
  opacity: 0.6,
}));
export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: '0',
  marginRight: '0',
}));

const BoxMarginRight = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));
const InfoIconSmall = styled(InfoIcon)(({ theme }) => ({
  height: theme.spacing(1.5),
  width: theme.spacing(1.5),
}));
export const AccordionTableHeader = ({ headerText }: { headerText: string }) => (
  <HeaderContainer>
    <HeaderText>
      { headerText }
    </HeaderText>
    <SubHeaderText>
      <BoxMarginRight>
        Values pulled from offer in local currency
      </BoxMarginRight>
      <InfoIconSmall/>
    </SubHeaderText>
  </HeaderContainer>
);

const CustomStyledAccordion = styled(StyledAccordion)(() => ({
  boxShadow: 'none',
  maxWidth: '100%',
}));

const ThinStyledAccordionSummary = styled(StyledAccordionSummary)(({ theme }) => ({
  minHeight: theme.spacing(3),
}));
export interface AddInAccordionProps {
  header: React.ReactNode;
  buttonComponent?: React.ReactNode;
  showDivider?: boolean;
  isDisabled?: boolean;
  isExpanded?: boolean;
  children: React.ReactNode;
}
export const AddInAccordion = ({
  header,
  buttonComponent,
  showDivider = false,
  isDisabled = false,
  isExpanded = false,
  children,
}: AddInAccordionProps) => {
  const [accordionOpen, setAccordionOpen] = useState(isExpanded);
  return (
  <CustomStyledAccordion
    disableGutters
    disabled={isDisabled}
    expanded={accordionOpen}>
    <ThinStyledAccordionSummary
      expandIcon={
        <ExpandMoreIcon
          sx={{ cursor: 'pointer' }}
          onClick={() => setAccordionOpen(!accordionOpen)} />
      }
      aria-controls="panel1-content"
      id="panel1-header"
    >
      {header}
      {buttonComponent}
    </ThinStyledAccordionSummary>
    {showDivider && <Divider />}
    <StyledAccordionDetails>
      {children}
    </StyledAccordionDetails>
  </CustomStyledAccordion>
  );
};

export const SwitchContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(0),
  top: theme.spacing(-5),
  paddingRight: theme?.spacing(1),
}));

export const SwitchText = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(1.75),
  [theme.breakpoints.down('smAddIn')]: {
    display: 'none',
  },
}));

export const NonPrimaryToggleContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  left: theme.spacing(2),
}));
