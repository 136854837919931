import { GridValidRowModel, GridValueSetterParams } from '@mui/x-data-grid-pro';
import { FeeItemTypes } from '@types';

// rounds number to specified number of decimal places
export const roundNumber = (value: number, precision: number) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const toNumberOrUndefined = (value?: any) => (Number.isNaN(value) || Number.isNaN(+value) ? undefined : +value);

export const toRoundedNumberOrUndefined = (value?: any) => {
  const numberValue = toNumberOrUndefined(value);
  return numberValue ? Math.round(numberValue) : numberValue;
};

/**
 * Attempts to coerce an input first into a number then fallback to a string.
 * @param value
 * @returns JS falsy values (other than 0) as undefined, truthy values as a number or string
 */
export const toValueOrUndefined = (value?: unknown): string | number | undefined => {
  if (['', null, undefined, NaN].includes(value as string | null | undefined)) {
    return undefined;
  }

  if (Number.isNaN(Number(value))) {
    return String(value);
  }

  return Number(value);
};

/**
 * This function removes valid commas from a string in order to evaluate the value as a number
 * @param value expects number in string format
 * @returns string value with valid commas removed. invalid commas are retained to cue validation error
 */
export const handleCommaSeparatedNumber = (value?: unknown, valueType?: string): string | number | undefined => {
  if (['', null, undefined, NaN].includes(value as string | null | undefined)) {
    return undefined;
  }

  // remove only commas before first decimal point
  const [beforeDecimal, ...afterDecimalParts] = String(value).split('.');

  // join all parts after the first decimal point
  const afterDecimal = afterDecimalParts.join('.');

  // and only remove commas that are properly formatted in the string (in a numerical sense)
  if (/^(\d{1,3}(,\d{3})*|)$/.test(beforeDecimal)) {
    const parsedValue: string = beforeDecimal.replace(/,/g, '') + (afterDecimal ? `.${afterDecimal}` : '');
    if (valueType === FeeItemTypes.PERCENTAGE && beforeDecimal.includes(',')) {
      return toValueOrUndefined(Number(parsedValue) / 100);
    }
    return toValueOrUndefined(parsedValue);
  }

  return toValueOrUndefined(value);
};

/**
 * This function expects a number as a percentage to be in decimal format.
 * It will multiply the number by 100 to convert it to a whole number percentage.
 * @param params
 * @returns number multiplied by 100, or the original value if it is not a number
 */
export const decimalPercentageGetter = (params: GridValidRowModel) => {
  const numberValue = toNumberOrUndefined(params.value);

  if (numberValue) {
    return numberValue * 100;
  }

  return toValueOrUndefined(params.value);
};

/**
 * This function expects a number as a percentage to be in whole number format.
 * It will divide the number by 100 to convert it to a decimal number percentage.
 * @param params
 * @returns number divided by 100, or the original value if it is not a number
 */
export const decimalPercentageSetter = <T extends GridValidRowModel>(params: GridValueSetterParams<T>) => {
  const numberValue = toNumberOrUndefined(params.value);
  let value;

  if (numberValue) {
    value = numberValue / 100;
  } else {
    value = toValueOrUndefined(params.value);
  }

  return ({
    ...params.row, value,
  });
};

/**
 * Formats a given value into a comma separated amount with currency symbol.
 * Negative numbers are also formatted -19522 -> ($19,522)
 * @param value
 * @param minimumFractionDigits - This suffices for whole numbers, but will print 2500.10 as $2,500.1)
 * @param currency - Defaults to USD
 * @returns Value as currency. Example: $2,703,731.83 or '-' when null or undefined value
 */
export const formatNumberToCurrency = (
  value: number | null | undefined,
  options?: {
    currencyCode?: string;
    nullishValue?: string;
    zeroValue?: string;
  },
): string => {
  const { currencyCode = 'USD', nullishValue = '-', zeroValue } = options ?? {};
  if ((value === undefined || value === null)) {
    return nullishValue;
  }

  if (value === 0 && zeroValue) {
    return zeroValue;
  }

  const numberFormatOptions: Intl.NumberFormatOptions = { style: 'currency', currency: currencyCode };
  const absoluteValue = Math.abs(value);
  const currencyString = new Intl.NumberFormat('en-US', numberFormatOptions).format(absoluteValue);

  if (Math.sign(value) === -1) {
    return `(${currencyString})`;
  }

  return currencyString;
};

/**
 * Format given number to absolute number
 * @param value - number
 * @returns string
 */
export const formatAbsoluteNumber = (value?: number) => {
  if (value === undefined || value === null) return '';
  const absoluteValue = Math.abs(value);
  if (Math.sign(value) === -1) {
    return `(${absoluteValue.toLocaleString()})`;
  }
  return absoluteValue.toLocaleString();
};

/**
 * Calculates the variance between two numbers
 * @param previousValue - number
 * @param newValue - number
 * @returns number
 */
export const calculateVariance = (previousValue: number, newValue: number) => newValue - previousValue;

/**
 * Format given totals into a human readable
 * @param average - number
 * @param total - number
 */
export const formatTotalsToHumanReadable = (totals: { average: number; total: number }, precision = 0) => ({
  average: totals.average?.toLocaleString('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  }),
  total: totals.total?.toLocaleString('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  }),
});

/**
 * Format given totals into a currency or dash
 * @param average - number
 * @param total - number
 */
export const formatTotalsToCurrencyOrDash = (totals: { average: number; total: number }) => ({
  average: formatNumberToCurrency(totals.average),
  total: formatNumberToCurrency(totals.total),
});

/**
 * Format given number to percentage
 * @param number - number
 * @param precision - number of decimal places
 * @param isDecimalPercentage - Multiply number by 100 to convert to percentage
 * @returns string
 */
export const formatNumberToPercentage = (
  number: number | null | undefined,
  precision = 2,
  isDecimalPercentage = true,
) => (
  number === null || number === undefined
    ? ''
    : `${(number * (isDecimalPercentage ? 100 : 1)).toLocaleString('en-US', {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
    })}%`
);
